import { act } from "react-dom/test-utils";
import {
  FETCH_ABANDONED_CART_FAILURE,
  FETCH_ABANDONED_CART_SUCCESS,
  FETCH_ABANDONED_CART_REQUEST,
} from "../actions/abandonedCartActions";

// Initial state
const initialState = {
  abandonedCart: [],
  loading: false,
  error: null,
};

// Reducer
const abandonedCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ABANDONED_CART_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
 
    case FETCH_ABANDONED_CART_SUCCESS:

      return {
        ...state,
        loading: false,
        abandonedCart: action.payload,
      };
 
    case FETCH_ABANDONED_CART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default abandonedCartReducer;
