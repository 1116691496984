import { FETCH_COUNT_REQUEST, FETCH_COUNT_SUCCESS, FETCH_COUNT_FAILURE} from "../actions/dashboardActions";

// Initial state
const initialState = {
    count: null,
    loading: false,
    error: null,
  };
  
  // Reducer
  const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COUNT_REQUEST:
      return { 
        ...state, 
        loading: true, 
        error: null 
    };
      case FETCH_COUNT_SUCCESS:
        return {
          ...state,
          loading: false,
          count: action.payload,
        };
      case FETCH_COUNT_FAILURE:
        return {  
            ...state, 
            loading: true, 
            error: action.payload 
        };
      default:
        return state;
    }
  };

  export default dashboardReducer;

  