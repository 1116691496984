import axios from 'axios';
import { toast } from 'react-toastify';
import { GETABOUTUS_URL , ADDABOUTUS_URL, DELETEABOUTUS_URL, MULTIPLE_DELETE_URL, UPDATEABOUTUS_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_ABOUTUS_REQUEST = 'FETCH_ABOUTUS_REQUEST';
export const FETCH_ABOUTUS_SUCCESS = 'FETCH_ABOUTUS_SUCCESS';
export const FETCH_ABOUTUS_FAILURE = 'FETCH_ABOUTUS_FAILURE';

export const ADD_ABOUTUS_REQUEST = 'ADD_ABOUTUS_REQUEST';
export const ADD_ABOUTUS_SUCCESS = 'ADD_ABOUTUS_SUCCESS';
export const ADD_ABOUTUS_FAILURE = 'ADD_ABOUTUS_FAILURE';

export const DELETE_ABOUTUS_REQUEST = 'DELETE_ABOUTUS_REQUEST';
export const DELETE_ABOUTUS_SUCCESS = 'DELETE_ABOUTUS_SUCCESS';
export const DELETE_ABOUTUS_FAILURE = 'DELETE_ABOUTUS_FAILURE';


export const UPDATE_ABOUTUS_REQUEST = 'UPDATE_ABOUTUS_REQUEST';
export const UPDATE_ABOUTUS_SUCCESS = 'UPDATE_ABOUTUS_SUCCESS';
export const UPDATE_ABOUTUS_FAILURE = 'UPDATE_ABOUTUS_FAILURE';

export const MULTIPLE_DELETE_ABOUTUS_SUCCESS = 'MULTIPLE_DELETE_ABOUTUS_SUCCESS';





export const fetchAboutUsRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ABOUTUS_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GETABOUTUS_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response)

    if (id) {
      const aboutUs = response.data;
      dispatch({
        type: FETCH_ABOUTUS_SUCCESS,
        payload: [aboutUs],
      });
    } else {
      const aboutUsList = response.data;

      dispatch({
        type: FETCH_ABOUTUS_SUCCESS,
        payload: aboutUsList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ABOUTUS_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchAboutUsSuccess = (aboutUs) => ({
    type: FETCH_ABOUTUS_SUCCESS,
    payload: aboutUs,
  });
  
  export const fetchAboutUsFailure = (error) => ({
    type: FETCH_ABOUTUS_FAILURE,
    payload: error,
  });
  
  export const addAboutUsRequest = (aboutUs, navigate) => async (dispatch) => {
    dispatch({ type: ADD_ABOUTUS_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Title', aboutUs.Title);
        formData.append('Slug', aboutUs.Slug);
        formData.append('Description', aboutUs.Description);
        formData.append('Keywords', aboutUs.Keywords);
        formData.append('Video', aboutUs.Video);
        formData.append('Image', aboutUs.Image);        


      const response = await axios.post(ADDABOUTUS_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_ABOUTUS_SUCCESS, payload: [response.data] });
      navigate('/about'); 

  } catch (error) {
    dispatch({
      type: ADD_ABOUTUS_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding About Us.")
  }
  };
  
  export const addAboutUsSuccess = () => ({
    type: ADD_ABOUTUS_SUCCESS,
  });
  
  export const addAboutUsFailure = (error) => ({
    type: ADD_ABOUTUS_FAILURE,
    payload: error,
  });


  export const deleteAboutUsRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_ABOUTUS_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETEABOUTUS_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_ABOUTUS_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_ABOUTUS_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete About Us');
    }
  };

  export const deleteAboutUsSuccess = () => ({
    type: DELETE_ABOUTUS_SUCCESS,
  });
  
  export const deleteAboutUsFailure = (error) => ({
    type: DELETE_ABOUTUS_FAILURE,
    payload: error,
  });


  export const multipleDeleteAboutUsRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_ABOUTUS_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_ABOUTUS_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_ABOUTUS_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateAboutUsRequest = (aboutUs, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_ABOUTUS_REQUEST });
    const token = localStorage.getItem('token');
    try {

      console.log(aboutUs.Keywords)


      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Title', aboutUs.Title);
        formData.append('Slug', aboutUs.Slug);
        formData.append('Description', aboutUs.Description);
        formData.append('Keywords', aboutUs.Keywords);
        formData.append('Video', aboutUs.Video);
        formData.append('Image', aboutUs.Image); 

      const response = await axios.put(`${UPDATEABOUTUS_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_ABOUTUS_SUCCESS, payload: [response.data] });
      navigate('/about'); 

    } catch (error) {
      dispatch({
        type: UPDATE_ABOUTUS_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update AboutUs');
    }
  };
  

  
  
