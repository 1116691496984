import { FETCH_COLOR_COMBINATION_REQUEST, FETCH_COLOR_COMBINATION_SUCCESS, FETCH_COLOR_COMBINATION_FAILURE, UPDATE_COLOR_COMBINATION_REQUEST, UPDATE_COLOR_COMBINATION_SUCCESS, UPDATE_COLOR_COMBINATION_FAILURE } from "../actions/colorCombinationActions";

// Initial state
const initialState = {
    colorCombination: null,
    loading: false,
    error: null,
  };
  
  // Reducer
  const colorCombinationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COLOR_COMBINATION_REQUEST:
      case UPDATE_COLOR_COMBINATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_COLOR_COMBINATION_SUCCESS:
        return {
          ...state,
          loading: false,
          colorCombination: action.payload,
        };
      case UPDATE_COLOR_COMBINATION_SUCCESS:
        return {
          ...state,
          loading: false,
          colorCombination: action.payload
        };
      case FETCH_COLOR_COMBINATION_FAILURE:
      case UPDATE_COLOR_COMBINATION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default colorCombinationReducer;

  