import { act } from "react-dom/test-utils";
import {
  FETCH_ENQUIRY_FAILURE,
  FETCH_ENQUIRY_SUCCESS,
  FETCH_ENQUIRY_REQUEST,
  DELETE_ENQUIRY_REQUEST,
  DELETE_ENQUIRY_SUCCESS,
  DELETE_ENQUIRY_FAILURE,
  MULTIPLE_DELETE_ENQUIRY_SUCCESS,
} from "../actions/enquiryActions";

// Initial state
const initialState = {
  enquiry: [],
  loading: false,
  error: null,
};

// Reducer
const enquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
  
      case DELETE_ENQUIRY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

      
    case FETCH_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiry: action.payload,
      };
    
      
      case DELETE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiry: state.enquiry.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_ENQUIRY_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedEnquiry = state.enquiry.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          enquiry: updatedEnquiry,
        };

        
      
    case FETCH_ENQUIRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
      case DELETE_ENQUIRY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    
    default:
      return state;
  }
};

export default enquiryReducer;
