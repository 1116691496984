import { act } from "react-dom/test-utils";
import {
  FETCH_PRODUCT_VARIANT_FAILURE,
  FETCH_PRODUCT_VARIANT_SUCCESS,
  FETCH_PRODUCT_VARIANT_REQUEST,
  GENERATE_PRODUCT_VARIANT_REQUEST,
  GENERATE_PRODUCT_VARIANT_SUCCESS,
  GENERATE_PRODUCT_VARIANT_FAILURE,
  DELETE_PRODUCT_VARIANT_REQUEST,
  DELETE_PRODUCT_VARIANT_SUCCESS,
  DELETE_PRODUCT_VARIANT_FAILURE,
  MULTIPLE_DELETE_PRODUCT_VARIANT_SUCCESS,
  UPDATE_PRODUCT_VARIANT_REQUEST,
  UPDATE_PRODUCT_VARIANT_SUCCESS,
  UPDATE_PRODUCT_VARIANT_FAILURE
} from "../actions/variantActions";

// Initial state
const initialState = {
  productVariant: [],
  loading: false,
  error: null,
};

// Reducer
const productVariantReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_VARIANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GENERATE_PRODUCT_VARIANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_PRODUCT_VARIANT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_PRODUCT_VARIANT_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_PRODUCT_VARIANT_SUCCESS:
      return {
        ...state,
        loading: false,
        productVariant: action.payload,
      };
    case GENERATE_PRODUCT_VARIANT_SUCCESS:
      return {
        ...state,
        loading: false,
        productVariant: action.payload,
      };
      
      case DELETE_PRODUCT_VARIANT_SUCCESS:
      const  filteredData = state.productVariant.map(entry => entry.filter(data => data.id !== action.payload.deletedId))
      console.log(filteredData)

      return {
        ...state,
        loading: false,
        productVariant: filteredData,
      };

      case MULTIPLE_DELETE_PRODUCT_VARIANT_SUCCESS:
        return {
          ...state,
          loading: false,
          productVariant: [],
        };

        case UPDATE_PRODUCT_VARIANT_SUCCESS:
        const updatedVariant = action.payload[0];

          const updatedProductVariant = state.productVariant.map(innerArray => {
            return innerArray.map(entry => {
              if (entry.id === updatedVariant.id) {
                return {
                  ...entry,
                  image: updatedVariant.image,
                  sku: updatedVariant.sku,
                  qty: updatedVariant.qty,
                  basePrice: updatedVariant.basePrice,
                  discountAmount: updatedVariant.discountAmount,
                  finalPrice: updatedVariant.finalPrice,
                };
              } 
              return entry;
            });
          });

        return {
          ...state,
          loading: false,
          productVariant: updatedProductVariant,
        };

      
    case FETCH_PRODUCT_VARIANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case  GENERATE_PRODUCT_VARIANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_PRODUCT_VARIANT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_PRODUCT_VARIANT_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default productVariantReducer;
