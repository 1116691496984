import {React , useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { fetchGalleryRequest, deleteGalleryRequest, multipleDeleteGalleryRequest, addGalleryRequest, updateGalleryRequest } from '../../redux_store/actions/galleryActions';
import { useDispatch , useSelector } from 'react-redux';
import { BASE_URL } from '../../apis/url_constants';
import { ColorRing } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";



const Gallery = () => {
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { gallery, loading, error } = useSelector(
        (state) => state.gallery
      );


      const handleAddGallery = () => {

        setEditId(null)
        setEditMode(false);

        setInitialValues({
            Title: "",
            Slug: "",
            Image: null,
            Order: "",
        });

        setAccordionOpen(true);

      };

      const [initialValues, setInitialValues] = useState({
        Title: "",
        Slug: "",
        Image: null,
        Order: "",
      });
      
      const validationSchema = Yup.object().shape({
        Title: Yup.string(),
        Slug: Yup.string(),
        Image: Yup.mixed().nullable(),
        Order: Yup.number().integer().nullable(),
      });
      

    useEffect(() => {
        dispatch(fetchGalleryRequest());
      }, [dispatch]);



      const handleMulitpleDelete = () => {

        setInitialValues({
            Title: "",
            Slug: "",
            Image: null,
            Order: "",
        });

        setAccordionOpen(false);

        const confirmAlert = (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => { 
                const selectedIds = selectedRows.map(row => row.id);
                dispatch(multipleDeleteGalleryRequest(selectedIds));
                setSelectedRows([]);
                hideAlert();
            }}
              onCancel={hideAlert}
            >
              This action cannot be undone.
            </SweetAlert>
          );
        
          setShowAlert(confirmAlert);
      };

      const handleDelete = (row) => {

        setInitialValues({
            Title: "",
            Slug: "",
            Image: null,
            Order: "",
        });

        setAccordionOpen(false);


        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteGalleryRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };
      
      const handleSubmit = (values) => {
        if(editMode) {
        dispatch(updateGalleryRequest(values , editId))
        .then(() => {
          // Reset initial values
          setInitialValues({
            Title: "",
        Slug: "",
        Image: null,
        Order: "",
          });

          setAccordionOpen(false);

          dispatch(fetchGalleryRequest());
        }) 
      }else{
        dispatch(addGalleryRequest(values))
        .then(() => {
          // Reset initial values
          setInitialValues({
            Title: "",
        Slug: "",
        Image: null,
        Order: "",
          });

          setAccordionOpen(false);

          dispatch(fetchGalleryRequest());
        }) 
      }
      
      }
      
     
    
      const handleEdit = (row) => {

        setEditId(row.id)
        setEditMode(true);
        setInitialValues({
          Title: row.title,
          Slug: row.slug,
          Image: null,
          Order: row.order,
        });

        setAccordionOpen(true);

      };
    
      const handleView = (row) => {
        console.log('View row:', row);
      };

      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };
      
      const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

      const columns = gallery && gallery.length > 0 ? Object.keys(gallery[0]).map((key) => {

        if (key === 'img') {
            return {
              name: 'Image',
              cell: (row) => (
                row[key] ? (
                  <img src={`${BASE_URL}${row[key]}`} alt="Image" width="100" height="100" />
                ) : (
                  <div>No Image Available</div>
                )
              ),
              sortable: false,
            };
          }

          
          if (key === 'order') {
            return {
              name: 'Sort Order',
              selector: (row) => row[key],
              sortable: true,
              defaultSortField: key,
              defaultSortAsc: true,
            };
          }

        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnsToRemove = ['Id' , 'Slug'];
        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
                <i onClick={() => handleEdit(row)} className='ti ti-pencil text-info fs-6 px-2'></i>
                {/* <Link to={`/addAbout/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-2'></i></Link> */}
              </>
            ),
            sortable: false,
          };
          

          const modifiedColumns = [...filteredColumns, actionsColumn];


          console.log(gallery)

  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>Gallery</h3>
                    <div className='d-flex align-items-center'>
                    {selectedRows.length > 0 && (
                        <button onClick={handleMulitpleDelete} className='btn btn-danger'>Delete Selected</button>
                    )}
                    <a href='/' className='btn btn-dark ms-2'>Preview</a>
                    <button onClick={handleAddGallery} className='btn btn-primary ms-2'> Add Images</button>
                    </div>
                </div>
                {accordionOpen && (
                  <div className='card-body pb-0'>
                      <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-4">
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Title"
                            name="Title"
                            onChange={(event) => {
                              const title = event.target.value;
                              setFieldValue("Title", title);
                              setFieldValue(
                                "Slug",
                                title
                                  .toLowerCase()
                                  .replace(/[^a-zA-Z0-9 ]/g, "")
                                  .replace(/ /g, "-")
                              );
                            }}
                            className="form-control border-0 ps-2"
                            placeholder="Title"
                          />
                        </div>
                        <ErrorMessage
                          name="Title"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 d-none">
                      <div className="mb-4">
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-link fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Slug"
                            name="Slug"
                            className="form-control border-0 ps-2"
                            placeholder="Slug"
                          />
                        </div>
                        <ErrorMessage
                          name="Slug"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-4">
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="Image"
                            name="Image"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              setFieldValue("Image", file || null);
                            }}
                            className="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="Image"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode &&
                        gallery.length > 0 &&
                        !loading &&
                        gallery[0].img && (
                          <div className="py-3">
                            <img
                              src={`${BASE_URL}${gallery[0].img}`}
                              alt="Image"
                              width="100"
                              height="100"
                            />
                          </div>
                        )}
                      {editMode &&
                        ((loading && gallery.length === 0) ||
                          gallery === undefined) && (
                          <div>Loading image...</div>
                        )}
                      {editMode && gallery.length === 0 && !loading && (
                        <div>No image available</div>
                      )}
                    </div>

                    <div className="col-md-4    ">
                      <div className="mb-4">
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                        <Field
                          type="number"
                          id="Order"
                          name="Order"
                          className="form-control"
                          placeholder="Order"
                        />
                        </div>
                        <ErrorMessage
                          name="Order"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    </div>
                  
                      <div className="action-form">
                        <div className="mb-3 mb-0 text-end">
                          <button
                            type="submit"
                            className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                          >
                            {editMode ? <text>Save</text> : <text>Add</text>}
                          </button>
                          <button
                            onClick={() => {setAccordionOpen(false)}}
                            className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>


                  
                  

                </Form>
              )}
            </Formik>
                  </div>  
                )}
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={gallery}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default Gallery