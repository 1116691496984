import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_VIDEO_URL , ADD_VIDEO_URL, DELETE_VIDEO_URL, UPDATE_VIDEO_URL, MULTIPLE_VIDEO_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_VIDEO_REQUEST = 'FETCH_VIDEO_REQUEST';
export const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS';
export const FETCH_VIDEO_FAILURE = 'FETCH_VIDEO_FAILURE';

export const ADD_VIDEO_REQUEST = 'ADD_VIDEO_REQUEST';
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS';
export const ADD_VIDEO_FAILURE = 'ADD_VIDEO_FAILURE';

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE';

export const MULTIPLE_DELETE_VIDEO_SUCCESS = 'MULTIPLE_DELETE_VIDEO_SUCCESS';

export const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST';
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS';
export const UPDATE_VIDEO_FAILURE = 'UPDATE_VIDEO_FAILURE';



export const fetchVideosRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_VIDEO_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_VIDEO_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const videos = response.data;
      dispatch({
        type: FETCH_VIDEO_SUCCESS,
        payload: [videos],
      });
    } else {
      const videosList = response.data;
      dispatch({
        type: FETCH_VIDEO_SUCCESS,
        payload: videosList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_VIDEO_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchVideosSuccess = (videos) => ({
    type: FETCH_VIDEO_SUCCESS,
    payload: videos,
  });
  
  export const fetchVideosFailure = (error) => ({
    type: FETCH_VIDEO_FAILURE,
    payload: error,
  });
  
  export const addVideosRequest = (videos) => async (dispatch) => {
    dispatch({ type: ADD_VIDEO_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Title', videos.Title);
        formData.append('Code', videos.Code); 


      const response = await axios.post(ADD_VIDEO_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_VIDEO_SUCCESS, payload: [response.data] }); 

  } catch (error) {
    dispatch({
      type: ADD_VIDEO_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Videos.")
  }
  };
  
  export const addVideosSuccess = () => ({
    type: ADD_VIDEO_SUCCESS,
  });
  
  export const addVideosFailure = (error) => ({
    type: ADD_VIDEO_FAILURE,
    payload: error,
  });


  export const deleteVideosRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_VIDEO_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_VIDEO_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_VIDEO_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_VIDEO_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Videos');
    }
  };

  export const deleteVideosSuccess = () => ({
    type: DELETE_VIDEO_SUCCESS,
  });
  
  export const deleteVideosFailure = (error) => ({
    type: DELETE_VIDEO_FAILURE,
    payload: error,
  });


  export const multipleDeleteVideosRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_VIDEO_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_VIDEO_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_VIDEO_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_VIDEO_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateVideosRequest = (videos, id) => async (dispatch) => {
    dispatch({ type: UPDATE_VIDEO_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Title', videos.Title);
        formData.append('Code', videos.Code); 

      const response = await axios.put(`${UPDATE_VIDEO_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: UPDATE_VIDEO_SUCCESS, payload: [response.data] }); 

    } catch (error) {
      dispatch({
        type: UPDATE_VIDEO_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Videos');
    }
  };
  

  
  
