import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  fetchProductVariantRequest,
  generateProductVariantRequest,
  deleteProductVariantRequest,
  multipleDeleteProductVariantRequest,
  updateProductVariantRequest,
} from "../../redux_store/actions/variantActions";
import { BASE_URL } from "../../apis/url_constants";
import { fetchProductRequest } from "../../redux_store/actions/productActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { ColorRing } from "react-loader-spinner";

const ProductVariant = () => {
  const { id } = useParams();
  const [showAlert, setShowAlert] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [variantChanges, setVariantChanges] = useState({});

  const { productVariant, loading, error } = useSelector(
    (state) => state.productVariant
  );
  const { product, productloading, producterror } = useSelector(
    (state) => state.product
  );

  const [initialValues, setInitialValues] = useState([]);

  const variantSchema = Yup.object().shape({
    basePrice: Yup.number().typeError("Only Numbers"),
    discountAmount: Yup.number().typeError("Only Numbers"),
    qty: Yup.number().typeError("Only Numbers"),
  });

  // Create the validation schema for the array of variants
  const validationSchema = Yup.array().of(variantSchema);

  const handleGenerateVariants = () => {
    dispatch(generateProductVariantRequest(id));
  };

  useEffect(() => {
    dispatch(fetchProductVariantRequest(id));
    dispatch(fetchProductRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    // Update the initialValues state once productVariant is fetched
    setInitialValues(productVariant);
  }, [productVariant]);

  const optionList = product.flatMap((product) =>
    product.options.map((option) => option.name)
  );

  const handleDelete = (variantId) => {
    const confirmAlert = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => {
          dispatch(deleteProductVariantRequest(variantId));
          hideAlert();
        }}
        onCancel={hideAlert}
      >
        This action cannot be undone.
      </SweetAlert>
    );

    setShowAlert(confirmAlert);
  };

  const handleMulitpleDelete = () => {
    const confirmAlert = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => {
          dispatch(multipleDeleteProductVariantRequest(id));
          hideAlert();
        }}
        onCancel={hideAlert}
      >
        This action cannot be undone.
      </SweetAlert>
    );

    setShowAlert(confirmAlert);
  };

  const hideAlert = () => {
    setShowAlert(null);
  };

  const handleUpdate = (variantId, updateData, index, innerIndex) => {
    console.log(updateData)
    dispatch(updateProductVariantRequest(variantId, updateData));
    setVariantChanges((prevState) => ({
      ...prevState,
      [`${index}-${innerIndex}`]: false, // Mark this row as unchanged
    }));
  };

  const handleFieldChange = (index, innerIndex) => {
    setVariantChanges((prevState) => ({
      ...prevState,
      [`${index}-${innerIndex}`]: true, // Mark this row as changed
    }));
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ variants: initialValues }} // Pass the values to Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values.variants);
        }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4>Product Variant</h4>
                  <div className="d-flex">
                    <a
                      onClick={handleGenerateVariants}
                      className="btn btn-primary btn-sm"
                    >
                      Generate Variants
                    </a>
                    <a
                      onClick={handleMulitpleDelete}
                      className="btn btn-danger ms-2 btn-sm"
                    >
                      Delete Variants
                    </a>
                  </div>
                </div>
                {loading ? (
                  <div className="d-flex p-5 justify-content-center align-items-center">
                    <ColorRing />
                  </div>
                ) : (
                  <div className="card-body p-4">
                    {values.variants.length > 0 &&
                    values.variants[0].length > 0 ? (
                      <div className="table-responsive rounded-2 mb-4">
                        <table className="table border text-nowrap customize-table mb-0 align-middle">
                          <thead className="text-dark fs-4">
                            <tr>
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">Id</h6>
                              </th>
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">Image</h6>
                              </th>
                              {optionList &&
                                optionList.map((optionName) => (
                                  <th key={optionName}>
                                    <h6 className="fs-4 fw-semibold mb-0">
                                      {optionName}
                                    </h6>
                                  </th>
                                ))}
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">SKU</h6>
                              </th>
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">
                                  BasePrice
                                </h6>
                              </th>
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">
                                  Discount
                                </h6>
                              </th>
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">Final</h6>
                              </th>
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">QTY</h6>
                              </th>
                              <th>
                                <h6 className="fs-4 fw-semibold mb-0">
                                  Actions
                                </h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.variants.map((innerArray, index) => (
                              <React.Fragment key={index}>
                                {innerArray.map((variant, innerIndex) => (
                                  <tr key={variant.id}>
                                    <td>#{variant.id}</td>
                                    <td className="d-flex flex-column justify-content-center align-items-center">
                                      <img
                                        src={`${BASE_URL}${variant.image}`}
                                        style={{
                                          height: "80px",
                                          width: "80px",
                                        }}
                                      />
                                          <button
                                            type="button"
                                            id={`ImageUploadButton_${variant.id}`}
                                            className="btn btn-info btn-sm mt-2 fs-1"
                                            onClick={() => {
                                              document.getElementById(`ImageUpload_${variant.id}`).click();
                                            }}
                                          >
                                            Upload Image
                                          </button>
                                        <input
                                          type="file"
                                          id={`ImageUpload_${variant.id}`}
                                          name={`variants.${index}.${innerIndex}.image`}
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            console.log(file)
                                            setFieldValue(
                                              `variants.${index}.${innerIndex}.image`, file || null
                                            );
                                            handleUpdate(
                                              variant.id,
                                              {
                                                ...values.variants[index][innerIndex],
                                                image: file || null,
                                              },
                                              index,
                                              innerIndex
                                            );
                                          }}
                                          style={{ display: "none" }}
                                        />
                                    </td>
                                    {variant.variantOptions.map((option) => (
                                      <td>{option.optionValue.text}</td>
                                    ))}
                                    <td>
                                      <Field
                                        as="input"
                                        type="text"
                                        name={`variants.${index}.${innerIndex}.sku`}
                                        className="form-control"
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setFieldValue(
                                            `variants.${index}.${innerIndex}.sku`,
                                            newValue
                                          );
                                          handleFieldChange(index, innerIndex);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.${innerIndex}.sku`}
                                        component="div"
                                        className="mt-1 error"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="input"
                                        type="number"
                                        name={`variants.${index}.${innerIndex}.basePrice`}
                                        className="form-control"
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setFieldValue(
                                            `variants.${index}.${innerIndex}.basePrice`,
                                            newValue
                                          );
                                          handleFieldChange(index, innerIndex);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.${innerIndex}.basePrice`}
                                        component="div"
                                        className="mt-1 error"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="input"
                                        type="number"
                                        name={`variants.${index}.${innerIndex}.discountAmount`}
                                        className="form-control"
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setFieldValue(
                                            `variants.${index}.${innerIndex}.discountAmount`,
                                            newValue
                                          );
                                          handleFieldChange(index, innerIndex);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.${innerIndex}.discountAmount`}
                                        component="div"
                                        className="mt-1 error"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="input"
                                        type="number"
                                        name={`variants.${index}.${innerIndex}.finalPrice`}
                                        disabled={true}
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.${innerIndex}.finalPrice`}
                                        component="div"
                                        className="mt-1 error"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="input"
                                        type="number"
                                        name={`variants.${index}.${innerIndex}.qty`}
                                        className="form-control"
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setFieldValue(
                                            `variants.${index}.${innerIndex}.qty`,
                                            newValue
                                          );
                                          handleFieldChange(index, innerIndex);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`variants.${index}.${innerIndex}.qty`}
                                        component="div"
                                        className="mt-1 error"
                                      />
                                    </td>
                                    <td>
                                      <i
                                        onClick={() => handleDelete(variant.id)}
                                        className="ti ti-trash text-danger fs-6 px-1"
                                      ></i>
                                      <i
                                        className={`ti ti-upload text-primary fs-6 px-1 ${
                                          variantChanges[
                                            `${index}-${innerIndex}`
                                          ]
                                            ? ""
                                            : "d-none"
                                        }`}
                                        onClick={() => {
                                          handleUpdate(
                                            variant.id,
                                            values.variants[index][innerIndex],
                                            index,
                                            innerIndex
                                          );
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <h4 className="my-5 text-center">
                        No Variant's Are Generated Yet!
                      </h4>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showAlert}
    </>
  );
};

export default ProductVariant;
