import { act } from "react-dom/test-utils";
import {
  FETCH_SUBSCRIBER_FAILURE,
  FETCH_SUBSCRIBER_SUCCESS,
  FETCH_SUBSCRIBER_REQUEST,
  DELETE_SUBSCRIBER_REQUEST,
  DELETE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_FAILURE,
  MULTIPLE_DELETE_SUBSCRIBER_SUCCESS,
} from "../actions/subscriberActions";

// Initial state
const initialState = {
  subscriber: [],
  loading: false,
  error: null,
};

// Reducer
const subscriberReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
  
      case DELETE_SUBSCRIBER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

      
    case FETCH_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriber: action.payload,
      };
    
      
      case DELETE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriber: state.subscriber.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_SUBSCRIBER_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedSubscriber = state.subscriber.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          subscriber: updatedSubscriber,
        };

        
      
    case FETCH_SUBSCRIBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
      case DELETE_SUBSCRIBER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    
    default:
      return state;
  }
};

export default subscriberReducer;
