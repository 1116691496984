import {
  FETCH_CUSTOM_PAGES_FAILURE,
  FETCH_CUSTOM_PAGES_SUCCESS,
  FETCH_CUSTOM_PAGES_REQUEST,
  ADD_CUSTOM_PAGES_REQUEST,
  ADD_CUSTOM_PAGES_SUCCESS,
  ADD_CUSTOM_PAGES_FAILURE,
  DELETE_CUSTOM_PAGES_REQUEST,
  DELETE_CUSTOM_PAGES_SUCCESS,
  DELETE_CUSTOM_PAGES_FAILURE,
  MULTIPLE_DELETE_CUSTOM_PAGES_SUCCESS,
  UPDATE_CUSTOM_PAGES_REQUEST,
  UPDATE_CUSTOM_PAGES_SUCCESS,
  UPDATE_CUSTOM_PAGES_FAILURE
} from "../actions/customPagesActions";

// Initial state
const initialState = {
  customPages: [],
  loading: false,
  error: null,
};

// Reducer
const customPagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOM_PAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_CUSTOM_PAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_CUSTOM_PAGES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_CUSTOM_PAGES_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_CUSTOM_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        customPages: action.payload,
      };
    case ADD_CUSTOM_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        customPages: action.payload,
      };
      
      case DELETE_CUSTOM_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        customPages: state.customPages.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_CUSTOM_PAGES_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedCustomPages = state.customPages.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          customPages: updatedCustomPages,
        };

        case UPDATE_CUSTOM_PAGES_SUCCESS:
          return {
            ...state,
            loading: false,
            customPages: action.payload,
          };

      
    case FETCH_CUSTOM_PAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_CUSTOM_PAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_CUSTOM_PAGES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_CUSTOM_PAGES_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default customPagesReducer;
