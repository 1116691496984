import {React , useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { fetchTestimonialRequest, deleteTestimonialRequest, multipleDeleteTestimonialRequest } from '../../redux_store/actions/testimonialActions';
import { useDispatch , useSelector } from 'react-redux';
import { BASE_URL } from '../../apis/url_constants';
import { ColorRing } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';


const Testimonial = () => {
    const [showAlert, setShowAlert] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { testimonial, loading, error } = useSelector(
        (state) => state.testimonial
      );

    useEffect(() => {
        dispatch(fetchTestimonialRequest());
      }, [dispatch]);

      const handleMulitpleDelete = () => {

        const confirmAlert = (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => { 
                const selectedIds = selectedRows.map(row => row.id);
                dispatch(multipleDeleteTestimonialRequest(selectedIds));
                setSelectedRows([]);
                hideAlert();
            }}
              onCancel={hideAlert}
            >
              This action cannot be undone.
            </SweetAlert>
          );
        
          setShowAlert(confirmAlert);
      };

      const handleDelete = (row) => {
        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteTestimonialRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };
      
     
    
      const handleEdit = (row) => {
        // Implement your edit logic here
        console.log('Edit row:', row);
      };
    
      const handleView = (row) => {
        // Implement your view logic here
        console.log('View row:', row);
      };

      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };

      
      const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

      const columns = testimonial && testimonial.length > 0 ? Object.keys(testimonial[0]).map((key) => {
        if (key === 'image') {
          return {
            name: 'Image',
            cell: (row) => (
              row[key] ? (
                <img src={`${BASE_URL}${row[key]}`} alt="Image" width="100" height="100" />
              ) : (
                <div>No Image Available</div>
              )
            ),
            sortable: false,
          };
        }

        if (key === 'sortOrder') {
          return {
            name: 'Sort Order',
            selector: (row) => row[key],
            sortable: true,
            defaultSortField: key,
            defaultSortAsc: true,
          };
        }
      
        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnsToRemove = ['Id' , 'Review' ,  'CreatedAt' , 'LastModified'];
        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
                <Link to={`/addTestimonial/${row.id}`}><i className='ti ti-pencil text-info fs-6 px-2'></i></Link>
                <Link to={`/addTestimonial/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-2'></i></Link>
              </>
            ),
            sortable: false,
          };

          const modifiedColumns = [...filteredColumns, actionsColumn];

  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>Testimonial</h3>
                    <div className='d-flex align-items-center'>
                    {selectedRows.length > 0 && (
                        <button onClick={handleMulitpleDelete} className='btn btn-danger'>Delete Selected</button>
                    )}
                    <Link to="/addTestimonial" className='btn btn-primary ms-2'>Add Testimonial</Link>
                    </div>
                </div>
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={testimonial}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default Testimonial