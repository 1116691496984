import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  addDealRequest,
  fetchDealRequest,
  updateDealRequest,
} from "../../redux_store/actions/dealActions";
import {
  fetchProductRequest
} from "../../redux_store/actions/productActions";

const AddDeal = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { deal, loading, error } = useSelector(
    (state) => state.deal
  );

  const { product, ploading, perror } = useSelector(
    (state) => state.product
  );

  const [initialValues, setInitialValues] = useState({
    ProductId: null,
    RegularPrice: "",
    Date: "",
    Price: "",
  });

  const validationSchema = Yup.object().shape({
    ProductId: Yup.number().nullable().required("Please Select a Product"),
    Date: Yup.date().required("Date is Required"),
    Price: Yup.number().required("Price is Required").positive("Price must be a positive number"),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateDealRequest(values, id, navigate))
      : 
      // console.log(values)
      dispatch(addDealRequest(values, navigate));
  };

  useEffect(() => {

    // fetching categories for dropdown
    dispatch(fetchProductRequest());


    if (editMode) {
      dispatch(fetchDealRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && deal && deal.length > 0) {
      const filteredDeal = deal[0];

      const regularPrice = product.find(p => p.id === filteredDeal.productId)

      setInitialValues({
        ProductId: filteredDeal.productId || null,
        RegularPrice: filteredDeal.productId ? regularPrice.basePrice : "",
        Price:  filteredDeal.price !== null
        ? filteredDeal.price
        :"",
        Date:  filteredDeal.date !== null
        ? new Date(filteredDeal.date).toISOString().split('T')[0]
        :"",
      });
    }
  }, [editMode, deal]);


  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{editMode && viewMode ? <text>View Deal</text> : !viewMode && editMode ? <text>Edit Deal</text> : <text>Add Deal</text>}</h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="ProductId"
                          className="form-label fw-semibold"
                        >
                          Product
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-category-2 fs-6"></i>
                          </span>
                          <Field
                            as="select"
                            id="ProductId"
                            name="ProductId"
                            className="form-select border-0 ps-2"
                            disabled={viewMode}
                             onChange={event => {
                                const selectedProductId = event.target.value;
                                const selectedProduct = product.find(p => p.id === parseInt(selectedProductId, 10));
                                console.log(selectedProduct)
                                if (selectedProduct) {
                                  setFieldValue("ProductId", selectedProductId);
                                  setFieldValue("RegularPrice", selectedProduct.basePrice);
                                }
                              }}
                          >
                            <option value="" selected>
                              Select Product
                            </option>
                            {product.map((product) => (
                              <option key={product.id} value={product.id}>
                                {product.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <ErrorMessage
                          name="ProductId"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="RegularPrice"
                          className="form-label fw-semibold"
                        >
                          Regular Price
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-calculator fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="RegularPrice"
                            name="RegularPrice"
                            className="form-control border-0 ps-2"
                            placeholder="RegularPrice"
                            disabled={true}
                          />
                        </div>
                        <ErrorMessage
                          name="RegularPrice"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="Price"
                          className="form-label fw-semibold"
                        >
                          Price
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-calculator fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="Price"
                            name="Price"
                            className="form-control border-0 ps-2"
                            placeholder="Price"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Price"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Date"
                          className="form-label fw-semibold"
                        >
                          Date
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-calendar fs-6"></i>
                          </span>
                          <Field
                            type="date"
                            id="Date"
                            name="Date"
                            className="form-control border-0 ps-2"
                            placeholder="Date"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Date"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                  
                  </div>

                  {viewMode ? <div className="py-3"></div> :
                  <div className="action-form">
                    <div className="mb-3 mb-0 text-end">
                      <button
                        type="submit"
                        className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                      >
                        Save
                      </button>
                      <Link
                        to="/deal"
                        className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div> }
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDeal;
