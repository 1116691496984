import {
  FETCH_COUPON_FAILURE,
  FETCH_COUPON_SUCCESS,
  FETCH_COUPON_REQUEST,
  ADD_COUPON_REQUEST,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAILURE,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  MULTIPLE_DELETE_COUPON_SUCCESS,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAILURE
} from "../actions/couponActions";

// Initial state
const initialState = {
  coupon: [],
  loading: false,
  error: null,
};

// Reducer
const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_COUPON_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_COUPON_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_COUPON_SUCCESS:

      return {
        ...state,
        loading: false,
        coupon: action.payload,
      };
    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload,
      };
      
      case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: state.coupon.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_COUPON_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedCoupon= state.coupon.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          coupon: updatedCoupon,
        };

        case UPDATE_COUPON_SUCCESS:
          return {
            ...state,
            loading: false,
            coupon: action.payload,
          };

      
    case FETCH_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_COUPON_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_COUPON_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default couponReducer;
