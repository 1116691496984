import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-form-wizard-component/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import * as Yup from "yup";
import { BASE_URL } from "../../apis/url_constants";
import { fetchOrderRequest } from "../../redux_store/actions/orderActions";

const OrderDetails = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { order, loading, error } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(fetchOrderRequest(id));
  }, [dispatch, id]);

  console.log(order)
  return (
    <>
 {order[0] && (
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3><span>Order Details</span></h3>
          </div>
          <div className="card-body pb-0">
            <div className="order-details">
              <div>
                <h3>User Information:</h3>
                <p>Name: {order[0].user.fullName}</p>
                <p>Email: {order[0].user.email}</p>
                <p>Phone Number: {order[0].user.phoneNumber}</p>
              </div>
              <div>
                <h3>Shipping Address:</h3>
                <p>Name: {order[0].address.name}</p>
                <p>Address: {order[0].address.mainAddress}</p>
                <p>City: {order[0].address.city}</p>
                <p>State: {order[0].address.state}</p>
                <p>Pin Code: {order[0].address.pinCode}</p>
              </div>
              <div>
                <h3>Order Information:</h3>
                <p>Payment Status: {order[0].paymentStatus}</p>
                <p>Amount: {order[0].amount}</p>
                <p>Date: {new Date(order[0].date).toLocaleString()}</p>
              </div>
              <div>
                <h3>Ordered Products:</h3>
                <div className="order-details">
                                      <table className="table mb-5">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Net Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order[0].orderProducts.map((product) => (
                        <tr key={product.id}>
                          <td>
                            {product.name}
                            {product.orderProductOptions && (
                              <ul>
                                {product.orderProductOptions.map((option) => (
                                  <li key={option.id}>
                                    {option.name}: {option.value}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </td>
                          <td>{product.quantity}</td>
                          <td>{product.netAmount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
                 
              </div>

    )}
    </>
    
  );
};

export default OrderDetails;
