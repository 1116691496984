import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_DEAL_URL , ADD_DEAL_URL, DELETE_DEAL_URL, UPDATE_DEAL_URL, MULTIPLE_DEAL_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_DEAL_REQUEST = 'FETCH_DEAL_REQUEST';
export const FETCH_DEAL_SUCCESS = 'FETCH_DEAL_SUCCESS';
export const FETCH_DEAL_FAILURE = 'FETCH_DEAL_FAILURE';

export const ADD_DEAL_REQUEST = 'ADD_DEAL_REQUEST';
export const ADD_DEAL_SUCCESS = 'ADD_DEAL_SUCCESS';
export const ADD_DEAL_FAILURE = 'ADD_DEAL_FAILURE';

export const DELETE_DEAL_REQUEST = 'DELETE_DEAL_REQUEST';
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS';
export const DELETE_DEAL_FAILURE = 'DELETE_DEAL_FAILURE';

export const MULTIPLE_DELETE_DEAL_SUCCESS = 'MULTIPLE_DELETE_DEAL_SUCCESS';

export const UPDATE_DEAL_REQUEST = 'UPDATE_DEAL_REQUEST';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_FAILURE = 'UPDATE_DEAL_FAILURE';



export const fetchDealRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_DEAL_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_DEAL_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const deal = response.data;
      dispatch({
        type: FETCH_DEAL_SUCCESS,
        payload: [deal],
      });
    } else {
      const dealList = response.data;
      dispatch({
        type: FETCH_DEAL_SUCCESS,
        payload: dealList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DEAL_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchDealSuccess = (deal) => ({
    type: FETCH_DEAL_SUCCESS,
    payload: deal,
  });
  
  export const fetchDealFailure = (error) => ({
    type: FETCH_DEAL_FAILURE,
    payload: error,
  });
  
  export const addDealRequest = (deal, navigate) => async (dispatch) => {
    dispatch({ type: ADD_DEAL_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData(); 
        formData.append('ProductId', deal.ProductId);     
        formData.append('Date', deal.Date);     
        formData.append('Price', deal.Price);     


      const response = await axios.post(ADD_DEAL_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_DEAL_SUCCESS, payload: [response.data] });
      navigate('/deal'); 

  } catch (error) {
    dispatch({
      type: ADD_DEAL_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Deal.")
  }
  };
  
  export const addDealSuccess = () => ({
    type: ADD_DEAL_SUCCESS,
  });
  
  export const addDealFailure = (error) => ({
    type: ADD_DEAL_FAILURE,
    payload: error,
  });


  export const deleteDealRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_DEAL_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_DEAL_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_DEAL_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_DEAL_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Deal');
    }
  };

  export const deleteDealSuccess = () => ({
    type: DELETE_DEAL_SUCCESS,
  });
  
  export const deleteDealFailure = (error) => ({
    type: DELETE_DEAL_FAILURE,
    payload: error,
  });


  export const multipleDeleteDealRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_DEAL_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_DEAL_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_DEAL_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_DEAL_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateDealRequest = (deal, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_DEAL_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('ProductId', deal.ProductId);     
        formData.append('Date', deal.Date);     
        formData.append('Price', deal.Price);     

      const response = await axios.put(`${UPDATE_DEAL_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_DEAL_SUCCESS, payload: [response.data] });
      navigate('/deal'); 

    } catch (error) {
      dispatch({
        type: UPDATE_DEAL_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Deal');
    }
  };
  

  
  
