import { act } from "react-dom/test-utils";
import {
  FETCH_VIDEO_FAILURE,
  FETCH_VIDEO_SUCCESS,
  FETCH_VIDEO_REQUEST,
  ADD_VIDEO_REQUEST,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE,
  MULTIPLE_DELETE_VIDEO_SUCCESS,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE
} from "../actions/videoActions";

// Initial state
const initialState = {
  videos: [],
  loading: false,
  error: null,
};

// Reducer
const videosReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_VIDEO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_VIDEO_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_VIDEO_SUCCESS:

      return {
        ...state,
        loading: false,
        videos: action.payload,
      };
    case ADD_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: action.payload,
      };
      
      case DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: state.videos.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_VIDEO_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedTaxes= state.videos.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          videos: updatedTaxes,
        };

        case UPDATE_VIDEO_SUCCESS:
          return {
            ...state,
            loading: false,
            videos: action.payload,
          };

      
    case FETCH_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_VIDEO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_VIDEO_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default videosReducer;
