import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchTestimonialRequest,
  updateTestimonialRequest,
  addTestimonialRequest,
} from "../../redux_store/actions/testimonialActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";

const AddTestimonial = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { testimonial, loading, error } = useSelector((state) => state.testimonial);

  const [initialValues, setInitialValues] = useState({
    Name: "",
    Image: null,
    Review: "",
    Order: "",

  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is Required"),
    Image: Yup.mixed().nullable(),
    Review: Yup.string().required("Review is Required"),
    Order: Yup.number()
    .integer()
    .nullable(),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateTestimonialRequest(values, id, navigate))
      : 
      dispatch(addTestimonialRequest(values, navigate));
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchTestimonialRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && testimonial && testimonial.length > 0) {
      const filteredTestimonial = testimonial[0];


      setInitialValues({
        Name: filteredTestimonial.name !== null ? filteredTestimonial.name : "",
        Image: null,
        Review: filteredTestimonial.review ? filteredTestimonial.review : "",
        Order: filteredTestimonial.order || "",
      });
    }
  }, [editMode, testimonial]);


  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{editMode && viewMode ? <text>View Testimonial</text> : !viewMode && editMode ? <text>Edit Testimonial</text> : <text>Add Testimonial</text>}</h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Name"
                          className="form-label fw-semibold"
                        >
                          Name
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Name"
                            name="Name"
                            onChange={(event) => {
                              const title = event.target.value;
                              setFieldValue("Name", title);
                              setFieldValue(
                                "Slug",
                                title
                                  .toLowerCase()
                                  .replace(/[^a-zA-Z0-9 ]/g, "")
                                  .replace(/ /g, "-")
                              );
                            }}
                            className="form-control border-0 ps-2"
                            placeholder="Name"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Image"
                          className="form-label fw-semibold"
                        >
                          Image
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="Image"
                            name="Image"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              setFieldValue("Image", file || null);
                            }}
                            className="form-control"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Image"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode &&
                        testimonial.length > 0 &&
                        !loading &&
                        testimonial[0].image && (
                          <div className="py-3">
                            <img
                              src={`${BASE_URL}${testimonial[0].image}`}
                              alt="Image"
                              width="100"
                              height="100"
                            />
                          </div>
                        )}
                      {editMode &&
                        ((loading && testimonial.length === 0) ||
                          testimonial === undefined) && (
                          <div>Loading image...</div>
                        )}
                      {editMode && testimonial.length === 0 && !loading && (
                        <div>No image available</div>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="Review"
                          className="form-label fw-semibold"
                        >
                          Review
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-quote fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Review"
                            name="Review"
                            className="form-control border-0 ps-2"
                            placeholder="Testimonial"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Review"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                  

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="Order"
                          className="form-label fw-semibold"
                        >
                          Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                        <Field
                          type="number"
                          id="Order"
                          name="Order"
                          className="form-control"
                          disabled={viewMode}
                        />
                        </div>
                        <ErrorMessage
                          name="Order"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>

                  { viewMode ? <div className="py-3"></div> :
                  <div className="action-form">
                    <div className="mb-3 mb-0 text-end">
                      <button
                        type="submit"
                        className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                      >
                        Save
                      </button>
                      <Link
                        to="/testimonial"
                        className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div> }
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTestimonial;
