import {React , useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { fetchVideosRequest, deleteVideosRequest, multipleDeleteVideosRequest, addVideosRequest, updateVideosRequest } from '../../redux_store/actions/videoActions';
import { useDispatch , useSelector } from 'react-redux';
import { BASE_URL } from '../../apis/url_constants';
import { ColorRing } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";



const Videos = () => {
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { videos, loading, error } = useSelector(
        (state) => state.videos
      );


      const handleAddVideos = () => {

        setEditId(null)
        setEditMode(false);

        setInitialValues({
          Title : "",
          Code : ""
        });

        setAccordionOpen(true);

      };

      const [initialValues, setInitialValues] = useState({
        Title : "",
        Code : ""
      });
      
      const validationSchema = Yup.object().shape({
        Title: Yup.string().required('Title is required'),
        Code: Yup.string().required('Code is required'),
      });
      

    useEffect(() => {
        dispatch(fetchVideosRequest());
      }, [dispatch]);



      const handleMulitpleDelete = () => {

        setInitialValues({
          Title : "",
          Code : ""
        });

        setAccordionOpen(false);

        const confirmAlert = (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => { 
                const selectedIds = selectedRows.map(row => row.id);
                dispatch(multipleDeleteVideosRequest(selectedIds));
                setSelectedRows([]);
                hideAlert();
            }}
              onCancel={hideAlert}
            >
              This action cannot be undone.
            </SweetAlert>
          );
        
          setShowAlert(confirmAlert);
      };

      const handleDelete = (row) => {

        setInitialValues({
          Title : "",
          Code : ""
        });

        setAccordionOpen(false);


        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteVideosRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };
      
      const handleSubmit = (values) => {
        if(editMode) {
        dispatch(updateVideosRequest(values , editId))
        .then(() => {
          // Reset initial values
          setInitialValues({
            Title : "",
            Code : ""
          });

          setAccordionOpen(false);

          dispatch(fetchVideosRequest());
        }) 
      }else{
        dispatch(addVideosRequest(values))
        .then(() => {
          // Reset initial values
          setInitialValues({
            Title : "",
            Code : ""
          });

          setAccordionOpen(false);

          dispatch(fetchVideosRequest());
        }) 
      }
      
      }
      
     
    
      const handleEdit = (row) => {

        setEditId(row.id)
        setEditMode(true);
        setInitialValues({
          Title: row.title,
          Code: row.code,
        });

        setAccordionOpen(true);

      };
    
      const handleView = (row) => {
        console.log('View row:', row);
      };

      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };
      
      const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

      const columns = videos && videos.length > 0 ? Object.keys(videos[0]).map((key) => {

        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnsToRemove = ['Id'];
        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
                <i onClick={() => handleEdit(row)} className='ti ti-pencil text-info fs-6 px-2'></i>
                {/* <Link to={`/addAbout/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-2'></i></Link> */}
              </>
            ),
            sortable: false,
          };
          

          const modifiedColumns = [actionsColumn ,...filteredColumns];

  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>Videos</h3>
                    <div className='d-flex align-items-center'>
                    {selectedRows.length > 0 && (
                        <button onClick={handleMulitpleDelete} className='btn btn-danger'>Delete Selected</button>
                    )}
                    <button onClick={handleAddVideos} className='btn btn-primary ms-2'> Add Video</button>
                    </div>
                </div>
                {accordionOpen && (
                  <div className='card-body pb-0'>
                      <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Title"
                            name="Title"
                            className="form-control border-0 ps-2"
                            placeholder="Title"
                          />
                        </div>
                        <ErrorMessage
                          name="Title"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    

                    <div className="col-md-6">
                      <div className="mb-4">
                       
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-brand-bilibili fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Code"
                            name="Code"
                            className="form-control"
                            placeholder="Video IFrame"
                          />
                        </div>
                        <ErrorMessage
                          name="Code"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    </div>
                  
                      <div className="action-form">
                        <div className="mb-3 mb-0 text-end">
                          <button
                            type="submit"
                            className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                          >
                            {editMode ? <text>Save</text> : <text>Add</text>}
                          </button>
                          <button
                            onClick={() => {setAccordionOpen(false)}}
                            className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>


                  
                  

                </Form>
              )}
            </Formik>
                  </div>  
                )}
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={videos}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default Videos