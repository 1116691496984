import {
  FETCH_BLOG_POST_FAILURE,
  FETCH_BLOG_POST_SUCCESS,
  FETCH_BLOG_POST_REQUEST,
  ADD_BLOG_POST_REQUEST,
  ADD_BLOG_POST_SUCCESS,
  ADD_BLOG_POST_FAILURE,
  DELETE_BLOG_POST_REQUEST,
  DELETE_BLOG_POST_SUCCESS,
  DELETE_BLOG_POST_FAILURE,
  MULTIPLE_DELETE_BLOG_POST_SUCCESS,
  DELETE_BLOG_POST_IMAGE_SUCCESS,
  UPDATE_BLOG_POST_REQUEST,
  UPDATE_BLOG_POST_SUCCESS,
  UPDATE_BLOG_POST_FAILURE,
} from "../actions/blogPostActions";

// Initial state
const initialState = {
  blogPost: [],
  loading: false,
  error: null,
};

// Reducer
const blogPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOG_POST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_BLOG_POST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case DELETE_BLOG_POST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_BLOG_POST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        blogPost: action.payload,
      };
    case ADD_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        blogPost: action.payload,
      };

    case DELETE_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        blogPost: state.blogPost.filter(
          (entry) => entry.id !== action.payload.deletedId
        ),
      };

    case MULTIPLE_DELETE_BLOG_POST_SUCCESS:
      console.log(action.payload);
      const deletedIds = action.payload;
      const updatedBlogPost = state.blogPost.filter(
        (entry) => !deletedIds.includes(entry.id)
      );
      return {
        ...state,
        loading: false,
        blogPost: updatedBlogPost,
      };

    case DELETE_BLOG_POST_IMAGE_SUCCESS:
      const imageId = action.payload;
      const updatedBlogPostImage = state.blogPost.map(entry => {
        return {
          ...entry,
          images: entry.images.filter(image => image.id !== imageId.deletedId)
        };
      });
      return {
        ...state,
        loading: false,
        blogPost: updatedBlogPostImage,
      };

    case UPDATE_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        blogPost: action.payload,
      };

    case FETCH_BLOG_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_BLOG_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BLOG_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BLOG_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default blogPostReducer;
