import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from './redux_store/actions/authActions';

const ProtectedRoute = ({ element, isAdminRequired }) => {

    const dispatch = useDispatch();

    const token = localStorage.getItem('token');
  
    const storedIsAdmin = localStorage.getItem('isAdmin') === "true";

    const isAdmin = useSelector((state) => state.auth.isAdmin);

    console.log(isAdminRequired, storedIsAdmin, isAdmin)

  if (token !== null && storedIsAdmin) {
    // Reset login success action
    dispatch(loginSuccess(null));

    if (isAdminRequired && isAdmin) {
      // Render the protected component
      return element;
    } else if (!isAdminRequired) {
      return element;
    } else {
        console.log("User is not admin")
      // Redirect to login if admin access is required but the user is not an admin
      return <Navigate to="/login" />;
    }
  } else {
      // Redirect to login if the user is not authenticated
      console.log("User is not authenticated")

    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
