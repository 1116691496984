import {
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_REQUEST,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  MULTIPLE_DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_IMAGE_SUCCESS,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_BANNER_IMAGE_SUCCESS,
} from "../actions/productActions";

// Initial state
const initialState = {
  product: [],
  loading: false,
  error: null,
};

// Reducer
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: state.product.filter(
          (entry) => entry.id !== action.payload.deletedId
        ),
      };

    case MULTIPLE_DELETE_PRODUCT_SUCCESS:
      console.log(action.payload);
      const deletedIds = action.payload;
      const updatedProduct = state.product.filter(
        (entry) => !deletedIds.includes(entry.id)
      );
      return {
        ...state,
        loading: false,
        product: updatedProduct,
      };

    case DELETE_PRODUCT_IMAGE_SUCCESS:
      const imageId = action.payload;
      const updatedProductImage = state.product.map(entry => {
        return {
          ...entry,
          images: entry.images.filter(image => image.id !== imageId.deletedId)
        };
      });
      return {
        ...state,
        loading: false,
        product: updatedProductImage,
      };

      case DELETE_PRODUCT_BANNER_IMAGE_SUCCESS:
      const bannerImageId = action.payload;
      const updatedProductBannerImage = state.product.map(entry => {
        return {
          ...entry,
          bannerImages: entry.bannerImages.filter(image => image.id !== bannerImageId.deletedId)
        };
      });
      return {
        ...state,
        loading: false,
        product: updatedProductBannerImage,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
      };

    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;
