import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-tagsinput/react-tagsinput.css";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import { event } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { addCustomPagesRequest } from "../../redux_store/actions/customPagesActions";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCustomPagesRequest, updateCustomPagesRequest } from "../../redux_store/actions/customPagesActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";

const AddCustomPages = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { customPages, loading, error } = useSelector((state) => state.customPages);

  const [initialValues, setInitialValues] = useState({
    Name: "",
    Slug: "",
    Order: "",
    Content: "",
  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is Required"),
    Slug: Yup.string().required("Slug is Required"),
    Order: Yup.number()
    .integer()
    .nullable()
    .min(1, "Sort Order must be greater than or equal to 1").required("Order is Required"),
    Content: Yup.string()
  });

  const handleSubmit = (values) => {
    editMode ?
    dispatch(updateCustomPagesRequest(values , id , navigate)) :
    dispatch(addCustomPagesRequest(values , navigate))
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchCustomPagesRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && customPages && customPages.length > 0) {

      const filteredCustomPages = customPages[0];
      
      setInitialValues({
        Name: filteredCustomPages.name !== null ? filteredCustomPages.name : "",
        Slug: filteredCustomPages.slug !== null ? filteredCustomPages.slug : "",
        Content: filteredCustomPages.content !== null ? filteredCustomPages.content : "",
        Order : filteredCustomPages.order !== null ?  filteredCustomPages.order :  "",
      });
    }
  }, [editMode, customPages]);



  
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{viewMode ? <text>View Custom Page</text> : !viewMode && editMode ? <text>Edit Custom Page</text> : <text>Add Custom Page</text>}</h3>
          </div>
          <div className="card-body pb-0">

              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="Name"
                            className="form-label fw-semibold"
                          >
                            Name
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-badge-cc fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Name"
                              name="Name"
                              disabled={viewMode}
                              onChange={(event) => {
                                const name = event.target.value;
                                setFieldValue("Name", name);
                                setFieldValue(
                                  "Slug",
                                  name
                                    .toLowerCase()
                                    .replace(/[^a-zA-Z0-9 ]/g, "")
                                    .replace(/ /g, "-")
                                );
                              }}
                              className="form-control border-0 ps-2"
                              placeholder="Enter Name here"
                            />
                          </div>
                          <ErrorMessage
                            name="Name"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="Slug"
                            className="form-label fw-semibold"
                          >
                            Slug
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-link fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Slug"
                              name="Slug"
                              className="form-control border-0 ps-2"
                              placeholder="Slug"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="Slug"
                            component="div"
                            className="mt-1 error"
                          />
                           <div className="mt-3 text-warning">
                            This will be used to genrate url, ex. /returnpolicy
                            Enter url frindly slug, ex. about-us, return-policy.
                            </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Order"
                          className="form-label fw-semibold"
                        >
                          Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                        <Field
                          type="number"
                          id="Order"
                          name="Order"
                          className="form-control"
                          disabled={viewMode}
                        />
                        </div>
                        <ErrorMessage
                          name="Order"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                      <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Content"
                            className="form-label fw-semibold"
                          >
                            Content
                          </label>
                          <div className=" border rounded-1">
                            <CKEditor
                              name="Content"
                              id="Content"
                              disabled={viewMode}
                              editor={ClassicEditor}
                              data={initialValues.Content || ''}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log(data)
                                setFieldValue("Content", data);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="Content"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>


                    </div>
                    { viewMode ? <div className="py-3" ></div> :
                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        <button
                          type="submit"
                          className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                        >
                          Save
                        </button>
                        <Link
                          to="/customPages"
                          className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div> }
                  </Form>
                )}
              </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomPages;
