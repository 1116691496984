import { act } from "react-dom/test-utils";
import {
  FETCH_TAXES_FAILURE,
  FETCH_TAXES_SUCCESS,
  FETCH_TAXES_REQUEST,
  ADD_TAXES_REQUEST,
  ADD_TAXES_SUCCESS,
  ADD_TAXES_FAILURE,
  DELETE_TAXES_REQUEST,
  DELETE_TAXES_SUCCESS,
  DELETE_TAXES_FAILURE,
  MULTIPLE_DELETE_TAXES_SUCCESS,
  UPDATE_TAXES_REQUEST,
  UPDATE_TAXES_SUCCESS,
  UPDATE_TAXES_FAILURE
} from "../actions/taxesActions";

// Initial state
const initialState = {
  taxes: [],
  loading: false,
  error: null,
};

// Reducer
const taxesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAXES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_TAXES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_TAXES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_TAXES_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_TAXES_SUCCESS:

      return {
        ...state,
        loading: false,
        taxes: action.payload,
      };
    case ADD_TAXES_SUCCESS:
      return {
        ...state,
        loading: false,
        taxes: action.payload,
      };
      
      case DELETE_TAXES_SUCCESS:
      return {
        ...state,
        loading: false,
        taxes: state.taxes.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_TAXES_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedTaxes= state.taxes.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          taxes: updatedTaxes,
        };

        case UPDATE_TAXES_SUCCESS:
          return {
            ...state,
            loading: false,
            taxes: action.payload,
          };

      
    case FETCH_TAXES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_TAXES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_TAXES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_TAXES_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default taxesReducer;
