import axios from 'axios';
import { LOGIN_URL } from "../../apis/url_constants";
import { LOGOUT_URL } from '../../apis/url_constants';
import { toast } from 'react-toastify';
import { GET_WEB_SETTING_URL, UPDATE_WEB_SETTING_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_WEB_SETTING_REQUEST = 'FETCH_WEB_SETTING_REQUEST';
export const FETCH_WEB_SETTING_SUCCESS = 'FETCH_WEB_SETTING_SUCCESS';
export const FETCH_WEB_SETTING_FAILURE = 'FETCH_WEB_SETTING_FAILURE';
export const UPDATE_WEB_SETTING_REQUEST = 'UPDATE_WEB_SETTING_REQUEST';
export const UPDATE_WEB_SETTING_SUCCESS = 'UPDATE_WEB_SETTING_SUCCESS';
export const UPDATE_WEB_SETTING_FAILURE = 'UPDATE_WEB_SETTING_FAILURE';


    export const fetchWebSettingRequest = () => async (dispatch) => {
        dispatch({ type: FETCH_WEB_SETTING_REQUEST });
        const token = localStorage.getItem('token');

        try {
        const response = await axios.get(GET_WEB_SETTING_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const webSetting = response.data;
            dispatch({
                type: FETCH_WEB_SETTING_SUCCESS,
                payload: webSetting,
            });
        } catch (error) {
            dispatch({
                type: FETCH_WEB_SETTING_FAILURE,
                payload: error.message,
            });
            toast.error(error.response.data)
        }
    };

  
  export const fetchWebSettingSuccess = (webSetting) => ({
    type: FETCH_WEB_SETTING_SUCCESS,
    payload: webSetting,
  });
  
  export const fetchWebSettingFailure = (error) => ({
    type: FETCH_WEB_SETTING_FAILURE,
    payload: error,
  });
  
  export const updateWebSettingRequest = (updatedWebSetting) => async (dispatch) => {
    dispatch({ type: UPDATE_WEB_SETTING_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(UPDATE_WEB_SETTING_URL, updatedWebSetting, {
        headers: {
          // Add your headers here
          Authorization: `Bearer ${token}`,
          // Other headers...
        },
      });
        dispatch({ type: UPDATE_WEB_SETTING_SUCCESS, payload: response.data });
        toast.success("Web Setting has been Updated.")
    } catch (error) {
      dispatch({
        type: UPDATE_WEB_SETTING_FAILURE,
        payload: error.message,
      });
      toast.error("Something went Wrong!, While Updating Web Setting.")
    }
  };
  
  export const updateWebSettingSuccess = () => ({
    type: UPDATE_WEB_SETTING_SUCCESS,
  });
  
  export const updateWebSettingFailure = (error) => ({
    type: UPDATE_WEB_SETTING_FAILURE,
    payload: error,
  });


  
