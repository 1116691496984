import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWebSettingRequest,
  updateWebSettingRequest,
} from "../redux_store/actions/webSettingActions";
import { ColorRing } from "react-loader-spinner";

const WebSetting = () => {
  const dispatch = useDispatch();

  const { webSetting, loading, error } = useSelector(
    (state) => state.webSetting
  );

  useEffect(() => {
    dispatch(fetchWebSettingRequest());
  }, [dispatch]);

  const initialValues = {
    Slider: true,
    AboutUs: true,
    AboutTitle: "",
    AboutSubTitle: "",
    Categories: true,
    CategoryTitle: "",
    CategorySubTitle: "",
    Products: true,
    ProductTitle: "",
    ProductSubTitle: "",
    Service: true,
    ServiceTitle: "",
    ServiceSubTitle: "",
    Team: true,
    TeamTitle: "",
    TeamSubTitle: "",
    FAQs: true,
    FAQsTitle: "",
    FAQsSubTitle: "",
    Blog: true,
    BlogTitle: "",
    BlogSubTitle: "",
    Testimonial: true,
    TestimonialTitle: "",
    TestimonialSubTitle: "",
    FooterTitle1: "",
    FooterTitle2: "",
    FooterTitle3: "",
    FooterTitle4: "",
  };

  if (webSetting) {
    initialValues.Slider = webSetting.slider;
    initialValues.AboutUs = webSetting.aboutUs;
    initialValues.AboutTitle = webSetting.aboutTitle;
    initialValues.AboutSubTitle = webSetting.aboutSubTitle;
    initialValues.Categories = webSetting.categories;
    initialValues.CategoryTitle = webSetting.categoryTitle;
    initialValues.CategorySubTitle = webSetting.categorySubTitle;
    initialValues.Products = webSetting.products;
    initialValues.ProductTitle = webSetting.productTitle;
    initialValues.ProductSubTitle = webSetting.productSubTitle;
    initialValues.Service = webSetting.service;
    initialValues.ServiceTitle = webSetting.serviceTitle;
    initialValues.ServiceSubTitle = webSetting.serviceSubTitle;
    initialValues.Team = webSetting.team;
    initialValues.TeamTitle = webSetting.teamTitle;
    initialValues.TeamSubTitle = webSetting.teamSubTitle;
    initialValues.FAQs = webSetting.fAQs;
    initialValues.FAQsTitle = webSetting.fAQsTitle;
    initialValues.FAQsSubTitle = webSetting.fAQsSubTitle;
    initialValues.Blog = webSetting.blog;
    initialValues.BlogTitle = webSetting.blogTitle;
    initialValues.BlogSubTitle = webSetting.blogSubTitle;
    initialValues.Testimonial = webSetting.testimonial;
    initialValues.TestimonialTitle = webSetting.testimonialTitle;
    initialValues.TestimonialSubTitle = webSetting.testimonialSubTitle;
    initialValues.FooterTitle1 = webSetting.footerTitle1;
    initialValues.FooterTitle2 = webSetting.footerTitle2;
    initialValues.FooterTitle3 = webSetting.footerTitle3;
    initialValues.FooterTitle4 = webSetting.footerTitle4;
  }

  const validationSchema = Yup.object().shape({
    Slider: Yup.boolean(),
    AboutUs: Yup.boolean(),
    AboutTitle: Yup.string(),
    AboutSubTitle: Yup.string(),
    Categories: Yup.boolean(),
    CategoryTitle: Yup.string(),
    CategorySubTitle: Yup.string(),
    Products: Yup.boolean(),
    ProductTitle: Yup.string(),
    ProductSubTitle: Yup.string(),
    Service: Yup.boolean(),
    ServiceTitle: Yup.string(),
    ServiceSubTitle: Yup.string(),
    Team: Yup.boolean(),
    TeamTitle: Yup.string(),
    TeamSubTitle: Yup.string(),
    FAQs: Yup.boolean(),
    FAQsTitle: Yup.string(),
    FAQsSubTitle: Yup.string(),
    Blog: Yup.boolean(),
    BlogTitle: Yup.string(),
    BlogSubTitle: Yup.string(),
    Testimonial: Yup.boolean(),
    TestimonialTitle: Yup.string(),
    TestimonialSubTitle: Yup.string(),
    FooterTitle1: Yup.string(),
    FooterTitle2: Yup.string(),
    FooterTitle3: Yup.string(),
    FooterTitle4: Yup.string(),
  });

  const handleUpdateProfile = (values) => {
    dispatch(updateWebSettingRequest(values));
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        {/* <ColorRing visible={true} height="80" width="80"/> */}
        <span>Loading...</span>
      </div>
    );
  }

  console.log(webSetting)

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h3 className="mb-4">Business Profile</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleUpdateProfile}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="row">
                    <h5>Slider</h5>
                    <hr className="mb-1" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Slider"
                          name="Slider"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="Slider"
                          className="fw-semibold form-check-label"
                        >
                          Slider
                        </label>

                        <ErrorMessage
                          name="Slider"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mt-4">About Us</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="AboutUs"
                          name="AboutUs"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="AboutUs"
                          className="fw-semibold form-check-label"
                        >
                          AboutUs
                        </label>

                        <ErrorMessage
                          name="AboutUs"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="AboutTitle"
                          placeholder="About Title"
                          className={`form-control border ${
                            errors.AboutTitle && touched.AboutTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="AboutTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.AboutTitle && touched.AboutTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            About Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="AboutTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="AboutSubTitle"
                          placeholder="About SubTitle"
                          className={`form-control border ${
                            errors.AboutSubTitle && touched.AboutSubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="AboutSubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.AboutSubTitle && touched.AboutSubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            About SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="AboutSubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mt-4">Categories</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Categories"
                          name="Categories"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="Categories"
                          className="fw-semibold form-check-label"
                        >
                          Categories
                        </label>

                        <ErrorMessage
                          name="Categories"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="CategoryTitle"
                          placeholder="Category Title"
                          className={`form-control border ${
                            errors.CategoryTitle && touched.CategoryTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="CategoryTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.CategoryTitle && touched.CategoryTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            Category Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="CategoryTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="CategorySubTitle"
                          placeholder="Category SubTitle"
                          className={`form-control border ${
                            errors.CategorySubTitle && touched.CategorySubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="CategorySubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.CategorySubTitle &&
                              touched.CategorySubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            Category SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="CategorySubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mt-4">Products</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Products"
                          name="Products"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="Products"
                          className="fw-semibold form-check-label"
                        >
                          Products
                        </label>

                        <ErrorMessage
                          name="Products"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="ProductTitle"
                          placeholder="Product Title"
                          className={`form-control border ${
                            errors.ProductTitle && touched.ProductTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="ProductTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.ProductTitle && touched.ProductTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            Product Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="ProductTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="ProductSubTitle"
                          placeholder="Product SubTitle"
                          className={`form-control border ${
                            errors.ProductSubTitle && touched.ProductSubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="ProductSubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.ProductSubTitle &&
                              touched.ProductSubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Product SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="ProductSubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mt-4">Service</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Service"
                          name="Service"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="Service"
                          className="fw-semibold form-check-label"
                        >
                          Service
                        </label>

                        <ErrorMessage
                          name="Service"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="ServiceTitle"
                          placeholder="Service Title"
                          className={`form-control border ${
                            errors.ServiceTitle && touched.ServiceTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="ServiceTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.ServiceTitle && touched.ServiceTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            Service Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="ServiceTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="ServiceSubTitle"
                          placeholder="Service SubTitle"
                          className={`form-control border ${
                            errors.ServiceSubTitle && touched.ServiceSubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="ServiceSubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.ServiceSubTitle &&
                              touched.ServiceSubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Service SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="ServiceSubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mt-4">Team</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Team"
                          name="Team"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="Team"
                          className="fw-semibold form-check-label"
                        >
                          Team
                        </label>

                        <ErrorMessage
                          name="Team"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="TeamTitle"
                          placeholder="Team Title"
                          className={`form-control border ${
                            errors.TeamTitle && touched.TeamTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="TeamTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.TeamTitle && touched.TeamTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            Team Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="TeamTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="TeamSubTitle"
                          placeholder="Team SubTitle"
                          className={`form-control border ${
                            errors.TeamSubTitle && touched.TeamSubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="TeamSubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.TeamSubTitle &&
                              touched.TeamSubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Team SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="TeamSubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>


                    <h5 className="mt-4">FAQs</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="FAQs"
                          name="FAQs"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="FAQs"
                          className="fw-semibold form-check-label"
                        >
                          FAQs
                        </label>

                        <ErrorMessage
                          name="FAQs"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="FAQsTitle"
                          placeholder="FAQ's Title"
                          className={`form-control border ${
                            errors.FAQsTitle && touched.FAQsTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="FAQsTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.FAQsTitle && touched.FAQsTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            FAQ's Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="FAQsTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="FAQsSubTitle"
                          placeholder="FAQ's SubTitle"
                          className={`form-control border ${
                            errors.FAQsSubTitle && touched.FAQsSubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="FAQsSubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.FAQsSubTitle &&
                              touched.FAQsSubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          FAQ's SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="FAQsSubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>


                            
                    <h5 className="mt-4">Blog</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Blog"
                          name="Blog"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="Blog"
                          className="fw-semibold form-check-label"
                        >
                          Blog
                        </label>

                        <ErrorMessage
                          name="Service"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="BlogTitle"
                          placeholder="Blog Title"
                          className={`form-control border ${
                            errors.BlogTitle && touched.BlogTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="BlogTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.BlogTitle && touched.BlogTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            Blog Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="BlogTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="BlogSubTitle"
                          placeholder="Blog SubTitle"
                          className={`form-control border ${
                            errors.BlogSubTitle && touched.BlogSubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="BlogSubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.BlogSubTitle &&
                              touched.BlogSubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Blog SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="BlogSubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    

                            
                    <h5 className="mt-4">Testimonial</h5>
                    <hr className="mb-3" />

                    <div className="col-md-2 mt-3">
                      <div className="form-check mr-sm-2">
                        <Field
                          type="checkbox"
                          id="Testimonial"
                          name="Testimonial"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="Testimonial"
                          className="fw-semibold form-check-label"
                        >
                          Testimonial
                        </label>

                        <ErrorMessage
                          name="Service"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="TestimonialTitle"
                          placeholder="Testimonial Title"
                          className={`form-control border ${
                            errors.TestimonialTitle && touched.TestimonialTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="TestimonialTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.TestimonialTitle && touched.TestimonialTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                            Testimonial Title
                          </span>
                        </label>
                        <ErrorMessage
                          name="TestimonialTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="TestimonialSubTitle"
                          placeholder="Testimonial SubTitle"
                          className={`form-control border ${
                            errors.TestimonialSubTitle && touched.TestimonialSubTitle
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="TestimonialSubTitle"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.TestimonialSubTitle &&
                              touched.TestimonialSubTitle
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Testimonial SubTitle
                          </span>
                        </label>
                        <ErrorMessage
                          name="TestimonialSubTitle"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mt-4">Footer</h5>
                    <hr className="mb-3" />

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="FooterTitle1"
                          placeholder="Footer Title 1"
                          className={`form-control border ${
                            errors.FooterTitle1 && touched.FooterTitle1
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="FooterTitle1"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.FooterTitle1 &&
                              touched.FooterTitle1
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Footer Title 1
                          </span>
                        </label>
                        <ErrorMessage
                          name="FooterTitle1"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="FooterTitle2"
                          placeholder="Footer Title 2"
                          className={`form-control border ${
                            errors.FooterTitle2 && touched.FooterTitle2
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="FooterTitle2"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.FooterTitle2 &&
                              touched.FooterTitle2
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Footer Title 2
                          </span>
                        </label>
                        <ErrorMessage
                          name="FooterTitle2"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="FooterTitle3"
                          placeholder="Footer Title 3"
                          className={`form-control border ${
                            errors.FooterTitle3 && touched.FooterTitle3
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="FooterTitle3"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.FooterTitle3 &&
                              touched.FooterTitle3
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Footer Title 3
                          </span>
                        </label>
                        <ErrorMessage
                          name="FooterTitle3"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <Field
                          type="text"
                          name="FooterTitle4"
                          placeholder="Footer Title 4"
                          className={`form-control border ${
                            errors.FooterTitle4 && touched.FooterTitle4
                              ? "border-danger"
                              : "border-info"
                          }`}
                          id="FooterTitle4"
                        />
                        <label>
                          <i
                            className={`ti ti-mail me-2 fs-4 ${
                              errors.FooterTitle4 &&
                              touched.FooterTitle4
                                ? "text-danger"
                                : "text-info"
                            }`}
                          ></i>
                          <span className="border-start border-info ps-3">
                          Footer Title 4
                          </span>
                        </label>
                        <ErrorMessage
                          name="FooterTitle4"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>



                  </div>

                  <div className="d-md-flex align-items-center">
                    <div className="mt-3 mt-md-0 ms-auto">
                      <button
                        type="submit"
                        className="btn btn-info font-medium rounded-pill px-4"
                      >
                        <div className="d-flex align-items-center">Save</div>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebSetting;
