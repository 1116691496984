import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_SEO_URL, UPDATE_SEO_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_SEO_REQUEST = 'FETCH_SEO_REQUEST';
export const FETCH_SEO_SUCCESS = 'FETCH_SEO_SUCCESS';
export const FETCH_SEO_FAILURE = 'FETCH_SEO_FAILURE';
export const UPDATE_SEO_REQUEST = 'UPDATE_SEO_REQUEST';
export const UPDATE_SEO_SUCCESS = 'UPDATE_SEO_SUCCESS';
export const UPDATE_SEO_FAILURE = 'UPDATE_SEO_FAILURE';


    export const fetchSeoRequest = () => async (dispatch) => {
        dispatch({ type: FETCH_SEO_REQUEST });
        const token = localStorage.getItem('token');

        try {
        const response = await axios.get(GET_SEO_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const seo = response.data;
            dispatch({
                type: FETCH_SEO_SUCCESS,
                payload: seo,
            });
        } catch (error) {
            dispatch({
                type: FETCH_SEO_FAILURE,
                payload: error.message,
            });
            toast.error(error.response.data)
        }
    };

  
  export const fetchSeoSuccess = (seo) => ({
    type: FETCH_SEO_SUCCESS,
    payload: seo,
  });
  
  export const fetchSeoFailure = (error) => ({
    type: FETCH_SEO_FAILURE,
    payload: error,
  });
  
  export const updateSeoRequest = (updatedSeo) => async (dispatch) => {
    dispatch({ type: UPDATE_SEO_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(UPDATE_SEO_URL, updatedSeo, {
        headers: {
          // Add your headers here
          Authorization: `Bearer ${token}`,
          // Other headers...
        },
      });
        dispatch({ type: UPDATE_SEO_SUCCESS, payload: response.data });
        toast.success("Seo has been Updated.")
    } catch (error) {
      dispatch({
        type: UPDATE_SEO_FAILURE,
        payload: error.message,
      });
      toast.error("Something went Wrong!, While Updating Seo.")
    }
  };
  
  export const updateSeoSuccess = () => ({
    type: UPDATE_SEO_SUCCESS,
  });
  
  export const updateSeoFailure = (error) => ({
    type: UPDATE_SEO_FAILURE,
    payload: error,
  });


  
