import { act } from "react-dom/test-utils";
import {
  FETCH_PRODUCT_REVIEW_FAILURE,
  FETCH_PRODUCT_REVIEW_SUCCESS,
  FETCH_PRODUCT_REVIEW_REQUEST,
  DELETE_PRODUCT_REVIEW_REQUEST,
  DELETE_PRODUCT_REVIEW_SUCCESS,
  DELETE_PRODUCT_REVIEW_FAILURE,
  MULTIPLE_DELETE_PRODUCT_REVIEW_SUCCESS,
} from "../actions/productReviewActions";

// Initial state
const initialState = {
  productReview: [],
  loading: false,
  error: null,
};

// Reducer
const productReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
  
      case DELETE_PRODUCT_REVIEW_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

      
    case FETCH_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        productReview: action.payload,
      };
    
      
      case DELETE_PRODUCT_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        productReview: state.productReview.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_PRODUCT_REVIEW_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedProductReview = state.productReview.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          productReview: updatedProductReview,
        };

        
      
    case FETCH_PRODUCT_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
      case DELETE_PRODUCT_REVIEW_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    
    default:
      return state;
  }
};

export default productReviewReducer;
