import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColorCombinationRequest, updateColorCombinationsRequest } from '../redux_store/actions/colorCombinationActions';
import { ColorRing } from 'react-loader-spinner';

const ColorCombination = () => {
    const dispatch = useDispatch();

    const { colorCombination, loading, error } = useSelector(
        (state) => state.colorCombination
    );

    useEffect(() => {
        dispatch(fetchColorCombinationRequest());
    }, [dispatch]);

    const initialValues = {
        HeaderBackground: '',
        HeaderText: '',
        FooterBackground: '',
        FooterText: '',
        ButtonBackground: '',
        ButtonText: '',
        HoverColor: '',
    };

    if (colorCombination) {
        initialValues.HeaderBackground = colorCombination.headerBackground;
        initialValues.HeaderText = colorCombination.headerText;
        initialValues.FooterBackground = colorCombination.footerBackground;
        initialValues.FooterText = colorCombination.footerText;
        initialValues.ButtonBackground = colorCombination.buttonBackground;
        initialValues.ButtonText = colorCombination.buttonText;
        initialValues.HoverColor = colorCombination.hoverColor;
    }


    const validationSchema = Yup.object().shape({
        HeaderBackground: Yup.string(),
        HeaderText: Yup.string(),
        FooterBackground: Yup.string(),
        FooterText: Yup.string(),
        ButtonBackground: Yup.string(),
        ButtonText: Yup.string(),
        HoverColor: Yup.string(),
    });

    const handleUpdateColor = (values) => {
        dispatch(updateColorCombinationsRequest(values));
    }

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center">
            {/* <ColorRing visible={true} height="80" width="80"/> */}
            <span>Loading...</span>
        </div>;
    }



    return (
        <>
            <div className='col-12'>
                <div className="card">
                    <div className="card-body">
                        <h3 className='mb-4'>Color Combination</h3>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleUpdateColor}>
                            {({ errors, touched }) => (
                                <Form>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-floating mb-3">
                                                <Field type="color" name='HeaderBackground' placeholder="Header Background Color" className={`form-control border ${errors.HeaderBackground && touched.HeaderBackground ? 'border-danger' : 'border-info'}`} id="HeaderBackground" />
                                                <label><i className={`ti ti-settings me-2 fs-4 ${errors.HeaderBackground && touched.HeaderBackground ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Header Background Color</span></label>
                                                <ErrorMessage name="HeaderBackground" component="div" className='mt-1 error' />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-floating mb-3">
                                                <Field type="color" name='HeaderText' placeholder="Header Text Color" className={`form-control border ${errors.HeaderText && touched.HeaderText ? 'border-danger' : 'border-info'}`} id="HeaderText" />
                                                <label><i className={`ti ti-settings me-2 fs-4 ${errors.HeaderText && touched.HeaderText ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Header Text Color</span></label>
                                                <ErrorMessage name="HeaderText" component="div" className='mt-1 error' />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-floating mb-3">
                                                <Field type="color" name='FooterBackground' placeholder="Footer Background Color" className={`form-control border ${errors.FooterBackground && touched.FooterBackground ? 'border-danger' : 'border-info'}`} id="FooterBackground" />
                                                <label><i className={`ti ti-settings me-2 fs-4 ${errors.FooterBackground && touched.FooterBackground ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Footer Background Color</span></label>
                                                <ErrorMessage name="FooterBackground" component="div" className='mt-1 error' />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-floating mb-3">
                                                <Field type="color" name='FooterText' placeholder="Footer Text Color" className={`form-control border ${errors.FooterText && touched.FooterText ? 'border-danger' : 'border-info'}`} id="FooterText" />
                                                <label><i className={`ti ti-settings me-2 fs-4 ${errors.FooterText && touched.FooterText ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Footer Text Color</span></label>
                                                <ErrorMessage name="FooterText" component="div" className='mt-1 error' />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-floating mb-3">
                                                <Field type="color" name='ButtonBackground' placeholder="Button Background Color" className={`form-control border ${errors.ButtonBackground && touched.ButtonBackground ? 'border-danger' : 'border-info'}`} id="ButtonBackground" />
                                                <label><i className={`ti ti-settings me-2 fs-4 ${errors.ButtonBackground && touched.ButtonBackground ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Button Background Color</span></label>
                                                <ErrorMessage name="ButtonBackground" component="div" className='mt-1 error' />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-floating mb-3">
                                                <Field type="color" name='ButtonText' placeholder="Button Text Color" className={`form-control border ${errors.ButtonText && touched.ButtonText ? 'border-danger' : 'border-info'}`} id="ButtonText" />
                                                <label><i className={`ti ti-settings me-2 fs-4 ${errors.ButtonText && touched.ButtonText ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Button Text Color</span></label>
                                                <ErrorMessage name="ButtonText" component="div" className='mt-1 error' />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className="form-floating mb-3">
                                                <Field type="color" name='HoverColor' placeholder="Hover Color" className={`form-control border ${errors.HoverColor && touched.HoverColor ? 'border-danger' : 'border-info'}`} id="HoverColor" />
                                                <label><i className={`ti ti-settings me-2 fs-4 ${errors.HoverColor && touched.HoverColor ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Hover Color</span></label>
                                                <ErrorMessage name="HoverColor" component="div" className='mt-1 error' />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="d-md-flex align-items-center">

                                        <div className="mt-3 mt-md-0 ms-auto">
                                            <button type="submit" className="btn btn-info font-medium rounded-pill px-4">
                                                <div className="d-flex align-items-center">
                                                    Save
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ColorCombination;
