import { act } from "react-dom/test-utils";
import {
  FETCH_ORDER_FAILURE,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_REQUEST,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
} from "../actions/orderActions";

// Initial state
const initialState = {
  order: [],
  loading: false,
  error: null,
};

// Reducer
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_ORDER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

          

    case FETCH_ORDER_SUCCESS:

      return {
        ...state,
        loading: false,
        order: action.payload,
      };
      
      case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: state.order.filter(entry => entry.id !== action.payload.deletedId),
      };

    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case DELETE_ORDER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    default:
      return state;
  }
};

export default orderReducer;
