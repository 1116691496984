import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import {
  fetchCategoryRequest
} from "../../redux_store/actions/categoryActions";
import {
  addCouponRequest,
  fetchCouponRequest,
  updateCouponRequest,
} from "../../redux_store/actions/couponActions";
import {
  fetchProductRequest
} from "../../redux_store/actions/productActions";

const AddCoupon = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { coupon, loading, error } = useSelector(
    (state) => state.coupon
  );

  const { category, cat_loading, cat_error } = useSelector(
    (state) => state.category
  );

  const { product, ploading, perror } = useSelector(
    (state) => state.product
  );

  const [initialValues, setInitialValues] = useState({
    Name: "",
    Code: "",
    CouponType: "Percent",
    Discount: "",
    MinimumAmount: "",
    ExpiryDate: "",
    MaxUses: "",
    AllowMutiple: false,
    IsActive: false,
    Products: [],
    Categories: [],
  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is Required"),
    Code: Yup.string().required("Code is Required"),
    CouponType: Yup.string().required("Coupon Type is Required").oneOf(['Percent', 'Inr'], 'Invalid Coupon Type'),
    Discount: Yup.number().required("Discount is Required").positive("Discount must be a positive number"),
    MinimumAmount: Yup.number().required("Minimum Amount is Required").positive("Minimum Amount must be a positive number"),
    ExpiryDate: Yup.date().required("Expiry Date is Required").min(new Date(), "Expiry Date must be in the future"),
    MaxUses: Yup.number().integer("Maximum Uses must be an integer").min(1, "Maximum Uses must be at least 1"),
    AllowMutiple: Yup.boolean(),
    IsActive: Yup.boolean(),
    Products: Yup.array(),
    Categories: Yup.array(),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateCouponRequest(values, id, navigate))
      : // console.log(values)
        dispatch(addCouponRequest(values, navigate));
  };

  useEffect(() => {
    // fetching categories for dropdown
    dispatch(fetchCategoryRequest());
    dispatch(fetchProductRequest());

    if (editMode) {
      dispatch(fetchCouponRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && coupon && coupon.length > 0) {
      const filteredCoupon = coupon[0];

      console.log(filteredCoupon)

      setInitialValues({
        Name:
          filteredCoupon.name !== null
            ? filteredCoupon.name
            : "",
        Code:  filteredCoupon.code !== null
        ? filteredCoupon.code
        :"",
        CouponType:  filteredCoupon.couponType !== null
        ? filteredCoupon.couponType
        :"",
        Discount: filteredCoupon.discount !== null
        ? filteredCoupon.discount
        : "",
        MinimumAmount:  filteredCoupon.minimumAmount !== null
        ? filteredCoupon.minimumAmount
        :"",
        ExpiryDate:  filteredCoupon.expiryDate !== null
        ? new Date(filteredCoupon.expiryDate).toISOString().split('T')[0]
        :"",
        MaxUses:  filteredCoupon.maxUses !== null
        ? filteredCoupon.maxUses
        :"",
        AllowMutiple:  filteredCoupon.allowMutiple !== null
        ? filteredCoupon.allowMutiple
        :false,
        IsActive:  filteredCoupon.isActive !== null
        ? filteredCoupon.isActive
        :false,
        Products:
          filteredCoupon.products !== null
            ? filteredCoupon.products.map(product => product.productId)
            : [],
        Categories:
          filteredCoupon.categories !== null
            ? filteredCoupon.categories.map(category => category.categoryId)
            : [],
      });
    }
  }, [editMode, coupon]);


  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>
              {editMode && viewMode ? (
                <span>View Navigation Menu</span>
              ) : !viewMode && editMode ? (
                <span>Edit Navigation Menu</span>
              ) : (
                <span>Add Navigation Menu</span>
              )}
            </h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Name"
                          className="form-label fw-semibold"
                        >
                          Name
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Name"
                            name="Name"
                            className="form-control border-0 ps-2"
                            placeholder="Name"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            htmlFor="Code"
                            className="form-label fw-semibold"
                          >
                            Code
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-2fa fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Code"
                              name="Code"
                              className="form-control border-0 ps-2"
                              placeholder="URL"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="Code"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div> 


                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="CouponType"
                          className="form-label fw-semibold"
                        >
                          CouponType
                        </label>
                        <Field
                          as="select"
                          id="CouponType"
                          name="CouponType"
                          className="form-select"
                          disabled={viewMode}
                        >
                          <option value="Percent">Percent</option>
                          <option value="Inr">Inr</option>
                        </Field>
                        <ErrorMessage
                          name="CouponType"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

             
                      <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="Discount"
                          className="form-label fw-semibold"
                        >
                          Discount
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-discount fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="Discount"
                            name="Discount"
                            className="form-control border-0 ps-2"
                            placeholder="Discount"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Discount"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="MaxUses"
                          className="form-label fw-semibold"
                        >
                          Maximum Uses
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-maximize fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="MaxUses"
                            name="MaxUses"
                            className="form-control border-0 ps-2"
                            placeholder="MaxUses"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="MaxUses"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="MinimumAmount"
                          className="form-label fw-semibold"
                        >
                          Minimum Amount
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-calculator fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="MinimumAmount"
                            name="MinimumAmount"
                            className="form-control border-0 ps-2"
                            placeholder="MinimumAmount"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="MinimumAmount"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Products"
                          className="form-label fw-semibold"
                        >
                          Products
                        </label>
                        <Field name="Products">
                          {({ field, form }) => (
                            <Select
                              id="Products"
                              name="Products"
                              options={product.map((product) => ({
                                value: product.id,
                                label: product.name,
                              }))}
                              isMulti
                              isDisabled={viewMode}
                              value={product
                                .map((product) => ({
                                  value: product.id,
                                  label: product.name,
                                }))
                                .filter((option) =>
                                  field.value.includes(option.value)
                                )}
                              onChange={(selectedOptions) => {
                                form.setFieldValue(
                                  "Products",
                                  selectedOptions
                                    ? selectedOptions.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="Products"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Categories"
                          className="form-label fw-semibold"
                        >
                          Categories
                        </label>
                        <Field name="Categories">
                          {({ field, form }) => (
                            <Select
                              id="Categories"
                              name="Categories"
                              options={category.map((category) => ({
                                value: category.id,
                                label: category.name,
                              }))}
                              isMulti
                              isDisabled={viewMode}
                              value={category
                                .map((category) => ({
                                  value: category.id,
                                  label: category.name,
                                }))
                                .filter((option) =>
                                  field.value.includes(option.value)
                                )}
                              onChange={(selectedOptions) => {
                                form.setFieldValue(
                                  "Categories",
                                  selectedOptions
                                    ? selectedOptions.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="Categories"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
           

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="ExpiryDate"
                          className="form-label fw-semibold"
                        >
                          Expiry Date
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-calendar fs-6"></i>
                          </span>
                          <Field
                            type="date"
                            id="ExpiryDate"
                            name="ExpiryDate"
                            className="form-control border-0 ps-2"
                            placeholder="ExpiryDate"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="ExpiryDate"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

       



                    <div className="col-md-3 mt-4">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="AllowMutiple"
                            name="AllowMutiple"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="AllowMutiple"
                            className="fw-semibold form-check-label"
                          >
                            Allow Multiple
                          </label>
                        </div>
                        <ErrorMessage
                          name="AllowMutiple"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3 mt-4">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="IsActive"
                            name="IsActive"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="IsActive"
                            className="fw-semibold form-check-label"
                          >
                            IsActive
                          </label>
                        </div>
                        <ErrorMessage
                          name="IsActive"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>


                  </div>

                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        {!viewMode && (
                          <button
                            type="submit"
                            className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                          >
                            Save
                          </button>
                        )}
                        <Link
                          to="/coupon"
                          className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCoupon;
