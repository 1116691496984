import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ENQUIRY_URL , DELETE_ENQUIRY_URL, MULTIPLE_ENQUIRY_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_ENQUIRY_REQUEST = 'FETCH_ENQUIRY_REQUEST';
export const FETCH_ENQUIRY_SUCCESS = 'FETCH_ENQUIRY_SUCCESS';
export const FETCH_ENQUIRY_FAILURE = 'FETCH_ENQUIRY_FAILURE';

export const DELETE_ENQUIRY_REQUEST = 'DELETE_ENQUIRY_REQUEST';
export const DELETE_ENQUIRY_SUCCESS = 'DELETE_ENQUIRY_SUCCESS';
export const DELETE_ENQUIRY_FAILURE = 'DELETE_ENQUIRY_FAILURE';

export const MULTIPLE_DELETE_ENQUIRY_SUCCESS = 'MULTIPLE_DELETE_ENQUIRY_SUCCESS';


export const fetchEnquiryRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ENQUIRY_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_ENQUIRY_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const enquiry = response.data;
      dispatch({
        type: FETCH_ENQUIRY_SUCCESS,
        payload: [enquiry],
      });
    } else {
      const enquiryList = response.data;
      dispatch({
        type: FETCH_ENQUIRY_SUCCESS,
        payload: enquiryList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ENQUIRY_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchEnquirySuccess = (enquiry) => ({
    type: FETCH_ENQUIRY_SUCCESS,
    payload: enquiry,
  });
  
  export const fetchEnquiryFailure = (error) => ({
    type: FETCH_ENQUIRY_FAILURE,
    payload: error,
  });
  

  export const deleteEnquiryRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_ENQUIRY_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_ENQUIRY_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_ENQUIRY_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_ENQUIRY_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Enquiry');
    }
  };

  export const deleteEnquirySuccess = () => ({
    type: DELETE_ENQUIRY_SUCCESS,
  });
  
  export const deleteEnquiryFailure = (error) => ({
    type: DELETE_ENQUIRY_FAILURE,
    payload: error,
  });


  export const multipleDeleteEnquiryRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_ENQUIRY_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_ENQUIRY_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_ENQUIRY_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_ENQUIRY_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };

  
  
