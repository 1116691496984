import {React, useEffect} from 'react';
import defaultLogo from '../assets/images/logos/ftu-logo-bg.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { login } from '../redux_store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../apis/url_constants';
import { fetchLogoRequest } from '../redux_store/actions/logoActions';

const Login = () => {

  const dispatch = useDispatch();


  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const navigate = useNavigate();

  const handleSubmit = (values) => {
    dispatch(login(values , navigate));
  };
  
  const { logo } = useSelector((state) => state.logo);

  useEffect(() => {
    dispatch(fetchLogoRequest());
  }, [dispatch]);


  
  return (
    <>
     <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    <div
      className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="row justify-content-center w-100">
          <div className="col-md-8 col-lg-6 col-xxl-3">
            <div className="card mb-0">
              <div className="card-body">
                <div className="text-nowrap logo-img text-center d-block py-3 pb-4 w-100">
                  <img src={logo?.headerLogo? `${BASE_URL}${logo.headerLogo}` : defaultLogo} width="75%" alt="" />
                </div>
                <p className="text-center">Your <b>E-Store</b> Dashboard</p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                  <div className="mb-3">
                    <label htmlFor="UserName" className="form-label">Username</label>
                    <Field type="text" name='username' className="form-control" id="UserName" />
                    <ErrorMessage name="username" component="div" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Password" className="form-label">Password</label>
                    <Field type="password" name='password' className="form-control" id="Password" />
                    <ErrorMessage name="password" component="div" />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                  </div>
                  <button type='submit' className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2">Sign In</button>
                </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default Login