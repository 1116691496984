import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_TESTIMONIAL_URL , ADD_TESTIMONIAL_URL, DELETE_TESTIMONIAL_URL, UPDATE_TESTIMONIAL_URL, MULTIPLE_TESTIMONIAL_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_TESTIMONIAL_REQUEST = 'FETCH_TESTIMONIAL_REQUEST';
export const FETCH_TESTIMONIAL_SUCCESS = 'FETCH_TESTIMONIAL_SUCCESS';
export const FETCH_TESTIMONIAL_FAILURE = 'FETCH_TESTIMONIAL_FAILURE';

export const ADD_TESTIMONIAL_REQUEST = 'ADD_TESTIMONIAL_REQUEST';
export const ADD_TESTIMONIAL_SUCCESS = 'ADD_TESTIMONIAL_SUCCESS';
export const ADD_TESTIMONIAL_FAILURE = 'ADD_TESTIMONIAL_FAILURE';

export const DELETE_TESTIMONIAL_REQUEST = 'DELETE_TESTIMONIAL_REQUEST';
export const DELETE_TESTIMONIAL_SUCCESS = 'DELETE_TESTIMONIAL_SUCCESS';
export const DELETE_TESTIMONIAL_FAILURE = 'DELETE_TESTIMONIAL_FAILURE';

export const MULTIPLE_DELETE_TESTIMONIAL_SUCCESS = 'MULTIPLE_DELETE_TESTIMONIAL_SUCCESS';

export const UPDATE_TESTIMONIAL_REQUEST = 'UPDATE_TESTIMONIAL_REQUEST';
export const UPDATE_TESTIMONIAL_SUCCESS = 'UPDATE_TESTIMONIAL_SUCCESS';
export const UPDATE_TESTIMONIAL_FAILURE = 'UPDATE_TESTIMONIAL_FAILURE';



export const fetchTestimonialRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_TESTIMONIAL_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_TESTIMONIAL_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const testimonial = response.data;
      dispatch({
        type: FETCH_TESTIMONIAL_SUCCESS,
        payload: [testimonial],
      });
    } else {
      const testimonialList = response.data;
      dispatch({
        type: FETCH_TESTIMONIAL_SUCCESS,
        payload: testimonialList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_TESTIMONIAL_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchTestimonialSuccess = (testimonial) => ({
    type: FETCH_TESTIMONIAL_SUCCESS,
    payload: testimonial,
  });
  
  export const fetchTestimonialFailure = (error) => ({
    type: FETCH_TESTIMONIAL_FAILURE,
    payload: error,
  });
  
  export const addTestimonialRequest = (testimonial, navigate) => async (dispatch) => {
    dispatch({ type: ADD_TESTIMONIAL_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', testimonial.Name);
        formData.append('Review', testimonial.Review);
        formData.append('Order', testimonial.Order);
        formData.append('Image', testimonial.Image);        


      const response = await axios.post(ADD_TESTIMONIAL_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_TESTIMONIAL_SUCCESS, payload: [response.data] });
      navigate('/testimonial'); 

  } catch (error) {
    dispatch({
      type: ADD_TESTIMONIAL_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Testimonial.")
  }
  };
  
  export const addTestimonialSuccess = () => ({
    type: ADD_TESTIMONIAL_SUCCESS,
  });
  
  export const addTestimonialFailure = (error) => ({
    type: ADD_TESTIMONIAL_FAILURE,
    payload: error,
  });


  export const deleteTestimonialRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_TESTIMONIAL_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_TESTIMONIAL_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_TESTIMONIAL_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_TESTIMONIAL_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Testimonial');
    }
  };

  export const deleteTestimonialSuccess = () => ({
    type: DELETE_TESTIMONIAL_SUCCESS,
  });
  
  export const deleteTestimonialFailure = (error) => ({
    type: DELETE_TESTIMONIAL_FAILURE,
    payload: error,
  });


  export const multipleDeleteTestimonialRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_TESTIMONIAL_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_TESTIMONIAL_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_TESTIMONIAL_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_TESTIMONIAL_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateTestimonialRequest = (testimonial, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_TESTIMONIAL_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', testimonial.Name);
        formData.append('Review', testimonial.Review);
        formData.append('Order', testimonial.Order);
        formData.append('Image', testimonial.Image);

      const response = await axios.put(`${UPDATE_TESTIMONIAL_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  

      dispatch({ type: UPDATE_TESTIMONIAL_SUCCESS, payload: [response.data] });
      navigate('/testimonial'); 

    } catch (error) {
      dispatch({
        type: UPDATE_TESTIMONIAL_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Testimonial');
    }
  };
  

  
  
