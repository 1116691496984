import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ORDER_URL , DELETE_ORDER_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export const fetchOrderRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ORDER_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_ORDER_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
        const order = response.data;
      dispatch({
        type: FETCH_ORDER_SUCCESS,
        payload: [order],
      });
    } else {
      const orderList = response.data;
      dispatch({
        type: FETCH_ORDER_SUCCESS,
        payload: orderList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ORDER_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchOrderSuccess = (order) => ({
    type: FETCH_ORDER_SUCCESS,
    payload: order,
  });
  
  export const fetchOrderFailure = (error) => ({
    type: FETCH_ORDER_FAILURE,
    payload: error,
  });
  

  export const deleteOrderRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_ORDER_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_ORDER_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_ORDER_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_ORDER_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Order');
    }
  };

  export const deleteOrderSuccess = () => ({
    type: DELETE_ORDER_SUCCESS,
  });
  
  export const deleteOrderFailure = (error) => ({
    type: DELETE_ORDER_FAILURE,
    payload: error,
  });

  
  
