import axios from 'axios';
import { LOGIN_URL } from "../../apis/url_constants";
import { LOGOUT_URL } from '../../apis/url_constants';
import { toast } from 'react-toastify';
import { GET_LOGO_URL, ADD_LOGO_URL, DELETE_LOGO__URL } from '../../apis/url_constants';

// Action Types
export const FETCH_LOGO_REQUEST = 'FETCH_LOGO_REQUEST';
export const FETCH_LOGO_SUCCESS = 'FETCH_LOGO_SUCCESS';
export const FETCH_LOGO_FAILURE = 'FETCH_LOGO_FAILURE';
export const UPDATE_LOGO_REQUEST = 'UPDATE_LOGO_REQUEST';
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS';
export const UPDATE_LOGO_FAILURE = 'UPDATE_LOGO_FAILURE';
export const DELETE_LOGO_REQUEST = 'DELETE_LOGO_REQUEST';
export const DELETE_LOGO_SUCCESS = 'DELETE_LOGO_SUCCESS';
export const DELETE_LOGO_FAILURE = 'DELETE_LOGO_FAILURE';


    export const fetchLogoRequest = () => async (dispatch) => {
        dispatch({ type: FETCH_LOGO_REQUEST });

        try {
        const response = await axios.get(GET_LOGO_URL);
        const logo = response.data;

            dispatch({
                type: FETCH_LOGO_SUCCESS,
                payload: logo,
            });
        } catch (error) {
            dispatch({
                type: FETCH_LOGO_FAILURE,
                payload: error.message,
            });
            toast.error(error.response.data)
        }
    };

  
  export const fetchLogoSuccess = (logo) => ({
    type: FETCH_LOGO_SUCCESS,
    payload: logo,
  });
  
  export const fetchLogoFailure = (error) => ({
    type: FETCH_LOGO_FAILURE,
    payload: error,
  });
  
  export const updateLogoRequest = (updatedLogo) => async (dispatch) => {
    dispatch({ type: UPDATE_LOGO_REQUEST });
    const token = localStorage.getItem('token');

    try {

      const formData = new FormData();
        formData.append('HeaderLogo', updatedLogo.HeaderLogo);
        formData.append('FooterLogo', updatedLogo.FooterLogo);
        formData.append('Favicon', updatedLogo.Favicon);

      const response = await axios.post(ADD_LOGO_URL, formData, {
        headers: {
          // Add your headers here
          Authorization: `Bearer ${token}`,
          // Other headers...
        },
      });
        dispatch({ type: UPDATE_LOGO_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: UPDATE_LOGO_FAILURE,
        payload: error.message,
      });
      toast.error("Something went Wrong!, While Updating Logo.")
    }
  };
  
  export const updateLogoSuccess = () => ({
    type: UPDATE_LOGO_SUCCESS,
  });
  
  export const updateLogoFailure = (error) => ({
    type: UPDATE_LOGO_FAILURE,
    payload: error,
  });


  export const deleteLogoRequest = () => async (dispatch) => {
    dispatch({ type: DELETE_LOGO_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.delete(`${DELETE_LOGO__URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_LOGO_SUCCESS, payload: null});
    } catch (error) {
      dispatch({
        type: DELETE_LOGO_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to Reset Logo');
    }
  };

  export const deleteLogoSuccess = () => ({
    type: DELETE_LOGO_SUCCESS,
  });
  
  export const deleteLogoFailure = (error) => ({
    type: DELETE_LOGO_FAILURE,
    payload: error,
  });

  
