// Assuming this code is in a file named "apiUrls.js" or similar

const isLocal = process.env.NODE_ENV === 'development';

export const BASE_URL = isLocal
  ? process.env.REACT_APP_BASE_URL_LOCAL
  : process.env.REACT_APP_BASE_URL_SERVER;


// User Authentication URL's
export const LOGIN_URL = BASE_URL + "/api/Auth/Login";
export const LOGOUT_URL = BASE_URL + "/api/Auth/EndSession";
export const GETUSER_URL = BASE_URL + "/api/Auth/GetUser";

// Dashboard Count's
export const DASHBOARD_URL = BASE_URL + "/api/Home/GetCount";

//  Business Profile URL's 
export const GETBUSINESSPROFILE_URL = BASE_URL + "/api/BusinessProfile/GetBusinessProfile";
export const UPDATEBUSINESSPROFILE_URL = BASE_URL + "/api/BusinessProfile/UpdateBusinessProfile";

//  Business Profile URL's 
export const GETCOLORCOMBINATION_URL = BASE_URL + "/api/ColorCombinations/GetColorCombination";
export const UPDATECOLORCOMBINATION_URL = BASE_URL + "/api/ColorCombinations/UpdateColorCombination";

// About URL's
export const GETABOUTUS_URL = BASE_URL + "/api/AboutUs/GetAboutUs";
export const ADDABOUTUS_URL = BASE_URL + "/api/AboutUs/PostAboutUs";
export const DELETEABOUTUS_URL = BASE_URL + "/api/AboutUs/DeleteAboutUs";
export const UPDATEABOUTUS_URL = BASE_URL + "/api/AboutUs/PutAboutUs";
export const MULTIPLE_DELETE_URL = BASE_URL + "/api/AboutUs/DeleteMultipleAboutUs";

// Product Category URL's
export const GET_CATEGORY_URL = BASE_URL + "/api/Categories/GetCategory";
export const ADD_CATEGORY_URL = BASE_URL + "/api/Categories/PostCategory";
export const DELETE_CATEGORY__URL = BASE_URL + "/api/Categories/DeleteCategory";
export const UPDATE_CATEGORY__URL = BASE_URL + "/api/Categories/PutCategory";
export const MULTIPLE_CATEGORY_DELETE_URL = BASE_URL + "/api/Categories/DeleteMultipleCategories";

// Product SubCategory URL's
export const GET_SUBCATEGORY_URL = BASE_URL + "/api/SubCategories/GetSubCategory";
export const ADD_SUBCATEGORY_URL = BASE_URL + "/api/SubCategories/PostSubCategory";
export const DELETE_SUBCATEGORY__URL = BASE_URL + "/api/SubCategories/DeleteSubCategory";
export const UPDATE_SUBCATEGORY__URL = BASE_URL + "/api/SubCategories/PutSubCategory";
export const MULTIPLE_SUBCATEGORY_DELETE_URL = BASE_URL + "/api/SubCategories/DeleteMultipleSubCategories";

// Taxes URL's
export const GET_TAXES_URL = BASE_URL + "/api/Taxes/GetTax";
export const ADD_TAXES_URL = BASE_URL + "/api/Taxes/PostTax";
export const DELETE_TAXES_URL = BASE_URL + "/api/Taxes/DeleteTax";
export const UPDATE_TAXES_URL = BASE_URL + "/api/Taxes/PutTax";
export const MULTIPLE_TAXES_DELETE_URL = BASE_URL + "/api/Taxes/DeleteMultipleTaxes";

// Navigation Menu URL's
export const GET_NAVIGATION_MENU_URL = BASE_URL + "/api/Menus/GetMenu";
export const ADD_NAVIGATION_MENU_URL = BASE_URL + "/api/Menus/PostMenu";
export const DELETE_NAVIGATION_MENU__URL = BASE_URL + "/api/Menus/DeleteMenu";
export const UPDATE_NAVIGATION_MENU__URL = BASE_URL + "/api/Menus/PutMenu";
export const MULTIPLE_NAVIGATION_MENU_DELETE_URL = BASE_URL + "/api/Menus/DeleteMultipleMenus";
export const RESET_NAVIGATION_MENU_URL = BASE_URL + "/api/Menus/ResetMenu";

// Team URL's
export const GET_TEAM_URL = BASE_URL + "/api/Teams/GetTeam";
export const ADD_TEAM_URL = BASE_URL + "/api/Teams/PostTeam";
export const DELETE_TEAM__URL = BASE_URL + "/api/Teams/DeleteTeam";
export const UPDATE_TEAM__URL = BASE_URL + "/api/Teams/PutTeam";
export const MULTIPLE_TEAM_DELETE_URL = BASE_URL + "/api/Teams/DeleteMultipleTeams";

// Logo URL's
export const GET_LOGO_URL = BASE_URL + "/api/Logoes/GetLogos";
export const ADD_LOGO_URL = BASE_URL + "/api/Logoes/PostLogo";
export const DELETE_LOGO__URL = BASE_URL + "/api/Logoes/DeleteLogo";

//  Gallery Image's
export const GET_GALLERY_IMAGE_URL = BASE_URL + "/api/Images/GetImages";
export const ADD_GALLERY_IMAGE_URL = BASE_URL + "/api/Images/PostImage";
export const DELETE_GALLERY_IMAGE_URL = BASE_URL + "/api/Images/DeleteImage";
export const UPDATE_GALLERY_IMAGE_URL = BASE_URL + "/api/Images/PutImage";
export const MULTIPLE_GALLERY_IMAGE_DELETE_URL = BASE_URL + "/api/Images/DeleteMultipleGalleryImages";

//  Slider Image's
export const GET_SLIDER_BANNER_URL = BASE_URL + "/api/SliderImages/GetSliderImages";
export const ADD_SLIDER_BANNER_URL = BASE_URL + "/api/SliderImages/PostSliderImage";
export const DELETE_SLIDER_BANNER_URL = BASE_URL + "/api/SliderImages/DeleteSliderImage";
export const UPDATE_SLIDER_BANNER_URL = BASE_URL + "/api/SliderImages/PutSliderImage";
export const MULTIPLE_SLIDER_BANNER_DELETE_URL = BASE_URL + "/api/SliderImages/DeleteMultipleSliderImages";

// Product Category URL's
export const GET_TESTIMONIAL_URL = BASE_URL + "/api/Testimonials/GetTestimonials";
export const ADD_TESTIMONIAL_URL = BASE_URL + "/api/Testimonials/PostTestimonial";
export const DELETE_TESTIMONIAL_URL = BASE_URL + "/api/Testimonials/DeleteTestimonial";
export const UPDATE_TESTIMONIAL_URL = BASE_URL + "/api/Testimonials/PutTestimonial";
export const MULTIPLE_TESTIMONIAL_DELETE_URL = BASE_URL + "/api/Testimonials/DeleteMultipleTestimonials";

// Enquiry URL's
export const GET_ENQUIRY_URL = BASE_URL + "/api/ContactMessages/GetContactMessages";
export const DELETE_ENQUIRY_URL = BASE_URL + "/api/ContactMessages/DeleteContactMessage";
export const MULTIPLE_ENQUIRY_DELETE_URL = BASE_URL + "/api/ContactMessages/DeleteMultipleEnquiries";

// Product Review's URL's
export const GET_PRODUCT_REVIEW_URL = BASE_URL + "/api/Reviews/GetReviews";
export const DELETE_PRODUCT_REVIEW_URL = BASE_URL + "/api/Reviews/DeleteReview";
export const MULTIPLE_PRODUCT_REVIEW_DELETE_URL = BASE_URL + "/api/Reviews/DeleteMultipleReviews";

// Subscriber's URL's
export const GET_SUBSCRIBER_URL = BASE_URL + "/api/NewsSubscribers/GetNewsSubscribers";
export const DELETE_SUBSCRIBER_URL = BASE_URL + "/api/NewsSubscribers/DeleteNewsSubscriber";
export const MULTIPLE_SUBSCRIBER_DELETE_URL = BASE_URL + "/api/NewsSubscribers/DeleteMultipleSubscribers";

// Blog Post URL's
export const GET_BLOG_POST_URL = BASE_URL + "/api/Posts/GetPosts";
export const ADD_BLOG_POST_URL = BASE_URL + "/api/Posts/PostPost";
export const DELETE_BLOG_POST_URL = BASE_URL + "/api/Posts/DeletePost";
export const UPDATE_BLOG_POST_URL = BASE_URL + "/api/Posts/PutPost";
export const MULTIPLE_BLOG_POST_DELETE_URL = BASE_URL + "/api/Posts/DeleteMultiplePosts";
export const DELETE_BLOG_POST_IMAGE_URL = BASE_URL + "/api/Posts/DeletePostImage";

// About URL's
export const GET_CUSTOM_PAGES_URL = BASE_URL + "/api/Pages/GetPages";
export const ADD_CUSTOM_PAGES_URL = BASE_URL + "/api/Pages/PostPage";
export const DELETE_CUSTOM_PAGES_URL = BASE_URL + "/api/Pages/DeletePage";
export const UPDATE_CUSTOM_PAGES_URL = BASE_URL + "/api/Pages/PutPage";
export const MULTIPLE_CUSTOM_PAGES_DELETE_URL = BASE_URL + "/api/Pages/DeleteMultiplePages";

// Video URL's
export const GET_VIDEO_URL = BASE_URL + "/api/Videos/GetVideos";
export const ADD_VIDEO_URL = BASE_URL + "/api/Videos/PostVideo";
export const DELETE_VIDEO_URL = BASE_URL + "/api/Videos/DeleteVideo";
export const UPDATE_VIDEO_URL = BASE_URL + "/api/Videos/PutVideo";
export const MULTIPLE_VIDEO_DELETE_URL = BASE_URL + "/api/Videos/DeleteMultipleVideos";

// faq's URL's
export const GET_FAQ_URL = BASE_URL + "/api/FAQs/GetFAQs";
export const ADD_FAQ_URL = BASE_URL + "/api/FAQs/PostFAQ";
export const DELETE_FAQ_URL = BASE_URL + "/api/FAQs/DeleteFAQ";
export const UPDATE_FAQ_URL = BASE_URL + "/api/FAQs/PutFAQ";
export const MULTIPLE_FAQ_DELETE_URL = BASE_URL + "/api/FAQs/DeleteMultipleFAQs";

// Product URL's
export const GET_PRODUCT_URL = BASE_URL + "/api/Products/GetProducts";
export const ADD_PRODUCT_URL = BASE_URL + "/api/Products/PostProduct";
export const DELETE_PRODUCT_URL = BASE_URL + "/api/Products/DeleteProduct";
export const UPDATE_PRODUCT_URL = BASE_URL + "/api/Products/PutProduct";
export const MULTIPLE_PRODUCT_DELETE_URL = BASE_URL + "/api/Products/DeleteMultipleProducts";
export const DELETE_PRODUCT_IMAGE_URL = BASE_URL + "/api/Products/DeleteProductImages";
export const DELETE_PRODUCT_BANNER_IMAGE_URL = BASE_URL + "/api/Products/DeleteBannerImages";

// Product Variant's URL
export const GET_PRODUCT_VARIANT_URL = BASE_URL + "/api/Variants/GetVariants";
export const GENERATE_PRODUCT_VARIANT_URL = BASE_URL + "/api/Variants/GenerateVariant";
export const DELETE_PRODUCT_VARIANT_URL = BASE_URL + "/api/Variants/DeleteProductVariant";
export const DELETE_ALL_PRODUCT_VARIANT_URL = BASE_URL + "/api/Variants/DeleteAllCategories";
export const UPDATE_PRODUCT_VARIANT_URL = BASE_URL + "/api/Variants/PutVariant";


// Coupons URL
export const GET_COUPON_URL = BASE_URL + "/api/Coupons/GetCoupons";
export const ADD_COUPON_URL = BASE_URL + "/api/Coupons/PostCoupon";
export const DELETE_COUPON_URL = BASE_URL + "/api/Coupons/DeleteCoupon";
export const UPDATE_COUPON_URL = BASE_URL + "/api/Coupons/PutCoupon";
export const MULTIPLE_COUPON_DELETE_URL = BASE_URL + "/api/Coupons/DeleteMultipleCoupons";

// Deals URL's
export const GET_DEAL_URL = BASE_URL + "/api/Deals/GetDeals";
export const ADD_DEAL_URL = BASE_URL + "/api/Deals/PostDeal";
export const DELETE_DEAL_URL = BASE_URL + "/api/Deals/DeleteDeal";
export const UPDATE_DEAL_URL = BASE_URL + "/api/Deals/PutDeal";
export const MULTIPLE_DEAL_DELETE_URL = BASE_URL + "/api/Deals/DeleteMultipleDeals";

// Order URL's
export const GET_ORDER_URL = BASE_URL + "/api/Orders/GetOrders";

export const DELETE_ORDER_URL = BASE_URL + "/api/Orders/DeleteOrder";
export const CHANGE_ORDER_STATUS_URL = BASE_URL + "/api/Orders/StatusChange";
export const GENERATE_INVOICE_URL = BASE_URL + "/api/Orders/GenerateInvoice";

// Abandoned Cart URL's
export const GET_ABANDONED_CART_URL = BASE_URL + "/api/AbandonedCart/GetAbandonedCart";

// Abandoned Wishlist URL's
export const GET_ABANDONED_WISHLIST_URL = BASE_URL + "/api/AbandonedWishlist/GetAbandonedWishlist";

// SiteMap URL's
export const GET_SITEMAP_URL = BASE_URL + "/api/Sitemap/CreateSitemapInRootDirectory";

// Seo URL's 
export const GET_SEO_URL = BASE_URL + "/api/Seo/GetSeo";
export const UPDATE_SEO_URL = BASE_URL + "/api/Seo/UpdateSeo";

//  Web SettiingURL's 
export const GET_WEB_SETTING_URL = BASE_URL + "/api/WebSettings/GetWebSetting";
export const UPDATE_WEB_SETTING_URL = BASE_URL + "/api/WebSettings/UpdateWebSetting";

// User URL's
export const GET_USERS_URL = BASE_URL + "/api/Users/GetUser";
export const ADD_USERS_URL = BASE_URL + "/api/Users/CreateUser";
export const DELETE_USERS_URL = BASE_URL + "/api/Users/DeleteUser";
export const UPDATE_USERS_URL = BASE_URL + "/api/Users/UpdateUser";
export const GET_USERS_ROLES_URL = BASE_URL + "/api/Users/GetRoles";