import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-tagsinput/react-tagsinput.css";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import { event } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { addAboutUsRequest } from "../../redux_store/actions/aboutActions";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAboutUsRequest, updateAboutUsRequest } from "../../redux_store/actions/aboutActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";

const AddAbout = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { aboutUs, loading, error } = useSelector((state) => state.about);

  const [tags, setTags] = useState([]);

  const [initialValues, setInitialValues] = useState({
    Title: "",
    Slug: "",
    Description: "",
    Keywords: "",
    Image: null,
    Video: "",
  });

  const validationSchema = Yup.object().shape({
    Title: Yup.string().required("Title is Required"),
    Slug: Yup.string().required("Slug is Required"),
    Description: Yup.string(),
    Keywords: Yup.string(),
    Image: Yup.mixed().nullable(),
    Video: Yup.string(),
  });

  const handleSubmit = (values) => {
    editMode ?
    dispatch(updateAboutUsRequest(values , id , navigate)) :
    dispatch(addAboutUsRequest(values , navigate))
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchAboutUsRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && aboutUs && aboutUs.length > 0) {

      const filteredAbout = aboutUs[0];

  
      if(filteredAbout.keywords !== null && filteredAbout.keywords !== undefined){
        const tagsArray = filteredAbout.keywords.split(',').map(tag => tag.trim());
        setTags(tagsArray);
      }else{
        setTags([])
      }
      
      setInitialValues({
        Title: filteredAbout.title !== null ? filteredAbout.title : "",
        Slug: filteredAbout.slug !== null ? filteredAbout.slug : "",
        Description: filteredAbout.description !== null ? filteredAbout.description : "",
        Keywords: filteredAbout.keywords !== null ? filteredAbout.keywords : "",
        Image: null,
        Video: filteredAbout.video!== null ? filteredAbout.video : "",
      });
    }
  }, [editMode, aboutUs]);



  
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{viewMode ? <text>View AboutUs</text> : !viewMode && editMode ? <text>Edit AboutUs</text> : <text>Add AboutUs</text>}</h3>
          </div>
          <div className="card-body pb-0">

              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Title"
                            className="form-label fw-semibold"
                          >
                            Title
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-badge-cc fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Title"
                              name="Title"
                              disabled={viewMode}
                              onChange={(event) => {
                                const title = event.target.value;
                                setFieldValue("Title", title);
                                setFieldValue(
                                  "Slug",
                                  title
                                    .toLowerCase()
                                    .replace(/[^a-zA-Z0-9 ]/g, "")
                                    .replace(/ /g, "-")
                                );
                              }}
                              className="form-control border-0 ps-2"
                              placeholder="Enter Title here"
                            />
                          </div>
                          <ErrorMessage
                            name="Title"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Slug"
                            className="form-label fw-semibold"
                          >
                            Slug
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-link fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Slug"
                              name="Slug"
                              className="form-control border-0 ps-2"
                              placeholder="Slug"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="Slug"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Description"
                            className="form-label fw-semibold"
                          >
                            Description
                          </label>
                          <div className=" border rounded-1">
                            <CKEditor
                              name="Description"
                              id="Description"
                              disabled={viewMode}
                              editor={ClassicEditor}
                              data={initialValues.Description || ''}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log(data)
                                setFieldValue("Description", data);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="Description"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Keywords"
                            className="form-label fw-semibold"
                          >
                            Keywords
                          </label>
                          <div className=" border rounded-1">
                            <TagsInput
                              value={tags}
                              onChange={(newTags) => {
                                setTags(newTags);
                                setFieldValue("Keywords", newTags.join(","));
                              }}
                              inputProps={{
                                id: "Keywords",
                                className: "form-control",
                                name: "Keywords",
                                placeholder: "Enter Keywords here",
                                disabled: viewMode,
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="Keywords"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Image"
                            className="form-label fw-semibold"
                          >
                            Image
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-photo fs-6"></i>
                            </span>
                            <input
                              type="file"
                              id="Image"
                              name="Image"
                              disabled={viewMode}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                setFieldValue("Image", file || null);
                              }}
                              className="form-control"
                            />
                          </div>
                          <ErrorMessage
                            name="Image"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>


                        {editMode && (
                          <>
                            {aboutUs && aboutUs.length > 0 && !loading && aboutUs[0].image ? (
                              <div className="py-3">
                                <img
                                  src={`${BASE_URL}${aboutUs[0].image}`}
                                  alt="Image"
                                  width="100"
                                  height="100"
                                />
                              </div>
                            ) : (
                              loading || aboutUs === undefined ? (
                                <div>Loading image...</div>
                              ) : (
                                <div>No image available</div>
                              )
                            )}
                          </>
                        )}



                      </div>

                      <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Video"
                            className="form-label fw-semibold"
                          >
                            Video Iframe
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-video fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Video"
                              name="Video"
                              className="form-control border-0 ps-2"
                              placeholder="Enter Video Iframe here"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="Video"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                    </div>
                    { viewMode ? <div className="py-3" ></div> :
                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        <button
                          type="submit"
                          className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                        >
                          Save
                        </button>
                        <Link
                          to="/about"
                          className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div> }
                  </Form>
                )}
              </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAbout;
