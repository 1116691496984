import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  fetchFAQsRequest,
  updateFAQsRequest,
  addFAQsRequest,
} from "../../redux_store/actions/faqActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";

const AddFAQs = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { faqs, loading, error } = useSelector((state) => state.faqs);

  const [initialValues, setInitialValues] = useState({
    Question: "",
    Answer: "",
    Order: "",
  });

  const validationSchema = Yup.object().shape({
    Question: Yup.string().required("Name is Required"),
    Answer: Yup.string().required("Answer is Required"),
    Order: Yup.number()
    .integer()
    .nullable()
    .min(1, "Sort Order must be greater than or equal to 1").required("Order is Required"),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateFAQsRequest(values, id, navigate))
      : 
      dispatch(addFAQsRequest(values, navigate));
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchFAQsRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && faqs && faqs.length > 0) {
      const filteredFAQs = faqs[0];


      setInitialValues({
        Question: filteredFAQs.question !== null ? filteredFAQs.question : "",
        Answer: filteredFAQs.answer !== null ? filteredFAQs.answer : "",
        Order: filteredFAQs.order || "",
      });
    }
  }, [editMode, faqs]);


  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{editMode && viewMode ? <text>View FAQs</text> : !viewMode && editMode ? <text>Edit FAQs</text> : <text>Add FAQs</text>}</h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="Name"
                          className="form-label fw-semibold"
                        >
                          Question
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-question-mark fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Question"
                            name="Question"
                            className="form-control border-0 ps-2"
                            placeholder="Question"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-4">
                          <label
                            htmlFor="Answer"
                            className="form-label fw-semibold"
                          >
                            Answer
                          </label>
                          <div className=" border rounded-1">
                            <CKEditor
                              name="Answer"
                              id="Answer"
                              disabled={viewMode}
                              editor={ClassicEditor}
                              data={initialValues.Answer || ''}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("Answer", data);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="Answer"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="Order"
                          className="form-label fw-semibold"
                        >
                          Sort Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                        <Field
                          type="number"
                          id="Order"
                          name="Order"
                          className="form-control"
                          disabled={viewMode}
                        />
                        </div>
                        <ErrorMessage
                          name="Order"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-3"></div>

                  </div>

                  
                  <div className="action-form">
                    <div className="mb-3 mb-0 text-end">
                    {!viewMode && (
                      <button
                        type="submit"
                        className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                      >
                        Save
                      </button> )}
                      <Link
                        to="/faqs"
                        className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div> 
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFAQs;
