import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ABANDONED_CART_URL} from '../../apis/url_constants';

// Action Types
export const FETCH_ABANDONED_CART_REQUEST = 'FETCH_ABANDONED_CART_REQUEST';
export const FETCH_ABANDONED_CART_SUCCESS = 'FETCH_ABANDONED_CART_SUCCESS';
export const FETCH_ABANDONED_CART_FAILURE = 'FETCH_ABANDONED_CART_FAILURE';


export const fetchAbandonedCartRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ABANDONED_CART_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_ABANDONED_CART_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const abandonedCart = response.data;
      dispatch({
        type: FETCH_ABANDONED_CART_SUCCESS,
        payload: [abandonedCart],
      });
    } else {
      const abandonedCartList = response.data;
      dispatch({
        type: FETCH_ABANDONED_CART_SUCCESS,
        payload: abandonedCartList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ABANDONED_CART_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchAbandonedCartSuccess = (abandonedCart) => ({
    type: FETCH_ABANDONED_CART_SUCCESS,
    payload: abandonedCart,
  });
  
  export const fetchAbandonedCartFailure = (error) => ({
    type: FETCH_ABANDONED_CART_FAILURE,
    payload: error,
  });

  
  
