import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_GALLERY_IMAGE_URL , ADD_GALLERY_IMAGE_URL, DELETE_GALLERY_IMAGE_URL, UPDATE_GALLERY_IMAGE_URL, MULTIPLE_GALLERY_IMAGE_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_GALLERY_IMAGE_REQUEST = 'FETCH_GALLERY_IMAGE_REQUEST';
export const FETCH_GALLERY_IMAGE_SUCCESS = 'FETCH_GALLERY_IMAGE_SUCCESS';
export const FETCH_GALLERY_IMAGE_FAILURE = 'FETCH_GALLERY_IMAGE_FAILURE';

export const ADD_GALLERY_IMAGE_REQUEST = 'ADD_GALLERY_IMAGE_REQUEST';
export const ADD_GALLERY_IMAGE_SUCCESS = 'ADD_GALLERY_IMAGE_SUCCESS';
export const ADD_GALLERY_IMAGE_FAILURE = 'ADD_GALLERY_IMAGE_FAILURE';

export const DELETE_GALLERY_IMAGE_REQUEST = 'DELETE_GALLERY_IMAGE_REQUEST';
export const DELETE_GALLERY_IMAGE_SUCCESS = 'DELETE_GALLERY_IMAGE_SUCCESS';
export const DELETE_GALLERY_IMAGE_FAILURE = 'DELETE_GALLERY_IMAGE_FAILURE';

export const MULTIPLE_DELETE_GALLERY_IMAGE_SUCCESS = 'MULTIPLE_DELETE_GALLERY_IMAGE_SUCCESS';

export const UPDATE_GALLERY_IMAGE_REQUEST = 'UPDATE_GALLERY_IMAGE_REQUEST';
export const UPDATE_GALLERY_IMAGE_SUCCESS = 'UPDATE_GALLERY_IMAGE_SUCCESS';
export const UPDATE_GALLERY_IMAGE_FAILURE = 'UPDATE_GALLERY_IMAGE_FAILURE';



export const fetchGalleryRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_GALLERY_IMAGE_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_GALLERY_IMAGE_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const gallery = response.data;
      dispatch({
        type: FETCH_GALLERY_IMAGE_SUCCESS,
        payload: [gallery],
      });
    } else {
      const galleryList = response.data;
      dispatch({
        type: FETCH_GALLERY_IMAGE_SUCCESS,
        payload: galleryList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_GALLERY_IMAGE_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchGallerySuccess = (gallery) => ({
    type: FETCH_GALLERY_IMAGE_SUCCESS,
    payload: gallery,
  });
  
  export const fetchGalleryFailure = (error) => ({
    type: FETCH_GALLERY_IMAGE_FAILURE,
    payload: error,
  });
  
  export const addGalleryRequest = (gallery) => async (dispatch) => {
    dispatch({ type: ADD_GALLERY_IMAGE_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Title', gallery.Title);
        formData.append('Slug', gallery.Slug);
        formData.append('Image', gallery.Image);   
        formData.append('Order', gallery.Order);   


      const response = await axios.post(ADD_GALLERY_IMAGE_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_GALLERY_IMAGE_SUCCESS, payload: [response.data] }); 

  } catch (error) {
    dispatch({
      type: ADD_GALLERY_IMAGE_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Gallery.")
  }
  };
  
  export const addGallerySuccess = () => ({
    type: ADD_GALLERY_IMAGE_SUCCESS,
  });
  
  export const addGalleryFailure = (error) => ({
    type: ADD_GALLERY_IMAGE_FAILURE,
    payload: error,
  });


  export const deleteGalleryRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_GALLERY_IMAGE_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_GALLERY_IMAGE_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_GALLERY_IMAGE_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_GALLERY_IMAGE_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Gallery');
    }
  };

  export const deleteGallerySuccess = () => ({
    type: DELETE_GALLERY_IMAGE_SUCCESS,
  });
  
  export const deleteGalleryFailure = (error) => ({
    type: DELETE_GALLERY_IMAGE_FAILURE,
    payload: error,
  });


  export const multipleDeleteGalleryRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_GALLERY_IMAGE_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_GALLERY_IMAGE_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_GALLERY_IMAGE_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_GALLERY_IMAGE_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateGalleryRequest = (gallery, id) => async (dispatch) => {
    dispatch({ type: UPDATE_GALLERY_IMAGE_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Title', gallery.Title);
        formData.append('Slug', gallery.Slug);
        formData.append('Image', gallery.Image);   
        formData.append('Order', gallery.Order);   


      const response = await axios.put(`${UPDATE_GALLERY_IMAGE_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: UPDATE_GALLERY_IMAGE_SUCCESS, payload: [response.data] }); 

    } catch (error) {
      dispatch({
        type: UPDATE_GALLERY_IMAGE_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Gallery');
    }
  };
  

  
  
