import axios from 'axios';
import { toast } from 'react-toastify';
import { DASHBOARD_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_COUNT_REQUEST = 'FETCH_COUNT_REQUEST';
export const FETCH_COUNT_SUCCESS = 'FETCH_COUNT_SUCCESS';
export const FETCH_COUNT_FAILURE = 'FETCH_COUNT_FAILURE';


export const fetchCountRequest = () => async (dispatch) => {
    dispatch({ type: FETCH_COUNT_REQUEST });
    const token = localStorage.getItem('token');

    try {
    const response = await axios.get(DASHBOARD_URL, {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    });
    const count = response.data;
        dispatch({
            type: FETCH_COUNT_SUCCESS,
            payload: count,
        });
    } catch (error) {
        dispatch({
            type: FETCH_COUNT_FAILURE,
            payload: error.message,
        });
        toast.error(error.response.data)
    }
};


export const fetchCountSuccess = (count) => ({
    type: FETCH_COUNT_SUCCESS,
    payload: count,
});

export const fetchCountFailure = (error) => ({
    type: FETCH_COUNT_FAILURE,
    payload: error,
});
