import {React, useEffect } from 'react'
import profileImg from '../assets/images/profile/user-1.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux_store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../apis/url_constants';
import { fetchLogoRequest } from '../redux_store/actions/logoActions';
import axios from 'axios';
import { GET_SITEMAP_URL } from '../apis/url_constants';
import { toast } from 'react-toastify';
import {fetchBusinessProfileRequest} from '../redux_store/actions/businessProfileActions';

const Navbar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout(navigate))
  }
  
  const { logo } = useSelector((state) => state.logo);
  const { businessProfile } = useSelector((state) => state.businessProfile);

  useEffect(() => {
    dispatch(fetchLogoRequest());
    dispatch(fetchBusinessProfileRequest());
  }, [dispatch]);

  const handleSitemapGeneration = async () => {
    try {
      const response = await axios.post(GET_SITEMAP_URL);
      toast.success(response.data)
      
    } catch (error) {
      // Handle error if the request fails.
      toast.error('Error generating sitemap:', error);
    }
  };

  const handleSidebarToggle  = () => {
      document.querySelector(".sidebartoggler").addEventListener("click", function () {
        var mainWrapper = document.getElementById("main-wrapper");
        mainWrapper.classList.toggle("mini-sidebar");
    
        if (mainWrapper.classList.contains("mini-sidebar")) {
            document.querySelector(".sidebartoggler").checked = true;
            mainWrapper.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
            document.querySelector(".sidebartoggler").checked = false;
            mainWrapper.setAttribute("data-sidebartype", "full");
        }
    });
    
    document.querySelector(".sidebartoggler").addEventListener("click", function () {
        document.getElementById("main-wrapper").classList.toggle("show-sidebar");
    });  
  }

  return (
    <>
     <header className="app-header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item d-block d-xl-none">
              <span className="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" onClick={handleSidebarToggle}>
                <i className="ti ti-menu-2"></i>
              </span>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link nav-icon-hover" href="/">
                <i className="ti ti-bell-ringing"></i>
                <div className="notification bg-primary rounded-circle"></div>
              </a>
            </li> */}
            <li className="nav-item">
              <a onClick={handleSitemapGeneration} className='btn btn-primary btn-sm'>Generate & Ping Sitemap</a>
            </li>
          </ul>
          <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li className="nav-item dropdown">
                {
                  businessProfile && 
                  <p className='mb-0'>{businessProfile.businessName}</p>
                }
                  <a href={BASE_URL} target="_blank" aria-expanded="false">
                    {BASE_URL}
                  </a> 
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link nav-icon-hover" href="/" id="drop2" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src={logo?.favicon? `${BASE_URL}${logo.favicon}` : profileImg } alt="" width="35" height="35" className="rounded-circle" />
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                  <div className="message-body text-center">
                    <span className='fw-bold fs-4'>Hello, Jhon Doe</span>
                    <a onClick={handleLogout} className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Navbar