import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_COUPON_URL , ADD_COUPON_URL, DELETE_COUPON_URL, UPDATE_COUPON_URL, MULTIPLE_COUPON_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_COUPON_REQUEST = 'FETCH_COUPON_REQUEST';
export const FETCH_COUPON_SUCCESS = 'FETCH_COUPON_SUCCESS';
export const FETCH_COUPON_FAILURE = 'FETCH_COUPON_FAILURE';

export const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAILURE = 'ADD_COUPON_FAILURE';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';

export const MULTIPLE_DELETE_COUPON_SUCCESS = 'MULTIPLE_DELETE_COUPON_SUCCESS';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILURE = 'UPDATE_COUPON_FAILURE';



export const fetchCouponRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_COUPON_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_COUPON_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const coupon = response.data;
      dispatch({
        type: FETCH_COUPON_SUCCESS,
        payload: [coupon],
      });
    } else {
      const couponList = response.data;
      dispatch({
        type: FETCH_COUPON_SUCCESS,
        payload: couponList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_COUPON_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchCouponSuccess = (coupon) => ({
    type: FETCH_COUPON_SUCCESS,
    payload: coupon,
  });
  
  export const fetchCouponFailure = (error) => ({
    type: FETCH_COUPON_FAILURE,
    payload: error,
  });
  
  export const addCouponRequest = (coupon, navigate) => async (dispatch) => {
    dispatch({ type: ADD_COUPON_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', coupon.Name);
        formData.append('Code', coupon.Code);
        formData.append('CouponType', coupon.CouponType);     
        formData.append('Discount', coupon.Discount);     
        formData.append('MinimumAmount', coupon.MinimumAmount);     
        formData.append('ExpiryDate', coupon.ExpiryDate);     
        formData.append('MaxUses', coupon.MaxUses);     
        formData.append('AllowMutiple', coupon.AllowMutiple);     
        formData.append('IsActive', coupon.IsActive);     
          
        if (coupon.Products.length > 0) {
          coupon.Products.forEach((product) => {
            formData.append('Products', product);
          });
        }
    
        // Check if MenuCategories is not empty before appending
        if (coupon.Categories.length > 0) {
          coupon.Categories.forEach((category) => {
            formData.append('Categories', category);
          });
        }
         

      const response = await axios.post(ADD_COUPON_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_COUPON_SUCCESS, payload: [response.data] });
      navigate('/coupon'); 

  } catch (error) {
    dispatch({
      type: ADD_COUPON_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Coupon.")
  }
  };
  
  export const addCouponSuccess = () => ({
    type: ADD_COUPON_SUCCESS,
  });
  
  export const addCouponFailure = (error) => ({
    type: ADD_COUPON_FAILURE,
    payload: error,
  });


  export const deleteCouponRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_COUPON_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_COUPON_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_COUPON_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_COUPON_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Coupon');
    }
  };

  export const deleteCouponSuccess = () => ({
    type: DELETE_COUPON_SUCCESS,
  });
  
  export const deleteCouponFailure = (error) => ({
    type: DELETE_COUPON_FAILURE,
    payload: error,
  });


  export const multipleDeleteCouponRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_COUPON_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_COUPON_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_COUPON_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_COUPON_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateCouponRequest = (coupon, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_COUPON_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', coupon.Name);
        formData.append('Code', coupon.Code);
        formData.append('CouponType', coupon.CouponType);     
        formData.append('Discount', coupon.Discount);     
        formData.append('MinimumAmount', coupon.MinimumAmount);     
        formData.append('ExpiryDate', coupon.ExpiryDate);     
        formData.append('MaxUses', coupon.MaxUses);     
        formData.append('AllowMutiple', coupon.AllowMutiple);     
        formData.append('IsActive', coupon.IsActive);   
        
        
        if (coupon.Products.length > 0) {
          coupon.Products.forEach((product) => {
            formData.append('Products', product);
          });
        }
    
        // Check if MenuCategories is not empty before appending
        if (coupon.Categories.length > 0) {
          coupon.Categories.forEach((category) => {
            formData.append('Categories', category);
          });
        }
    
         

      const response = await axios.put(`${UPDATE_COUPON_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response.data);
  

      dispatch({ type: UPDATE_COUPON_SUCCESS, payload: [response.data] });
      navigate('/coupon'); 

    } catch (error) {
      dispatch({
        type: UPDATE_COUPON_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Coupon');
    }
  };