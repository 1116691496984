import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_TAXES_URL , ADD_TAXES_URL, DELETE_TAXES_URL, UPDATE_TAXES_URL, MULTIPLE_TAXES_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_TAXES_REQUEST = 'FETCH_TAXES_REQUEST';
export const FETCH_TAXES_SUCCESS = 'FETCH_TAXES_SUCCESS';
export const FETCH_TAXES_FAILURE = 'FETCH_TAXES_FAILURE';

export const ADD_TAXES_REQUEST = 'ADD_TAXES_REQUEST';
export const ADD_TAXES_SUCCESS = 'ADD_TAXES_SUCCESS';
export const ADD_TAXES_FAILURE = 'ADD_TAXES_FAILURE';

export const DELETE_TAXES_REQUEST = 'DELETE_TAXES_REQUEST';
export const DELETE_TAXES_SUCCESS = 'DELETE_TAXES_SUCCESS';
export const DELETE_TAXES_FAILURE = 'DELETE_TAXES_FAILURE';

export const MULTIPLE_DELETE_TAXES_SUCCESS = 'MULTIPLE_DELETE_TAXES_SUCCESS';

export const UPDATE_TAXES_REQUEST = 'UPDATE_TAXES_REQUEST';
export const UPDATE_TAXES_SUCCESS = 'UPDATE_TAXES_SUCCESS';
export const UPDATE_TAXES_FAILURE = 'UPDATE_TAXES_FAILURE';



export const fetchTaxesRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_TAXES_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_TAXES_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const taxes = response.data;
      dispatch({
        type: FETCH_TAXES_SUCCESS,
        payload: [taxes],
      });
    } else {
      const taxesList = response.data;
      dispatch({
        type: FETCH_TAXES_SUCCESS,
        payload: taxesList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_TAXES_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchTaxesSuccess = (taxes) => ({
    type: FETCH_TAXES_SUCCESS,
    payload: taxes,
  });
  
  export const fetchTaxesFailure = (error) => ({
    type: FETCH_TAXES_FAILURE,
    payload: error,
  });
  
  export const addTaxesRequest = (taxes) => async (dispatch) => {
    dispatch({ type: ADD_TAXES_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', taxes.Name);
        formData.append('Type', taxes.Type);
        formData.append('Amount', taxes.Amount);   


      const response = await axios.post(ADD_TAXES_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_TAXES_SUCCESS, payload: [response.data] }); 

  } catch (error) {
    dispatch({
      type: ADD_TAXES_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Taxes.")
  }
  };
  
  export const addTaxesSuccess = () => ({
    type: ADD_TAXES_SUCCESS,
  });
  
  export const addTaxesFailure = (error) => ({
    type: ADD_TAXES_FAILURE,
    payload: error,
  });


  export const deleteTaxesRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_TAXES_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_TAXES_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_TAXES_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_TAXES_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Taxes');
    }
  };

  export const deleteTaxesSuccess = () => ({
    type: DELETE_TAXES_SUCCESS,
  });
  
  export const deleteTaxesFailure = (error) => ({
    type: DELETE_TAXES_FAILURE,
    payload: error,
  });


  export const multipleDeleteTaxesRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_TAXES_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_TAXES_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_TAXES_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_TAXES_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateTaxesRequest = (taxes, id) => async (dispatch) => {
    dispatch({ type: UPDATE_TAXES_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', taxes.Name);
        formData.append('Type', taxes.Type);
        formData.append('Amount', taxes.Amount);

      const response = await axios.put(`${UPDATE_TAXES_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: UPDATE_TAXES_SUCCESS, payload: [response.data] }); 

    } catch (error) {
      dispatch({
        type: UPDATE_TAXES_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Taxes');
    }
  };
  

  
  
