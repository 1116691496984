import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_CATEGORY_URL , ADD_CATEGORY_URL, DELETE_CATEGORY__URL, UPDATE_CATEGORY__URL, MULTIPLE_CATEGORY_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const MULTIPLE_DELETE_CATEGORY_SUCCESS = 'MULTIPLE_DELETE_CATEGORY_SUCCESS';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';






export const fetchCategoryRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_CATEGORY_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_CATEGORY_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const category = response.data;
      dispatch({
        type: FETCH_CATEGORY_SUCCESS,
        payload: [category],
      });
    } else {
      const categoryList = response.data;
      dispatch({
        type: FETCH_CATEGORY_SUCCESS,
        payload: categoryList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CATEGORY_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchCategorySuccess = (category) => ({
    type: FETCH_CATEGORY_SUCCESS,
    payload: category,
  });
  
  export const fetchCategoryFailure = (error) => ({
    type: FETCH_CATEGORY_FAILURE,
    payload: error,
  });
  
  export const addCategoryRequest = (category, navigate) => async (dispatch) => {
    dispatch({ type: ADD_CATEGORY_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', category.Name);
        formData.append('Slug', category.Slug);
        formData.append('Featured', category.Featured);
        formData.append('Status', category.Status);
        formData.append('SortOrder', category.SortOrder);
        formData.append('Image', category.Image);        


      const response = await axios.post(ADD_CATEGORY_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_CATEGORY_SUCCESS, payload: [response.data] });
      navigate('/category'); 

  } catch (error) {
    dispatch({
      type: ADD_CATEGORY_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Category.")
  }
  };
  
  export const addCategorySuccess = () => ({
    type: ADD_CATEGORY_SUCCESS,
  });
  
  export const addCategoryFailure = (error) => ({
    type: ADD_CATEGORY_FAILURE,
    payload: error,
  });


  export const deleteCategoryRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_CATEGORY_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_CATEGORY__URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_CATEGORY_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_CATEGORY_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Category');
    }
  };

  export const deleteCategorySuccess = () => ({
    type: DELETE_CATEGORY_SUCCESS,
  });
  
  export const deleteCategoryFailure = (error) => ({
    type: DELETE_CATEGORY_FAILURE,
    payload: error,
  });


  export const multipleDeleteCategoryRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_CATEGORY_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_CATEGORY_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_CATEGORY_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_CATEGORY_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateCategoryRequest = (category, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_CATEGORY_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', category.Name);
        formData.append('Slug', category.Slug);
        formData.append('Featured', category.Featured);
        formData.append('Status', category.Status);
        formData.append('SortOrder', category.SortOrder);
        formData.append('Image', category.Image); 

      const response = await axios.put(`${UPDATE_CATEGORY__URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_CATEGORY_SUCCESS, payload: [response.data] });
      navigate('/category'); 

    } catch (error) {
      dispatch({
        type: UPDATE_CATEGORY_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Category');
    }
  };
  

  
  
