import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-tagsinput/react-tagsinput.css";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import { event } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { addBlogPostRequest } from "../../redux_store/actions/blogPostActions";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchBlogPostRequest,
  updateBlogPostRequest,
  deleteBlogPostImageRequest,
} from "../../redux_store/actions/blogPostActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";

const AddBlogPost = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { blogPost, loading, error } = useSelector((state) => state.blogPost);

  const [tags, setTags] = useState([]);

  const [initialValues, setInitialValues] = useState({
    Title: "",
    Slug: "",
    MetaDescription: "",
    MetaKeywords: "",
    Image: null,
    Images: [],
    Content: "",
    SortOrder: "",
  });

  const validationSchema = Yup.object().shape({
    Title: Yup.string().required("Title is Required"),
    Slug: Yup.string(),
    MetaDescription: Yup.string(),
    MetaKeywords: Yup.string(),
    MetaMetaKeywords: Yup.mixed().nullable(),
    Image: Yup.mixed().nullable().required("Image is Required"),
    Image: Yup.mixed().nullable(),
    Content: Yup.string().required("Content is Required"),
    SortOrder: Yup.number().integer().nullable(),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateBlogPostRequest(values, id, navigate))
      : dispatch(addBlogPostRequest(values, navigate));
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchBlogPostRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && blogPost && blogPost.length > 0) {
      const filteredBlogPost = blogPost[0];

      if (
        filteredBlogPost.metaKeywords !== null &&
        filteredBlogPost.metaKeywords !== undefined
      ) {
        const tagsArray = filteredBlogPost.metaKeywords
          .split(",")
          .map((tag) => tag.trim());
        setTags(tagsArray);
      } else {
        setTags([]);
      }

      setInitialValues({
        Title: filteredBlogPost.title !== null ? filteredBlogPost.title : "",
        Slug: filteredBlogPost.slug !== null ? filteredBlogPost.slug : "",
        MetaDescription:
          filteredBlogPost.metaDescription !== null
            ? filteredBlogPost.metaDescription
            : "",
        MetaKeywords:
          filteredBlogPost.metaKeywords !== null
            ? filteredBlogPost.metaKeywords
            : "",
        Image: null,
        Images: [],
        Content:
          filteredBlogPost.content !== null ? filteredBlogPost.content : "",
        SortOrder:
          filteredBlogPost.sortOrder !== null ? filteredBlogPost.sortOrder : "",
      });
    }
  }, [editMode, blogPost]);

  const handleDelete = (ImageId) => {
    dispatch(deleteBlogPostImageRequest(ImageId));
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>
              {viewMode ? (
                <span>View BlogPost</span>
              ) : !viewMode && editMode ? (
                <span>Edit BlogPost</span>
              ) : (
                <span>Add BlogPost</span>
              )}
            </h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Title"
                          className="form-label fw-semibold"
                        >
                          Title
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Title"
                            name="Title"
                            disabled={viewMode}
                            onChange={(event) => {
                              const title = event.target.value;
                              setFieldValue("Title", title);
                              setFieldValue(
                                "Slug",
                                title
                                  .toLowerCase()
                                  .replace(/[^a-zA-Z0-9 ]/g, "")
                                  .replace(/ /g, "-")
                              );
                            }}
                            className="form-control border-0 ps-2"
                            placeholder="Enter Title here"
                          />
                        </div>
                        <ErrorMessage
                          name="Title"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Slug"
                          className="form-label fw-semibold"
                        >
                          Slug
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-link fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Slug"
                            name="Slug"
                            className="form-control border-0 ps-2"
                            placeholder="Slug"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Slug"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="MetaDescription"
                          className="form-label fw-semibold"
                        >
                          Meta Description
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-link fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="MetaDescription"
                            name="MetaDescription"
                            className="form-control border-0 ps-2"
                            placeholder="MetaDescription"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="MetaDescription"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="MetaKeywords"
                          className="form-label fw-semibold"
                        >
                          Meta Keywords
                        </label>
                        <div className=" border rounded-1">
                          <TagsInput
                            value={tags}
                            onChange={(newTags) => {
                              setTags(newTags);
                              setFieldValue("MetaKeywords", newTags.join(","));
                            }}
                            inputProps={{
                              id: "MetaKeywords",
                              className: "form-control",
                              name: "MetaKeywords",
                              placeholder: "Enter MetaKeywords here",
                              disabled: viewMode,
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="MetaKeywords"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Image"
                          className="form-label fw-semibold"
                        >
                          Image
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="Image"
                            name="Image"
                            disabled={viewMode}
                            onChange={(event) => {
                              const file = event.target.files[0];
                              setFieldValue("Image", file || null);
                            }}
                            className="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="Image"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode && (
                        <>
                          {blogPost &&
                          blogPost.length > 0 &&
                          !loading &&
                          blogPost[0].image ? (
                            <div className="py-3">
                              <img
                                src={`${BASE_URL}${blogPost[0].image}`}
                                alt="Image"
                                width="100"
                                height="100"
                              />
                            </div>
                          ) : loading || blogPost === undefined ? (
                            <div>Loading image...</div>
                          ) : (
                            <div>No image available</div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Images"
                          className="form-label fw-semibold"
                        >
                          Images
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="Images"
                            name="Images"
                            disabled={viewMode}
                            onChange={(event) => {
                              const files = event.target.files;
                              const imagesArray = Array.from(files);
                              setFieldValue("Images", imagesArray);
                            }}
                            className="form-control"
                            multiple
                          />
                        </div>
                        <ErrorMessage
                          name="Images"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode && (
                        <>
                          {blogPost &&
                          blogPost.length > 0 &&
                          !loading &&
                          blogPost[0].images ? (
                            <div className="py-3 d-flex flex-wrap">
                              {blogPost[0].images.map((image, index) => (
                                <div className="position-relative">
                                  <img
                                  key={index}
                                  src={`${BASE_URL}${image.img}`}
                                  alt="Image"
                                  width="100"
                                  height="100"
                                  className="me-3 mb-3"
                                />
                                {!viewMode && (
                                  <i onClick={() => handleDelete(image.id)} className='ti ti-trash text-danger fs-6 px-2 position-absolute' style={{bottom:'20px', right: '10px'}}></i>
                                )}
                                </div>
                                
                              ))}
                            </div>
                          ) : loading || blogPost === undefined ? (
                            <div>Loading images...</div>
                          ) : (
                            <div>No images available</div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="Content"
                          className="form-label fw-semibold"
                        >
                          Content
                        </label>
                        <div className=" border rounded-1">
                          <CKEditor
                            name="Content"
                            id="Content"
                            disabled={viewMode}
                            editor={ClassicEditor}
                            data={initialValues.Content || ""}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("Content", data);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="Content"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="SortOrder"
                          className="form-label fw-semibold"
                        >
                          Sort Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="SortOrder"
                            name="SortOrder"
                            className="form-control"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="SortOrder"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                  </div>
                  {viewMode ? (
                    <div className="py-3"></div>
                  ) : (
                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        <button
                          type="submit"
                          className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                        >
                          Save
                        </button>
                        <Link
                          to="/blogPost"
                          className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlogPost;
