import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateLogoRequest, fetchLogoRequest, deleteLogoRequest } from "../../redux_store/actions/logoActions";
import { BASE_URL } from "../../apis/url_constants";
import noImage from "../../assets/images/backgrounds/no-image.jpg";
import { ColorRing } from "react-loader-spinner";

const Logo = () => {

  const dispatch = useDispatch ();

  const { logo, loading, error } = useSelector(
    (state) => state.logo
  );

  useEffect(() => {
    dispatch(fetchLogoRequest());
  },[dispatch]);


  const [initialValues, setInitialValues] = useState({
    HeaderLogo: null,
    FooterLogo: null,
    Favicon: null,
  });

  const validationSchema = Yup.object().shape({
    HeaderLogo: Yup.mixed().nullable(),
    FooterLogo: Yup.mixed().nullable(),
    Favicon: Yup.mixed().nullable(),
  });

  const handleSubmit = (values) => {
    dispatch(updateLogoRequest(values));
  };

  const handleReset = () => {
    dispatch(deleteLogoRequest());
  }

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>Website Logo's</h3>
            <div className='d-flex align-items-center'>
                <span onClick={handleReset} className='btn btn-danger ms-2'>Reset Logo</span>
            </div>
          </div>
          <div className="card-body">
          {loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
                <ColorRing color="#00BFFF" />
              </div>
            ) : (
            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <Form>
                  <div className="row px-4">
                    <div className="col-md-4 text-center">
                    <label htmlFor="HeaderLogo">
                        <button
                          type="button"
                          id="HeaderLogoButton"
                          className="btn btn-info rounded-pill"
                          onClick={() => {
                            document.getElementById("HeaderLogo").click();
                          }}
                        >
                          Upload Header Logo
                        </button>
                      </label>
                      <input
                        type="file"
                        id="HeaderLogo"
                        name="HeaderLogo"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setFieldValue("HeaderLogo", file || null);
                          handleSubmit();
                        }}
                        style={{ display: "none" }}
                      />
                      <div className="d-flex align-items-center justify-content-center border border-secondary mt-3 rounded-3" style={{ minHeight : '300px'}}>
                     <img
                        src={logo?.headerLogo ? `${BASE_URL}${logo.headerLogo}` : noImage}
                        alt="Image"
                        width="100"
                        height="100"
                        className="img-fluid w-75"
                      />
                      </div>
                      <div className="text-center mt-3">
                        <p>Supported format: .jpeg, .png or .gif
                          Recommended dimension: Square (200x200 px)
                          Max file size:500 KB</p>
                      </div>
                      
                    </div>
                    <div className="col-md-4 text-center mt-5 mt-md-0">
                    <label htmlFor="FooterLogo">
                        <button
                          type="button"
                          id="FooterLogoButton"
                          className="btn btn-info rounded-pill"
                          onClick={() => {
                            document.getElementById("FooterLogo").click();
                          }}
                        >
                          Upload Footer Logo
                        </button>
                      </label>
                      <input
                        type="file"
                        id="FooterLogo"
                        name="FooterLogo"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setFieldValue("FooterLogo", file || null);
                          handleSubmit();
                        }}
                        style={{ display: "none" }}
                      />
                       <div className="d-flex align-items-center justify-content-center border border-secondary mt-3 rounded-3" style={{ minHeight : '300px'}}>
                     <img
                        src={logo?.footerLogo ? `${BASE_URL}${logo.footerLogo}` : noImage}
                        alt="Image"
                        width="100"
                        height="100"
                        className="img-fluid w-75"
                      />
                     </div>
                     <div className="text-center mt-3">
                        <p>Supported format: .jpeg, .png or .gif
                          Recommended dimension: Square (200x200 px)
                          Max file size:500 KB</p>
                      </div>
                    </div>
                    <div className="col-md-4 text-center mt-5 mt-md-0">
                    <label htmlFor="Favicon">
                        <button
                          type="button"
                          id="FaviconButton"
                          className="btn btn-info rounded-pill"
                          onClick={() => {
                            document.getElementById("Favicon").click();
                          }}
                        >
                          Upload Favicon
                        </button>
                      </label>
                      <input
                        type="file"
                        id="Favicon"
                        name="Favicon"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setFieldValue("Favicon", file || null);
                          handleSubmit();
                        }}
                        style={{ display: "none" }}
                      />
                       <div className="d-flex align-items-center justify-content-center border border-secondary mt-3 rounded-3" style={{ minHeight : '300px'}}>
                     <img
                        src={logo?.favicon ? `${BASE_URL}${logo.favicon}` : noImage}
                        alt="Image"
                        width="100"
                        height="100"
                        className="img-fluid w-75"
                      />
                      </div>
                      <div className="text-center mt-3">
                        <p>Supported format: .jpeg, .png or .gif
                          Recommended dimension: Square (200x200 px)
                          Max file size:500 KB</p>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Logo;
