import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_TEAM_URL , ADD_TEAM_URL, DELETE_TEAM__URL, UPDATE_TEAM__URL, MULTIPLE_TEAM_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_TEAM_REQUEST = 'FETCH_TEAM_REQUEST';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_FAILURE = 'FETCH_TEAM_FAILURE';

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';

export const MULTIPLE_DELETE_TEAM_SUCCESS = 'MULTIPLE_DELETE_TEAM_SUCCESS';

export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE';






export const fetchTeamRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_TEAM_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_TEAM_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const team = response.data;
      dispatch({
        type: FETCH_TEAM_SUCCESS,
        payload: [team],
      });
    } else {
      const teamList = response.data;
      dispatch({
        type: FETCH_TEAM_SUCCESS,
        payload: teamList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_TEAM_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchTeamSuccess = (team) => ({
    type: FETCH_TEAM_SUCCESS,
    payload: team,
  });
  
  export const fetchTeamFailure = (error) => ({
    type: FETCH_TEAM_FAILURE,
    payload: error,
  });
  
  export const addTeamRequest = (team, navigate) => async (dispatch) => {
    dispatch({ type: ADD_TEAM_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', team.Name);
        formData.append('Slug', team.Slug);
        formData.append('Post', team.Post);
        formData.append('SortOrder', team.SortOrder);
        formData.append('Image', team.Image);        


      const response = await axios.post(ADD_TEAM_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_TEAM_SUCCESS, payload: [response.data] });
      navigate('/team'); 

  } catch (error) {
    dispatch({
      type: ADD_TEAM_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Team.")
  }
  };
  
  export const addTeamSuccess = () => ({
    type: ADD_TEAM_SUCCESS,
  });
  
  export const addTeamFailure = (error) => ({
    type: ADD_TEAM_FAILURE,
    payload: error,
  });


  export const deleteTeamRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_TEAM_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_TEAM__URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_TEAM_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_TEAM_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Team');
    }
  };

  export const deleteTeamSuccess = () => ({
    type: DELETE_TEAM_SUCCESS,
  });
  
  export const deleteTeamFailure = (error) => ({
    type: DELETE_TEAM_FAILURE,
    payload: error,
  });


  export const multipleDeleteTeamRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_TEAM_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_TEAM_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_TEAM_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_TEAM_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateTeamRequest = (team, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_TEAM_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', team.Name);
        formData.append('Slug', team.Slug);
        formData.append('Post', team.Post);
        formData.append('SortOrder', team.SortOrder);
        formData.append('Image', team.Image);        


      const response = await axios.put(`${UPDATE_TEAM__URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_TEAM_SUCCESS, payload: [response.data] });
      navigate('/team'); 

    } catch (error) {
      dispatch({
        type: UPDATE_TEAM_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Team');
    }
  };
  

  
  
