import { FETCH_WEB_SETTING_REQUEST, FETCH_WEB_SETTING_SUCCESS, FETCH_WEB_SETTING_FAILURE, UPDATE_WEB_SETTING_REQUEST, UPDATE_WEB_SETTING_SUCCESS, UPDATE_WEB_SETTING_FAILURE } from "../actions/webSettingActions";

// Initial state
const initialState = {
    webSetting: null,
    loading: false,
    error: null,
  };
  
  // Reducer
  const webSettingReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_WEB_SETTING_REQUEST:
      case UPDATE_WEB_SETTING_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_WEB_SETTING_SUCCESS:
        return {
          ...state,
          loading: false,
          webSetting: action.payload,
        };
      case UPDATE_WEB_SETTING_SUCCESS:
        return {
          ...state,
          loading: false,
          webSetting: action.payload
        };
      case FETCH_WEB_SETTING_FAILURE:
      case UPDATE_WEB_SETTING_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default webSettingReducer;

  