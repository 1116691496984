import {React , useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { fetchUserRequest, deleteUserRequest } from '../../redux_store/actions/userActions';
import { useDispatch , useSelector } from 'react-redux';
import { BASE_URL } from '../../apis/url_constants';
import { ColorRing } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';


const User = () => {
    const [showAlert, setShowAlert] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { user, loading, error } = useSelector(
        (state) => state.user
      );

    useEffect(() => {
        dispatch(fetchUserRequest());
      }, [dispatch]);

      const handleDelete = (row) => {
        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteUserRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };

      const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

      const columns = user && user.length > 0 ? Object.keys(user[0]).map((key) => {
       
      
        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnstoAdd = ['FirstName' , 'LastName' , 'UserName'];
        const filteredColumns = columns.filter((column) => columnstoAdd.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
                <Link to={`/addUser/${row.id}`}><i className='ti ti-pencil text-info fs-6 px-2'></i></Link>
                <Link to={`/addUser/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-2'></i></Link>
              </>
            ),
            sortable: false,
          };
          
          const modifiedColumns = [actionsColumn , ...filteredColumns];

  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>User</h3>
                    <div className='d-flex align-items-center'>
                    <Link to="/addUser" className='btn btn-primary ms-2'> Add User</Link>
                    </div>
                </div>
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={user}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default User