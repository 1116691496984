import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import SideBar from "./SideBar";

const Dashboard = () => {
  return (
    <>
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed">
        <SideBar />
        <div className="body-wrapper">
          <Navbar />
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard