import {React , useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { fetchProductReviewRequest, deleteProductReviewRequest, multipleDeleteProductReviewRequest } from '../../redux_store/actions/productReviewActions';
import { useDispatch , useSelector } from 'react-redux';
import { BASE_URL } from '../../apis/url_constants';
import { ColorRing } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';
import RatingStars from 'react-rating-stars-component';


const ProductReview = () => {
    const [showAlert, setShowAlert] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { productReview, loading, error } = useSelector(
        (state) => state.productReview
      );

    useEffect(() => {
        dispatch(fetchProductReviewRequest());
      }, [dispatch]);

      const handleMulitpleDelete = () => {

        const confirmAlert = (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => { 
                const selectedIds = selectedRows.map(row => row.id);
                dispatch(multipleDeleteProductReviewRequest(selectedIds));
                setSelectedRows([]);
                hideAlert();
            }}
              onCancel={hideAlert}
            >
              This action cannot be undone.
            </SweetAlert>
          );
        
          setShowAlert(confirmAlert);
      };

      const handleDelete = (row) => {
        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteProductReviewRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };

      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };

      
      const ExpandedComponent = ({ data }) => {
        return (
          <>
          <div className='p-4'>
          <h6 style={{fontWeight: '900'}}>HeadLine</h6>
          <p>{data.headline}</p> 
          <h6 style={{fontWeight: '900'}}>Written Review</h6>
          <p>{data.writtenReview}</p> 
          </div> 
          </>
        )
      };

      const formatDate = (datetimeString) => {
        const date = new Date(datetimeString);
        const day = date.getDate(); // Get the day (1-31)
        const month = date.getMonth() + 1; // Get the month (0-11) and add 1 to match the desired range (1-12)
        const year = date.getFullYear(); // Get the full year
    
        // Format the components as needed (e.g., zero padding)
        const formattedDate = `${day}-${month}-${year}`;
    
        return formattedDate;
      };

      const RatingColumn = {
        name: 'Rating',
        cell: (row) => (
          <RatingStars
            count={5} // Number of stars
            value={row.rating} // Rating value from the row data
            size={24} // Size of the stars
            activeColor="#ffd700" // Color of the filled stars
            emptyIcon={<><i className='ti ti-star-filled fs-6'></i></>} // Icon for empty/unfilled stars
            filledIcon={<><i className='ti ti-star-filled fs-6'></i></>} // Icon for filled stars
            edit={false} // Disable editing the rating
          />
        ),
        sortable: true,
      };

      const columns = productReview && productReview.length > 0 ? Object.keys(productReview[0]).map((key) => {

        if (key === 'product') {
          return {
            name: 'Product',
            selector: (row) => (row.product ? row.product.name : ''),
            sortable: true,
          };
        }

        if (key === 'user') {
          return {
            name: 'User',
            selector: (row) => (row.user ? row.user.fullName : ''),
            sortable: true,
          };
        }

        if (key === 'rating') {
          return RatingColumn; // Replace the rating column with the new RatingColumn
        }
      

        if (key === 'createdAt') {
          return {
            name: 'Created At',
            selector: (row) => formatDate(row[key]), // Format the date here
            sortable: true,
          };
        }


        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnsToRemove = ['Id' , 'ProductId', 'Headline' , 'WrittenReview' , 'UserId',  'LastModified'];
        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
              </>
            ),
            sortable: false,
          };

          const modifiedColumns = [ actionsColumn, ...filteredColumns,];

          


  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>Product Reviews</h3>
                    <div className='d-flex align-items-center'>
                    {selectedRows.length > 0 && (
                        <button onClick={handleMulitpleDelete} className='btn btn-danger'>Delete Selected</button>
                    )}
                    </div>
                </div>
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={productReview}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                    responsive={true}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default ProductReview