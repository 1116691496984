import {
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_REQUEST,
  FETCH_USERS_ROLES_FAILURE,
  FETCH_USERS_ROLES_SUCCESS,
  FETCH_USERS_ROLES_REQUEST,
  ADD_USERS_REQUEST,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAILURE,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAILURE,
} from "../actions/userActions";

// Initial state
const initialState = {
  user: [],
  roles: [],
  loading: false,
  error: null,
};

// Reducer
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_USERS_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADD_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case DELETE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case FETCH_USERS_ROLES_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    case ADD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: state.user.filter(
          (entry) => entry.id !== action.payload.deletedId
        ),
      };

    // case MULTIPLE_DELETE_USERS_SUCCESS:
    //   console.log(action.payload)
    //   const deletedIds = action.payload;
    //   const updatedUser = state.user.filter(entry => !deletedIds.includes(entry.id));
    //   return {
    //     ...state,
    //     loading: false,
    //     user: updatedUser,
    //   };

    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_USERS_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
