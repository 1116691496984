import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_USERS_URL , ADD_USERS_URL, DELETE_USERS_URL, UPDATE_USERS_URL, GET_USERS_ROLES_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const ADD_USERS_REQUEST = 'ADD_USERS_REQUEST';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_FAILURE = 'ADD_USERS_FAILURE';

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE';

export const UPDATE_USERS_REQUEST = 'UPDATE_USERS_REQUEST';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const UPDATE_USERS_FAILURE = 'UPDATE_USERS_FAILURE';

export const FETCH_USERS_ROLES_REQUEST = 'FETCH_USERS_ROLES_REQUEST';
export const FETCH_USERS_ROLES_SUCCESS = 'FETCH_USERS_ROLES_SUCCESS';
export const FETCH_USERS_ROLES_FAILURE = 'FETCH_USERS_ROLES_FAILURE';

// export const MULTIPLE_DELETE_USERS_SUCCESS = 'MULTIPLE_DELETE_USERS_SUCCESS';



export const fetchUserRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_USERS_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response)

    if (id) {
      const user = response.data;
      dispatch({
        type: FETCH_USERS_SUCCESS,
        payload: [user],
      });
    } else {
      const userList = response.data;

      dispatch({
        type: FETCH_USERS_SUCCESS,
        payload: userList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_USERS_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchUserSuccess = (user) => ({
    type: FETCH_USERS_SUCCESS,
    payload: user,
  });
  
  export const fetchUserFailure = (error) => ({
    type: FETCH_USERS_FAILURE,
    payload: error,
  });


  
  export const addUserRequest = (user, navigate) => async (dispatch) => {
    dispatch({ type: ADD_USERS_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('FirstName', user.FirstName);
        formData.append('LastName', user.LastName);
        formData.append('Email', user.Email);
        formData.append('MobileNumber', user.MobileNumber);
        formData.append('Password', user.Password);
        formData.append('ConfirmPassword', user.ConfirmPassword);  
        
        if (user.Roles.length > 0) {
          user.Roles.forEach((user) => {
            formData.append('Roles', user);
          });
        }


      const response = await axios.post(ADD_USERS_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_USERS_SUCCESS, payload: [response.data] });
      navigate('/user'); 

  } catch (error) {
    dispatch({
      type: ADD_USERS_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding User.")
  }
  };
  
  export const addUserSuccess = () => ({
    type: ADD_USERS_SUCCESS,
  });
  
  export const addUserFailure = (error) => ({
    type: ADD_USERS_FAILURE,
    payload: error,
  });


  export const deleteUserRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_USERS_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_USERS_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_USERS_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_USERS_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete User');
    }
  };

  export const deleteUserSuccess = () => ({
    type: DELETE_USERS_SUCCESS,
  });
  
  export const deleteUserFailure = (error) => ({
    type: DELETE_USERS_FAILURE,
    payload: error,
  });


  // export const multipleDeleteUserRequest = (ids) => async (dispatch) => {
  //   dispatch({ type: DELETE_USERS_REQUEST });
  //   const token = localStorage.getItem('token');
  //   try {
  //     const response = await axios.post(MULTIPLE_DELETE_URL, ids, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  
  //     dispatch({ type: MULTIPLE_DELETE_USERS_SUCCESS, payload: ids });

  //   } catch (error) {
  //     console.log(error);
  //     dispatch({
  //       type: DELETE_USERS_FAILURE,
  //       payload: error.message,
  //     });
  //     // Handle error message if needed
  //   }
  // };
  
  export const updateUserRequest = (user, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_USERS_REQUEST });
    const token = localStorage.getItem('token');
    try {

      console.log(user.Keywords)


      const formData = new FormData();
        formData.append('Id', id);
        formData.append('FirstName', user.FirstName);
        formData.append('LastName', user.LastName);
        formData.append('Email', user.Email);
        formData.append('MobileNumber', user.MobileNumber);
        formData.append('Password', user.Password);
        formData.append('ConfirmPassword', user.ConfirmPassword);  
        
        if (user.Roles.length > 0) {
          user.Roles.forEach((user) => {
            formData.append('Roles', user);
          });
        }

      const response = await axios.put(`${UPDATE_USERS_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_USERS_SUCCESS, payload: [response.data] });
      navigate('/user'); 

    } catch (error) {
      dispatch({
        type: UPDATE_USERS_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update User');
    }
  };
  

  
  


export const fetchUserRolesRequest = () => async (dispatch) => {

  dispatch({ type: FETCH_USERS_ROLES_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_USERS_ROLES_URL;
    
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

      const userList = response.data;

      dispatch(fetchUserRolesSuccess(userList));
    
  } catch (error) {
    dispatch({
      type: FETCH_USERS_ROLES_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchUserRolesSuccess = (user) => ({
    type: FETCH_USERS_ROLES_SUCCESS,
    payload: user,
  });
  
  export const fetchUserRolesFailure = (error) => ({
    type: FETCH_USERS_ROLES_FAILURE,
    payload: error,
  });