import { FETCH_SEO_REQUEST, FETCH_SEO_SUCCESS, FETCH_SEO_FAILURE, UPDATE_SEO_REQUEST, UPDATE_SEO_SUCCESS, UPDATE_SEO_FAILURE } from "../actions/seoActions";

// Initial state
const initialState = {
    seo: null,
    loading: false,
    error: null,
  };
  
  // Reducer
  const seoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SEO_REQUEST:
      case UPDATE_SEO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SEO_SUCCESS:
        return {
          ...state,
          loading: false,
          seo: action.payload,
        };
      case UPDATE_SEO_SUCCESS:
        return {
          ...state,
          loading: false,
          seo: action.payload
        };
      case FETCH_SEO_FAILURE:
      case UPDATE_SEO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export default seoReducer;

  