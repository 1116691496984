import React from "react";
import { Formik, Form, Field, ErrorMessage, setFieldValue } from "formik";
import { Link, useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-tagsinput/react-tagsinput.css";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import { event } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { addUserRequest } from "../../redux_store/actions/userActions";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUserRequest, updateUserRequest, fetchUserRolesRequest } from "../../redux_store/actions/userActions";
import { ColorRing } from "react-loader-spinner";
import { BASE_URL } from "../../apis/url_constants";
import Select from "react-select";


const AddUser = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const { user, roles, loading, error } = useSelector((state) => state.user);

  const [initialValues, setInitialValues] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
    Password: "",
    ConfirmPassword: "",
    Roles: [],
  });

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    Email: Yup.string().email('Invalid email format').required('Email is required'),
    MobileNumber: Yup.string().required('Mobile number is required'),
    Password: Yup.string()
      .min(4, 'Password must be at least 4 characters long')
      .max(20, 'Password can be at most 20 characters long')
      .required('Password is required'),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref('Password'), null], 'Passwords must match')
      .required('Confirm password is required'),
    Roles: Yup.array().min(1, 'At least one role must be selected').required('Roles are required'),
  });

  const handleSubmit = (values) => {
    editMode ?
    dispatch(updateUserRequest(values , id , navigate)) :
    dispatch(addUserRequest(values , navigate))
  };

  useEffect(() => {

    dispatch(fetchUserRolesRequest());

    if (editMode) {
      dispatch(fetchUserRequest(id));
    }
  }, [editMode, dispatch, id]);

  useEffect(() => {
    if (editMode && user && user.length > 0 && user[0].user) {

      const filteredUser = user[0].user;
      const filteredRoles = user[0].userRole;

    
      
      setInitialValues({
        FirstName: filteredUser.firstName !== null ? filteredUser.firstName : "",
        LastName: filteredUser.lastName !== null ? filteredUser.lastName : "",
        Email: filteredUser.email !== null ? filteredUser.email : "",
        MobileNumber: filteredUser.phoneNumber !== null ? filteredUser.phoneNumber : "",
        Password: "",
        ConfirmPassword: "",
        Roles: filteredRoles
        ? filteredRoles.map(
            (role) => role
          )
        : [],
      });
    }
  }, [editMode, user]);
  
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>{viewMode ? <text>View User</text> : !viewMode && editMode ? <text>Edit User</text> : <text>Add User</text>}</h3>
          </div>
          <div className="card-body pb-0">

              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="FirstName"
                            className="form-label fw-semibold"
                          >
                            First Name
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-user fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="FirstName"
                              name="FirstName"
                              className="form-control border-0 ps-2"
                              placeholder="First Name"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="FirstName"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="LastName"
                            className="form-label fw-semibold"
                          >
                            Last Name
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-user fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="LastName"
                              name="LastName"
                              className="form-control border-0 ps-2"
                              placeholder="Last Name"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="LastName"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="MobileNumber"
                            className="form-label fw-semibold"
                          >
                            Mobile Number
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-dialpad fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="MobileNumber"
                              name="MobileNumber"
                              className="form-control border-0 ps-2"
                              placeholder="Mobile Number"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="MobileNumber"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="Email"
                            className="form-label fw-semibold"
                          >
                            Email
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-mail-forward fs-6"></i>
                            </span>
                            <Field
                              type="text"
                              id="Email"
                              name="Email"
                              className="form-control border-0 ps-2"
                              placeholder="Email"
                              disabled={viewMode}
                            />
                          </div>
                          <ErrorMessage
                            name="Email"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                 
                    
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="Password"
                            className="form-label fw-semibold"
                          >
                            Password
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-lock fs-6"></i>
                            </span>
                            <Field
                              type={passwordVisible ? 'text' : 'password'}
                              id="Password"
                              name="Password"
                              className="form-control border-0 ps-2"
                              placeholder="Password"
                              disabled={viewMode}
                            />
                             <i
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => {
                                  setPasswordVisible(prevState => !prevState);
                                }}
                              >
                                {passwordVisible ? <i className="ti ti-eye-off "></i>  : <i className="ti ti-eye" > </i>}
                              </i>
                          </div>
                          <ErrorMessage
                            name="Password"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label
                            htmlFor="ConfirmPassword"
                            className="form-label fw-semibold"
                          >
                            Confirm Password
                          </label>
                          <div className="input-group border rounded-1">
                            <span
                              className="input-group-text bg-transparent px-6 border-0"
                              id="basic-addon1"
                            >
                              <i className="ti ti-lock fs-6"></i>
                            </span>
                            <Field
                              type={confirmPasswordVisible ? 'text' : 'password'}
                              id="ConfirmPassword"
                              name="ConfirmPassword"
                              className="form-control border-0 ps-2"
                              placeholder="Confirm Password"
                              disabled={viewMode}
                            />
                             <i
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => {
                                  setConfirmPasswordVisible(prevState => !prevState);
                                }}
                              >
                                {confirmPasswordVisible ? <i className="ti ti-eye-off "></i>  : <i className="ti ti-eye" > </i>}
                              </i>
                          </div>
                          <ErrorMessage
                            name="ConfirmPassword"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Roles"
                          className="form-label fw-semibold"
                        >
                          Roles
                        </label>
                        <Field name="Roles">
                          {({ field, form }) => (
                            <Select
                              id="Roles"
                              name="Roles"
                              options={roles.map((role) => ({
                                value: role.name,
                                label: role.name,
                              }))}
                              isMulti
                              isDisabled={viewMode}
                              value={roles
                                .map((role) => ({
                                  value: role.name,
                                  label: role.name,
                                }))
                                .filter((option) =>
                                  field.value.includes(option.value)
                                )}
                              onChange={(selectedOptions) => {
                                form.setFieldValue(
                                  "Roles",
                                  selectedOptions
                                    ? selectedOptions.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="Roles"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>


                    </div>
                    { viewMode ? <div className="py-3" ></div> :
                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        <button
                          type="submit"
                          className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                        >
                          Save
                        </button>
                        <Link
                          to="/user"
                          className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div> }
                  </Form>
                )}
              </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
