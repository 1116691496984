import { act } from "react-dom/test-utils";
import {
  FETCH_GALLERY_IMAGE_FAILURE,
  FETCH_GALLERY_IMAGE_SUCCESS,
  FETCH_GALLERY_IMAGE_REQUEST,
  ADD_GALLERY_IMAGE_REQUEST,
  ADD_GALLERY_IMAGE_SUCCESS,
  ADD_GALLERY_IMAGE_FAILURE,
  DELETE_GALLERY_IMAGE_REQUEST,
  DELETE_GALLERY_IMAGE_SUCCESS,
  DELETE_GALLERY_IMAGE_FAILURE,
  MULTIPLE_DELETE_GALLERY_IMAGE_SUCCESS,
  UPDATE_GALLERY_IMAGE_REQUEST,
  UPDATE_GALLERY_IMAGE_SUCCESS,
  UPDATE_GALLERY_IMAGE_FAILURE
} from "../actions/galleryActions";

// Initial state
const initialState = {
  gallery: [],
  loading: false,
  error: null,
};

// Reducer
const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_GALLERY_IMAGE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_GALLERY_IMAGE_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_GALLERY_IMAGE_SUCCESS:

      return {
        ...state,
        loading: false,
        gallery: action.payload,
      };
    case ADD_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        gallery: action.payload,
      };
      
      case DELETE_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        gallery: state.gallery.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_GALLERY_IMAGE_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedTaxes= state.gallery.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          gallery: updatedTaxes,
        };

        case UPDATE_GALLERY_IMAGE_SUCCESS:
          return {
            ...state,
            loading: false,
            gallery: action.payload,
          };

      
    case FETCH_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_GALLERY_IMAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_GALLERY_IMAGE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default galleryReducer;
