import { FETCH_LOGO_REQUEST, FETCH_LOGO_SUCCESS, FETCH_LOGO_FAILURE, UPDATE_LOGO_REQUEST, UPDATE_LOGO_SUCCESS, UPDATE_LOGO_FAILURE, DELETE_LOGO_REQUEST, DELETE_LOGO_SUCCESS, DELETE_LOGO_FAILURE } from "../actions/logoActions";

// Initial state
const initialState = {
    logo: null,
    loading: false,
    error: null,
  };
  
  // Reducer
  const logoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LOGO_REQUEST: 
      case UPDATE_LOGO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
        case DELETE_LOGO_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
      case FETCH_LOGO_SUCCESS:
        return {
          ...state,
          loading: false,
          logo: action.payload,
        };
        
      case UPDATE_LOGO_SUCCESS:
        return {
          ...state,
          loading: false,
          logo: action.payload
        };
        case DELETE_LOGO_SUCCESS:
          return {
            ...state,
            loading: false,
            logo: action.payload
          };
      case FETCH_LOGO_FAILURE:
      case UPDATE_LOGO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case DELETE_LOGO_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
      default:
        return state;
    }
  };

  export default logoReducer;

  