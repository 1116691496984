import { act } from "react-dom/test-utils";
import {
  FETCH_SUBCATEGORY_FAILURE,
  FETCH_SUBCATEGORY_SUCCESS,
  FETCH_SUBCATEGORY_REQUEST,
  ADD_SUBCATEGORY_REQUEST,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_FAILURE,
  DELETE_SUBCATEGORY_REQUEST,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAILURE,
  MULTIPLE_DELETE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_REQUEST,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAILURE
} from "../actions/subCategoryActions";

// Initial state
const initialState = {
  subCategory: [],
  loading: false,
  error: null,
};

// Reducer
const subCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_SUBCATEGORY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_SUBCATEGORY_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_SUBCATEGORY_SUCCESS:

      return {
        ...state,
        loading: false,
        subCategory: action.payload,
      };
    case ADD_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategory: action.payload,
      };
      
      case DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategory: state.subCategory.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_SUBCATEGORY_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedSubCategory= state.subCategory.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          subCategory: updatedSubCategory,
        };

        case UPDATE_SUBCATEGORY_SUCCESS:
          return {
            ...state,
            loading: false,
            subCategory: action.payload,
          };

      
    case FETCH_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_SUBCATEGORY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_SUBCATEGORY_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default subCategoryReducer;
