import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSeoRequest, updateSeoRequest } from '../redux_store/actions/seoActions';
import { ColorRing } from 'react-loader-spinner';

const Seo = () => {
  const dispatch = useDispatch();

  const { seo, loading, error } = useSelector(
    (state) => state.seo
  );

  useEffect(() => {
    dispatch(fetchSeoRequest());
  }, [dispatch]);

  const initialValues = {
    MetaTitle : "",
    MetaDiscription : "",
    CanonicalTag : "",
    OgTitle : "",
    OgDescription : "",
    OgUrl : "",
    OgSiteName : "",
    MetaTwitter : "",
    MetaTwitterDescription :"",
    TwitterTitle : "",
    MsValidate: "",
    GSiteVerivation : "",
    HeaderCode: "",
    FooterCode: "",
  };

  if (seo) {
    initialValues.MetaTitle = seo.metaTitle;
    initialValues.MetaDiscription = seo.metaDiscription;
    initialValues.CanonicalTag = seo.canonicalTag;
    initialValues.OgTitle = seo.ogTitle;
    initialValues.OgDescription = seo.ogDescription;
    initialValues.OgUrl = seo.ogUrl;
    initialValues.OgSiteName = seo.ogSiteName;
    initialValues.MetaTwitter = seo.metaTwitter;
    initialValues.MetaTwitterDescription = seo.metaTwitterDescription;
    initialValues.TwitterTitle = seo.twitterTitle;
    initialValues.MsValidate = seo.msValidate;
    initialValues.GSiteVerivation = seo.gSiteVerivation;
    initialValues.HeaderCode = seo.headerCode;
    initialValues.FooterCode = seo.footerCode;
  }


  const validationSchema = Yup.object().shape({
    MetaTitle: Yup.string(),
    MetaDiscription: Yup.string(),
    CanonicalTag: Yup.string(),
    OgTitle: Yup.string(),
    OgDescription: Yup.string(),
    OgUrl: Yup.string(),
    OgSiteName: Yup.string(),
    MetaTwitter: Yup.string(),
    MetaTwitterDescription: Yup.string(),
    TwitterTitle: Yup.string(),
    MsValidate: Yup.string(),
    GSiteVerivation: Yup.string(),
    HeaderCode: Yup.string(),
    FooterCode: Yup.string(),
  });

  const handleUpdateProfile = (values) => {
    dispatch(updateSeoRequest(values));
  }

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center">
      {/* <ColorRing visible={true} height="80" width="80"/> */}
       <span>Loading...</span>
      </div>;
  }

  return (
    <>
    <div className='col-12'>
      <div className="card">
          <div className="card-body">
            <h3 className='mb-4'>SEO Setting's</h3>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleUpdateProfile}>
            {({ errors, touched }) => (
             <Form>
              <div className="form-floating mb-3">                
                <Field type="text" name='MetaTitle' placeholder="Meta Title"  className={`form-control border ${errors.MetaTitle && touched.MetaTitle ? 'border-danger' : 'border-info'}`} id="MetaTitle" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.MetaTitle && touched.MetaTitle ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Meta Title</span></label>
                <ErrorMessage name="MetaTitle" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field as="textarea" name='MetaDiscription' placeholder="Meta Description"  className={`form-control border ${errors.MetaDiscription && touched.MetaDiscription ? 'border-danger' : 'border-info'}`} id="MetaDiscription" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.MetaDiscription && touched.MetaDiscription ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Meta Description</span></label>
                <ErrorMessage name="MetaDiscription" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field type="text" name='CanonicalTag' placeholder="Canonical Tag"  className={`form-control border ${errors.CanonicalTag && touched.CanonicalTag ? 'border-danger' : 'border-info'}`} id="CanonicalTag" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.CanonicalTag && touched.CanonicalTag ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Canonical Tag</span></label>
                <ErrorMessage name="CanonicalTag" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field type="text" name='OgTitle' placeholder="Og Title"  className={`form-control border ${errors.OgTitle && touched.OgTitle ? 'border-danger' : 'border-info'}`} id="OgTitle" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.OgTitle && touched.OgTitle ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Og Title</span></label>
                <ErrorMessage name="OgTitle" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field as="textarea" name='OgDescription' placeholder="Og Description"  className={`form-control border ${errors.OgDescription && touched.OgDescription ? 'border-danger' : 'border-info'}`} id="OgDescription" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.OgDescription && touched.OgDescription ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Og Description</span></label>
                <ErrorMessage name="OgDescription" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field type="text" name='OgUrl' placeholder="Og Url"  className={`form-control border ${errors.OgUrl && touched.OgUrl ? 'border-danger' : 'border-info'}`} id="OgUrl" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.OgUrl && touched.OgUrl ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Og Url</span></label>
                <ErrorMessage name="OgUrl" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field type="text" name='OgSiteName' placeholder="Og SiteName"  className={`form-control border ${errors.OgSiteName && touched.OgSiteName ? 'border-danger' : 'border-info'}`} id="OgSiteName" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.OgSiteName && touched.OgSiteName ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Og SiteName</span></label>
                <ErrorMessage name="OgSiteName" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field type="text" name='MsValidate' placeholder="MS Validate"  className={`form-control border ${errors.MsValidate && touched.MsValidate ? 'border-danger' : 'border-info'}`} id="MsValidate" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.MsValidate && touched.MsValidate ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">MS Validate</span></label>
                <ErrorMessage name="MsValidate" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field type="text" name='GSiteVerivation' placeholder="GSite Verification"  className={`form-control border ${errors.GSiteVerivation && touched.GSiteVerivation ? 'border-danger' : 'border-info'}`} id="GSiteVerivation" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.GSiteVerivation && touched.GSiteVerivation ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">GSite Verification</span></label>
                <ErrorMessage name="GSiteVerivation" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field style={{height: "200px"}}   as="textarea" name='HeaderCode' placeholder="Header Code"  className={`form-control border ${errors.HeaderCode && touched.HeaderCode ? 'border-danger' : 'border-info'}`} id="HeaderCode" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.HeaderCode && touched.HeaderCode ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Header Code</span></label>
                <ErrorMessage name="HeaderCode" component="div" className='mt-1 error' />
              </div>

              <div className="form-floating mb-3">                
                <Field style={{height: "200px"}} as="textarea" name='FooterCode' placeholder="Footer Code"  className={`form-control border ${errors.FooterCode && touched.FooterCode ? 'border-danger' : 'border-info'}`} id="FooterCode" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.FooterCode && touched.FooterCode ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Footer Code</span></label>
                <ErrorMessage name="FooterCode" component="div" className='mt-1 error' />
              </div>

              <div className="d-md-flex align-items-center"> 
                <div className="mt-3 mt-md-0 ms-auto">
                  <button type="submit" className="btn btn-info font-medium rounded-pill px-4">
                    <div className="d-flex align-items-center">
                      Save
                    </div>
                  </button>
                </div>
              </div>
              
              </Form>
            )}
            </Formik>
          </div>
        </div>
    </div>
    </>
  );
};

export default Seo;
