import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ABANDONED_WISHLIST_URL} from '../../apis/url_constants';

// Action Types
export const FETCH_ABANDONED_WISHLIST_REQUEST = 'FETCH_ABANDONED_WISHLIST_REQUEST';
export const FETCH_ABANDONED_WISHLIST_SUCCESS = 'FETCH_ABANDONED_WISHLIST_SUCCESS';
export const FETCH_ABANDONED_WISHLIST_FAILURE = 'FETCH_ABANDONED_WISHLIST_FAILURE';


export const fetchAbandonedWishlistRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_ABANDONED_WISHLIST_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_ABANDONED_WISHLIST_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const abandonedWishlist = response.data;
      dispatch({
        type: FETCH_ABANDONED_WISHLIST_SUCCESS,
        payload: [abandonedWishlist],
      });
    } else {
      const abandonedWishlistList = response.data;
      dispatch({
        type: FETCH_ABANDONED_WISHLIST_SUCCESS,
        payload: abandonedWishlistList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ABANDONED_WISHLIST_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchAbandonedWishlistSuccess = (abandonedWishlist) => ({
    type: FETCH_ABANDONED_WISHLIST_SUCCESS,
    payload: abandonedWishlist,
  });
  
  export const fetchAbandonedWishlistFailure = (error) => ({
    type: FETCH_ABANDONED_WISHLIST_FAILURE,
    payload: error,
  });

  
  
