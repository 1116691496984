import { act } from "react-dom/test-utils";
import {
  FETCH_ABANDONED_WISHLIST_FAILURE,
  FETCH_ABANDONED_WISHLIST_SUCCESS,
  FETCH_ABANDONED_WISHLIST_REQUEST,
} from "../actions/abandonedWishlistActions";

// Initial state
const initialState = {
  abandonedWishlist: [],
  loading: false,
  error: null,
};

// Reducer
const abandonedWishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ABANDONED_WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
 
    case FETCH_ABANDONED_WISHLIST_SUCCESS:

      return {
        ...state,
        loading: false,
        abandonedWishlist: action.payload,
      };
 
    case FETCH_ABANDONED_WISHLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default abandonedWishlistReducer;
