import { act } from "react-dom/test-utils";
import {
  FETCH_TEAM_FAILURE,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_REQUEST,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  MULTIPLE_DELETE_TEAM_SUCCESS,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE
} from "../actions/teamActions";

// Initial state
const initialState = {
  team: [],
  loading: false,
  error: null,
};

// Reducer
const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_TEAM_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_TEAM_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        team: action.payload,
      };
    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        team: action.payload,
      };
      
      case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        team: state.team.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_TEAM_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedTeam = state.team.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          team: updatedTeam,
        };

        case UPDATE_TEAM_SUCCESS:
          return {
            ...state,
            loading: false,
            team: action.payload,
          };

      
    case FETCH_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_TEAM_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_TEAM_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default teamReducer;
