import axios from 'axios';
import { LOGIN_URL } from "../../apis/url_constants";
import { LOGOUT_URL } from '../../apis/url_constants';
import { toast } from 'react-toastify';
import { GETBUSINESSPROFILE_URL, UPDATEBUSINESSPROFILE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_BUSINESS_PROFILE_REQUEST = 'FETCH_BUSINESS_PROFILE_REQUEST';
export const FETCH_BUSINESS_PROFILE_SUCCESS = 'FETCH_BUSINESS_PROFILE_SUCCESS';
export const FETCH_BUSINESS_PROFILE_FAILURE = 'FETCH_BUSINESS_PROFILE_FAILURE';
export const UPDATE_BUSINESS_PROFILE_REQUEST = 'UPDATE_BUSINESS_PROFILE_REQUEST';
export const UPDATE_BUSINESS_PROFILE_SUCCESS = 'UPDATE_BUSINESS_PROFILE_SUCCESS';
export const UPDATE_BUSINESS_PROFILE_FAILURE = 'UPDATE_BUSINESS_PROFILE_FAILURE';


    export const fetchBusinessProfileRequest = () => async (dispatch) => {
        dispatch({ type: FETCH_BUSINESS_PROFILE_REQUEST });
        const token = localStorage.getItem('token');

        try {
        const response = await axios.get(GETBUSINESSPROFILE_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const businessProfile = response.data;
            dispatch({
                type: FETCH_BUSINESS_PROFILE_SUCCESS,
                payload: businessProfile,
            });
        } catch (error) {
            dispatch({
                type: FETCH_BUSINESS_PROFILE_FAILURE,
                payload: error.message,
            });
            toast.error(error.response.data)
        }
    };

  
  export const fetchBusinessProfileSuccess = (businessProfile) => ({
    type: FETCH_BUSINESS_PROFILE_SUCCESS,
    payload: businessProfile,
  });
  
  export const fetchBusinessProfileFailure = (error) => ({
    type: FETCH_BUSINESS_PROFILE_FAILURE,
    payload: error,
  });
  
  export const updateBusinessProfileRequest = (updatedBusinessProfile) => async (dispatch) => {
    dispatch({ type: UPDATE_BUSINESS_PROFILE_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(UPDATEBUSINESSPROFILE_URL, updatedBusinessProfile, {
        headers: {
          // Add your headers here
          Authorization: `Bearer ${token}`,
          // Other headers...
        },
      });
        dispatch({ type: UPDATE_BUSINESS_PROFILE_SUCCESS, payload: response.data });
        toast.success("Business Profile has been Updated.")
    } catch (error) {
      dispatch({
        type: UPDATE_BUSINESS_PROFILE_FAILURE,
        payload: error.message,
      });
      toast.error("Something went Wrong!, While Updating Business Profile.")
    }
  };
  
  export const updateBusinessProfileSuccess = () => ({
    type: UPDATE_BUSINESS_PROFILE_SUCCESS,
  });
  
  export const updateBusinessProfileFailure = (error) => ({
    type: UPDATE_BUSINESS_PROFILE_FAILURE,
    payload: error,
  });


  
