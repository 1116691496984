import {
  FETCH_NAVIGATION_MENU_FAILURE,
  FETCH_NAVIGATION_MENU_SUCCESS,
  FETCH_NAVIGATION_MENU_REQUEST,
  ADD_NAVIGATION_MENU_REQUEST,
  ADD_NAVIGATION_MENU_SUCCESS,
  ADD_NAVIGATION_MENU_FAILURE,
  DELETE_NAVIGATION_MENU_REQUEST,
  DELETE_NAVIGATION_MENU_SUCCESS,
  DELETE_NAVIGATION_MENU_FAILURE,
  MULTIPLE_DELETE_NAVIGATION_MENU_SUCCESS,
  UPDATE_NAVIGATION_MENU_REQUEST,
  UPDATE_NAVIGATION_MENU_SUCCESS,
  UPDATE_NAVIGATION_MENU_FAILURE
} from "../actions/navigationMenuActions";

// Initial state
const initialState = {
  navigationMenu: [],
  loading: false,
  error: null,
};

// Reducer
const navigationMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NAVIGATION_MENU_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_NAVIGATION_MENU_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_NAVIGATION_MENU_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_NAVIGATION_MENU_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_NAVIGATION_MENU_SUCCESS:

      return {
        ...state,
        loading: false,
        navigationMenu: action.payload,
      };
    case ADD_NAVIGATION_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        navigationMenu: action.payload,
      };
      
      case DELETE_NAVIGATION_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        navigationMenu: state.navigationMenu.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_NAVIGATION_MENU_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedNavigationMenu= state.navigationMenu.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          navigationMenu: updatedNavigationMenu,
        };

        case UPDATE_NAVIGATION_MENU_SUCCESS:
          return {
            ...state,
            loading: false,
            navigationMenu: action.payload,
          };

      
    case FETCH_NAVIGATION_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_NAVIGATION_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_NAVIGATION_MENU_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_NAVIGATION_MENU_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default navigationMenuReducer;
