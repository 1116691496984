import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBusinessProfileRequest, updateBusinessProfileRequest } from '../redux_store/actions/businessProfileActions';
import { ColorRing } from 'react-loader-spinner';

const BusinessProfile = () => {
  const dispatch = useDispatch();

  const { businessProfile, loading, error } = useSelector(
    (state) => state.businessProfile
  );

  useEffect(() => {
    dispatch(fetchBusinessProfileRequest());
  }, [dispatch]);

  const initialValues = {
    BusinessName: '',
    Title: '',
    EmailAddress: '',
    RegisteredContactNumber: '',
    AlternateContactNumber: '',
    DisplayContact1: '',
    DisplayContact2: '',
    DisplayContact3: '',
    Address: '',
    City: '',
    State: '',
    Country: '',
    Pincode: '',
    WhatsAppForBusinessNumber: '',
    FacebookPageURL: '',
    InstagramPageURL: '',
    TwitterURL: '',
    LinkedInURL: '',
    YoutubeURL: '',
    OtherWebsite: '',
    Copyright: '',
  };

  if (businessProfile) {
    initialValues.BusinessName = businessProfile.businessName;
    initialValues.Title = businessProfile.title;
    initialValues.EmailAddress = businessProfile.emailAddress;
    initialValues.RegisteredContactNumber = businessProfile.registeredContactNumber;
    initialValues.AlternateContactNumber = businessProfile.alternateContactNumber;
    initialValues.DisplayContact1 = businessProfile.displayContact1;
    initialValues.DisplayContact2 = businessProfile.displayContact2;
    initialValues.DisplayContact3 = businessProfile.displayContact3;
    initialValues.Address = businessProfile.address;
    initialValues.City = businessProfile.city;
    initialValues.State = businessProfile.state;
    initialValues.Country = businessProfile.country;
    initialValues.Pincode = businessProfile.pincode;
    initialValues.WhatsAppForBusinessNumber = businessProfile.whatsAppForBusinessNumber;
    initialValues.FacebookPageURL = businessProfile.facebookPageURL;
    initialValues.InstagramPageURL = businessProfile.instagramPageURL;
    initialValues.TwitterURL = businessProfile.twitterURL;
    initialValues.LinkedInURL = businessProfile.linkedInURL;
    initialValues.YoutubeURL = businessProfile.youtubeURL;
    initialValues.OtherWebsite = businessProfile.otherWebsite;
    initialValues.Copyright = businessProfile.copyright;
  }


  const validationSchema = Yup.object().shape({
    BusinessName: Yup.string().required('Business Name is required'),
    Title: Yup.string(),
    EmailAddress: Yup.string().email('Invalid email address').required('Email address is required'),
    RegisteredContactNumber: Yup.string().required('Registered Contact Number is required').matches(/^\d{10}$/, 'Contact Number must be a 10-digit number'),
    AlternateContactNumber: Yup.string().matches(/^\d{10}$/, 'Contact Number must be a 10-digit number'),
    DisplayContact1: Yup.string().matches(/^\d{10}$/, 'Contact Number must be a 10-digit number'),
    DisplayContact2: Yup.string().matches(/^\d{10}$/, 'Contact Number must be a 10-digit number'),
    DisplayContact3: Yup.string().matches(/^\d{10}$/, 'Contact Number must be a 10-digit number'),
    Address: Yup.string().required('Address is required'),
    City: Yup.string().required('City is required'),
    State: Yup.string().required('State is required'),
    Country: Yup.string().required('Country is required'),
    Pincode: Yup.string().required('Pincode is required').matches(/^[0-9]+$/, 'Please Enter a Valid Pincode'),
    WhatsAppForBusinessNumber: Yup.string(),
    FacebookPageURL: Yup.string(),
    InstagramPageURL: Yup.string(),
    TwitterURL: Yup.string(),
    LinkedInURL: Yup.string(),
    YoutubeURL: Yup.string(),
    OtherWebsite: Yup.string(),
    Copyright: Yup.string(),
  });

  const handleUpdateProfile = (values) => {
    dispatch(updateBusinessProfileRequest(values));
  }

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center">
      {/* <ColorRing visible={true} height="80" width="80"/> */}
       <span>Loading...</span>
      </div>;
  }

  

  return (
    <>
    <div className='col-12'>
      <div className="card">
          <div className="card-body">
            <h3 className='mb-4'>Business Profile</h3>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleUpdateProfile}>
            {({ errors, touched }) => (
             <Form>
              <div className="form-floating mb-3">                
                <Field type="text" name='BusinessName' placeholder="Business Name"  className={`form-control border ${errors.BusinessName && touched.BusinessName ? 'border-danger' : 'border-info'}`} id="BusinessName" />
                <label><i className={`ti ti-briefcase me-2 fs-4 ${errors.BusinessName && touched.BusinessName ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Business Name</span></label>
                <ErrorMessage name="BusinessName" component="div" className='mt-1 error' />
              </div>
              <div className="form-floating mb-3">                
                <Field type="text" name='Title' placeholder="Title"  className={`form-control border ${errors.Title && touched.Title ? 'border-danger' : 'border-info'}`} id="Title" />
                <label><i className={`ti ti-badge-cc me-2 fs-4 ${errors.Title && touched.Title ? 'text-danger' :'text-info'}`}></i><span className="border-start border-info ps-3">Title</span></label>
                <ErrorMessage name="Title" component="div" className='mt-1 error' />
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='EmailAddress' placeholder="Email"  className={`form-control border ${errors.EmailAddress && touched.EmailAddress ? 'border-danger' : 'border-info'}`} id="EmailAddress" />
                    <label><i className={`ti ti-mail me-2 fs-4 ${errors.EmailAddress && touched.EmailAddress ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Email</span></label>
                    <ErrorMessage name="EmailAddress" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='RegisteredContactNumber' placeholder="Registered Contact Number"  className={`form-control border ${errors.RegisteredContactNumber && touched.RegisteredContactNumber ? 'border-danger' : 'border-info'}`} id="RegisteredContactNumber" />
                    <label><i className={`ti ti-address-book me-2 fs-4 ${errors.RegisteredContactNumber && touched.RegisteredContactNumber ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Registered Contact Number</span></label>
                    <ErrorMessage name="RegisteredContactNumber" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='AlternateContactNumber' placeholder="Alternate Contact Number"  className={`form-control border ${errors.AlternateContactNumber && touched.AlternateContactNumber ? 'border-danger' : 'border-info'}`} id="AlternateContactNumber" />
                    <label><i className={`ti ti-address-book me-2 fs-4 ${errors.AlternateContactNumber && touched.AlternateContactNumber ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Alternate Contact Number</span></label>
                    <ErrorMessage name="AlternateContactNumber" component="div" className='mt-1 error' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='DisplayContact1' placeholder="Display Contact 1"  className={`form-control border ${errors.DisplayContact1 && touched.DisplayContact1 ? 'border-danger' : 'border-info'}`} id="DisplayContact1" />
                    <label><i className={`ti ti-address-book me-2 fs-4 ${errors.DisplayContact1 && touched.DisplayContact1 ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Display Contact 1</span></label>
                    <ErrorMessage name="DisplayContact1" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                <div className="form-floating mb-3">                
                    <Field type="text" name='DisplayContact2' placeholder="Display Contact 2"  className={`form-control border ${errors.DisplayContact2 && touched.DisplayContact2 ? 'border-danger' : 'border-info'}`} id="DisplayContact2" />
                    <label><i className={`ti ti-address-book me-2 fs-4 ${errors.DisplayContact2 && touched.DisplayContact2 ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Display Contact 2</span></label>
                    <ErrorMessage name="DisplayContact2" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='DisplayContact3' placeholder="Display Contact 3"  className={`form-control border ${errors.DisplayContact3 && touched.DisplayContact3 ? 'border-danger' : 'border-info'}`} id="DisplayContact3" />
                    <label><i className={`ti ti-address-book me-2 fs-4 ${errors.DisplayContact3 && touched.DisplayContact3 ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Display Contact 3</span></label>
                    <ErrorMessage name="DisplayContact3" component="div" className='mt-1 error' />
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">                
                <Field type="text" name='Address' placeholder="Address"  className={`form-control border ${errors.Address && touched.Address ? 'border-danger' : 'border-info'}`} id="Address" />
                <label><i className={`ti ti-map me-2 fs-4 ${errors.Address && touched.Address ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Address</span></label>
                <ErrorMessage name="Address" component="div" className='mt-1 error' />
              </div>  
              <div className='row'>
                <div className='col-md-3'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='City' placeholder="City"  className={`form-control border ${errors.City && touched.City ? 'border-danger' : 'border-info'}`} id="City" />
                    <label><i className={`ti ti-building-skyscraper me-2 fs-4 ${errors.City && touched.City ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">City</span></label>
                    <ErrorMessage name="City" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='State' placeholder="State"  className={`form-control border ${errors.State && touched.State ? 'border-danger' : 'border-info'}`} id="State" />
                    <label><i className={`ti ti-building-bank me-2 fs-4 ${errors.State && touched.State ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">State</span></label>
                    <ErrorMessage name="State" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='Country' placeholder="Country"  className={`form-control border ${errors.Country && touched.Country ? 'border-danger' : 'border-info'}`} id="Country" />
                    <label><i className={`ti ti-world me-2 fs-4 ${errors.Country && touched.Country ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Country</span></label>
                    <ErrorMessage name="Country" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='Pincode' placeholder="Pincode"  className={`form-control border ${errors.Pincode && touched.Pincode ? 'border-danger' : 'border-info'}`} id="Pincode" />
                    <label><i className={`ti ti-map-pin me-2 fs-4 ${errors.Pincode && touched.Pincode ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Pincode</span></label>
                    <ErrorMessage name="Pincode" component="div" className='mt-1 error' />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='WhatsAppForBusinessNumber' placeholder="WhatsApp Business"  className={`form-control border ${errors.WhatsAppForBusinessNumber && touched.WhatsAppForBusinessNumber ? 'border-danger' : 'border-info'}`} id="WhatsAppForBusinessNumber" />
                    <label><i className={`ti ti-brand-whatsapp me-2 fs-4 ${errors.WhatsAppForBusinessNumber && touched.WhatsAppForBusinessNumber ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">WhatsApp Business</span></label>
                    <ErrorMessage name="WhatsAppForBusinessNumber" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                <div className="form-floating mb-3">                
                    <Field type="text" name='FacebookPageURL' placeholder="Facebook Page URL"  className={`form-control border ${errors.FacebookPageURL && touched.FacebookPageURL ? 'border-danger' : 'border-info'}`} id="FacebookPageURL" />
                    <label><i className={`ti ti-brand-facebook me-2 fs-4 ${errors.FacebookPageURL && touched.FacebookPageURL ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Facebook Page URL</span></label>
                    <ErrorMessage name="FacebookPageURL" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='InstagramPageURL' placeholder="Instagram Page URL"  className={`form-control border ${errors.InstagramPageURL && touched.InstagramPageURL ? 'border-danger' : 'border-info'}`} id="InstagramPageURL" />
                    <label><i className={`ti ti-brand-instagram me-2 fs-4 ${errors.InstagramPageURL && touched.InstagramPageURL ? 'text-danger' : 'text-info'}`}></i><span className="border-start border-info ps-3">Instagram Page URL</span></label>
                    <ErrorMessage name="InstagramPageURL" component="div" className='mt-1 error' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='TwitterURL' placeholder="Twitter URL"  className={`form-control border ${errors.TwitterURL && touched.TwitterURL ? 'border-danger' : 'border-info'}`} id="TwitterURL" />
                    <label><i className={`ti ti-brand-twitter me-2 fs-4 ${errors.TwitterURL && touched.TwitterURL ? 'text-danger' :'text-info'}`}></i><span className="border-start border-info ps-3">Twitter URL</span></label>
                    <ErrorMessage name="TwitterURL" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                <div className="form-floating mb-3">                
                    <Field type="text" name='LinkedInURL' placeholder="LinkedIn URL"  className={`form-control border ${errors.LinkedInURL && touched.LinkedInURL ? 'border-danger' : 'border-info'}`} id="LinkedInURL" />
                    <label><i className={`ti ti-brand-linkedin me-2 fs-4 ${errors.LinkedInURL && touched.LinkedInURL ? 'text-danger' :'text-info'}`}></i><span className="border-start border-info ps-3">LinkedIn URL</span></label>
                    <ErrorMessage name="LinkedInURL" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='YoutubeURL' placeholder="Youtube URL"  className={`form-control border ${errors.YoutubeURL && touched.YoutubeURL ? 'border-danger' : 'border-info'}`} id="YoutubeURL" />
                    <label><i className={`ti ti-brand-youtube me-2 fs-4 ${errors.YoutubeURL && touched.YoutubeURL ? 'text-danger' :'text-info'}`}></i><span className="border-start border-info ps-3">Youtube URL</span></label>
                    <ErrorMessage name="YoutubeURL" component="div" className='mt-1 error' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className="form-floating mb-3">                
                    <Field type="text" name='OtherWebsite' placeholder="Other Website"  className={`form-control border ${errors.OtherWebsite && touched.OtherWebsite ? 'border-danger' : 'border-info'}`} id="OtherWebsite" />
                    <label><i className={`ti ti-app-window me-2 fs-4 ${errors.OtherWebsite && touched.OtherWebsite ? 'text-danger' :'text-info'}`}></i><span className="border-start border-info ps-3">Other Website</span></label>
                    <ErrorMessage name="OtherWebsite" component="div" className='mt-1 error' />
                  </div>
                </div>
                <div className='col-md-6'>
                <div className="form-floating mb-3">                
                    <Field type="text" name='Copyright' placeholder="Copyright"  className={`form-control border ${errors.Copyright && touched.Copyright ? 'border-danger' : 'border-info'}`} id="Copyright" />
                    <label><i className={`ti ti-copyright me-2 fs-4 ${errors.Copyright && touched.Copyright ? 'text-danger' :'text-info'}`}></i><span className="border-start border-info ps-3">Copyright</span></label>
                    <ErrorMessage name="Copyright" component="div" className='mt-1 error' />
                  </div>
                </div>
              </div>
            

              <div className="d-md-flex align-items-center">
                
                <div className="mt-3 mt-md-0 ms-auto">
                  <button type="submit" className="btn btn-info font-medium rounded-pill px-4">
                    <div className="d-flex align-items-center">
                      Save
                    </div>
                  </button>
                </div>
              </div>
              </Form>
            )}
            </Formik>
          </div>
        </div>
    </div>
    </>
  );
};

export default BusinessProfile;
