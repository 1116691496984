import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_BLOG_POST_URL , ADD_BLOG_POST_URL, DELETE_BLOG_POST_URL, MULTIPLE_BLOG_POST_DELETE_URL, UPDATE_BLOG_POST_URL, DELETE_BLOG_POST_IMAGE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_BLOG_POST_REQUEST = 'FETCH_BLOG_POST_REQUEST';
export const FETCH_BLOG_POST_SUCCESS = 'FETCH_BLOG_POST_SUCCESS';
export const FETCH_BLOG_POST_FAILURE = 'FETCH_BLOG_POST_FAILURE';

export const ADD_BLOG_POST_REQUEST = 'ADD_BLOG_POST_REQUEST';
export const ADD_BLOG_POST_SUCCESS = 'ADD_BLOG_POST_SUCCESS';
export const ADD_BLOG_POST_FAILURE = 'ADD_BLOG_POST_FAILURE';

export const DELETE_BLOG_POST_REQUEST = 'DELETE_BLOG_POST_REQUEST';
export const DELETE_BLOG_POST_SUCCESS = 'DELETE_BLOG_POST_SUCCESS';
export const DELETE_BLOG_POST_FAILURE = 'DELETE_BLOG_POST_FAILURE';


export const UPDATE_BLOG_POST_REQUEST = 'UPDATE_BLOG_POST_REQUEST';
export const UPDATE_BLOG_POST_SUCCESS = 'UPDATE_BLOG_POST_SUCCESS';
export const UPDATE_BLOG_POST_FAILURE = 'UPDATE_BLOG_POST_FAILURE';

export const MULTIPLE_DELETE_BLOG_POST_SUCCESS = 'MULTIPLE_DELETE_BLOG_POST_SUCCESS';

export const DELETE_BLOG_POST_IMAGE_SUCCESS = 'DELETE_BLOG_POST_IMAGE_SUCCESS';


export const fetchBlogPostRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_BLOG_POST_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_BLOG_POST_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response)

    if (id) {
      const blogPost = response.data;
      dispatch({
        type: FETCH_BLOG_POST_SUCCESS,
        payload: [blogPost],
      });
    } else {
      const blogPostList = response.data;

      dispatch({
        type: FETCH_BLOG_POST_SUCCESS,
        payload: blogPostList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_BLOG_POST_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchBlogPostSuccess = (blogPost) => ({
    type: FETCH_BLOG_POST_SUCCESS,
    payload: blogPost,
  });
  
  export const fetchBlogPostFailure = (error) => ({
    type: FETCH_BLOG_POST_FAILURE,
    payload: error,
  });
  
  export const addBlogPostRequest = (blogPost, navigate) => async (dispatch) => {
    dispatch({ type: ADD_BLOG_POST_REQUEST });
    const token = localStorage.getItem('token');

    console.log(blogPost)
    try {
        const formData = new FormData();
        formData.append('Title', blogPost.Title);
        formData.append('Slug', blogPost.Slug);
        formData.append('MetaDescription', blogPost.MetaDescription);
        formData.append('MetaKeywords', blogPost.MetaKeywords);
        formData.append('Image', blogPost.Image);          
        formData.append('Content', blogPost.Content);        
        formData.append('SortOrder', blogPost.SortOrder);        

        for (let i = 0; i < blogPost.Images.length; i++) {
          formData.append('Images', blogPost.Images[i]);
        }
    

      const response = await axios.post(ADD_BLOG_POST_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          },
      });

      dispatch({ type: ADD_BLOG_POST_SUCCESS, payload: [response.data] });
      navigate('/blogPost'); 

  } catch (error) {
    dispatch({
      type: ADD_BLOG_POST_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data)
  }
  };
  
  export const addBlogPostSuccess = () => ({
    type: ADD_BLOG_POST_SUCCESS,
  });
  
  export const addBlogPostFailure = (error) => ({
    type: ADD_BLOG_POST_FAILURE,
    payload: error,
  });


  export const deleteBlogPostRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_BLOG_POST_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_BLOG_POST_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_BLOG_POST_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_BLOG_POST_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete About Us');
    }
  };

  export const deleteBlogPostSuccess = () => ({
    type: DELETE_BLOG_POST_SUCCESS,
  });
  
  export const deleteBlogPostFailure = (error) => ({
    type: DELETE_BLOG_POST_FAILURE,
    payload: error,
  });


  export const multipleDeleteBlogPostRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_BLOG_POST_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_BLOG_POST_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_BLOG_POST_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_BLOG_POST_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateBlogPostRequest = (blogPost, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_BLOG_POST_REQUEST });
    const token = localStorage.getItem('token');
    try {


      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Title', blogPost.Title);
        formData.append('Slug', blogPost.Slug);
        formData.append('MetaDescription', blogPost.MetaDescription);
        formData.append('MetaKeywords', blogPost.MetaKeywords);
        formData.append('Image', blogPost.Image);          
        formData.append('Content', blogPost.Content);        
        formData.append('SortOrder', blogPost.SortOrder); 

        for (let i = 0; i < blogPost.Images.length; i++) {
          formData.append('Images', blogPost.Images[i]);
        }

      const response = await axios.put(`${UPDATE_BLOG_POST_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_BLOG_POST_SUCCESS, payload: [response.data] });
      navigate('/blogPost'); 

    } catch (error) {
      dispatch({
        type: UPDATE_BLOG_POST_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update BlogPost');
    }
  };


  export const deleteBlogPostImageRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_BLOG_POST_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.delete(`${DELETE_BLOG_POST_IMAGE_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: DELETE_BLOG_POST_IMAGE_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      console.log(error)
      dispatch({
        type: DELETE_BLOG_POST_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Blog Post Image');
    }
  };
  

  
  
