import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_FAQ_URL , ADD_FAQ_URL, DELETE_FAQ_URL, UPDATE_FAQ_URL, MULTIPLE_FAQ_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_FAQ_REQUEST = 'FETCH_FAQ_REQUEST';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAILURE = 'FETCH_FAQ_FAILURE';

export const ADD_FAQ_REQUEST = 'ADD_FAQ_REQUEST';
export const ADD_FAQ_SUCCESS = 'ADD_FAQ_SUCCESS';
export const ADD_FAQ_FAILURE = 'ADD_FAQ_FAILURE';

export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAILURE = 'DELETE_FAQ_FAILURE';

export const MULTIPLE_DELETE_FAQ_SUCCESS = 'MULTIPLE_DELETE_FAQ_SUCCESS';

export const UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAILURE = 'UPDATE_FAQ_FAILURE';



export const fetchFAQsRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_FAQ_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_FAQ_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const faqs = response.data;
      dispatch({
        type: FETCH_FAQ_SUCCESS,
        payload: [faqs],
      });
    } else {
      const faqsList = response.data;
      dispatch({
        type: FETCH_FAQ_SUCCESS,
        payload: faqsList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FAQ_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchFAQsSuccess = (faqs) => ({
    type: FETCH_FAQ_SUCCESS,
    payload: faqs,
  });
  
  export const fetchFAQsFailure = (error) => ({
    type: FETCH_FAQ_FAILURE,
    payload: error,
  });
  
  export const addFAQsRequest = (faqs, navigate) => async (dispatch) => {
    dispatch({ type: ADD_FAQ_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Question', faqs.Question);
        formData.append('Answer', faqs.Answer);
        formData.append('Order', faqs.Order);   


      const response = await axios.post(ADD_FAQ_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_FAQ_SUCCESS, payload: [response.data] }); 
      navigate('/faqs'); 

  } catch (error) {
    dispatch({
      type: ADD_FAQ_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding FAQs.")
  }
  };
  
  export const addFAQsSuccess = () => ({
    type: ADD_FAQ_SUCCESS,
  });
  
  export const addFAQsFailure = (error) => ({
    type: ADD_FAQ_FAILURE,
    payload: error,
  });


  export const deleteFAQsRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_FAQ_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_FAQ_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_FAQ_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_FAQ_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete FAQs');
    }
  };

  export const deleteFAQsSuccess = () => ({
    type: DELETE_FAQ_SUCCESS,
  });
  
  export const deleteFAQsFailure = (error) => ({
    type: DELETE_FAQ_FAILURE,
    payload: error,
  });


  export const multipleDeleteFAQsRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_FAQ_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_FAQ_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_FAQ_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_FAQ_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateFAQsRequest = (faqs, id ,navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_FAQ_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Question', faqs.Question);
        formData.append('Answer', faqs.Answer);
        formData.append('Order', faqs.Order);   

      const response = await axios.put(`${UPDATE_FAQ_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: UPDATE_FAQ_SUCCESS, payload: [response.data] }); 
      navigate('/faqs'); 

    } catch (error) {
      dispatch({
        type: UPDATE_FAQ_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update FAQs');
    }
  };
  

  
  
