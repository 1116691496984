import { act } from "react-dom/test-utils";
import {
  FETCH_TESTIMONIAL_FAILURE,
  FETCH_TESTIMONIAL_SUCCESS,
  FETCH_TESTIMONIAL_REQUEST,
  ADD_TESTIMONIAL_REQUEST,
  ADD_TESTIMONIAL_SUCCESS,
  ADD_TESTIMONIAL_FAILURE,
  DELETE_TESTIMONIAL_REQUEST,
  DELETE_TESTIMONIAL_SUCCESS,
  DELETE_TESTIMONIAL_FAILURE,
  MULTIPLE_DELETE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_REQUEST,
  UPDATE_TESTIMONIAL_SUCCESS,
  UPDATE_TESTIMONIAL_FAILURE
} from "../actions/testimonialActions";

// Initial state
const initialState = {
  testimonial: [],
  loading: false,
  error: null,
};

// Reducer
const testimonialReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_TESTIMONIAL_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_TESTIMONIAL_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonial: action.payload,
      };
    case ADD_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonial: action.payload,
      };
      
      case DELETE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonial: state.testimonial.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_TESTIMONIAL_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedTestimonial = state.testimonial.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          testimonial: updatedTestimonial,
        };

        case UPDATE_TESTIMONIAL_SUCCESS:
          return {
            ...state,
            loading: false,
            testimonial: action.payload,
          };

      
    case FETCH_TESTIMONIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_TESTIMONIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_TESTIMONIAL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_TESTIMONIAL_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default testimonialReducer;
