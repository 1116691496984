import axios from 'axios';
import { LOGIN_URL } from "../../apis/url_constants";
import { LOGOUT_URL } from '../../apis/url_constants';
import { toast } from 'react-toastify';
import { GETCOLORCOMBINATION_URL, UPDATECOLORCOMBINATION_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_COLOR_COMBINATION_REQUEST = 'FETCH_COLOR_COMBINATION_REQUEST';
export const FETCH_COLOR_COMBINATION_SUCCESS = 'FETCH_COLOR_COMBINATION_SUCCESS';
export const FETCH_COLOR_COMBINATION_FAILURE = 'FETCH_COLOR_COMBINATION_FAILURE';
export const UPDATE_COLOR_COMBINATION_REQUEST = 'UPDATE_COLOR_COMBINATION_REQUEST';
export const UPDATE_COLOR_COMBINATION_SUCCESS = 'UPDATE_COLOR_COMBINATION_SUCCESS';
export const UPDATE_COLOR_COMBINATION_FAILURE = 'UPDATE_COLOR_COMBINATION_FAILURE';


    export const fetchColorCombinationRequest = () => async (dispatch) => {
        dispatch({ type: FETCH_COLOR_COMBINATION_REQUEST });
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(GETCOLORCOMBINATION_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const colorCombination = response.data;
            dispatch({
                type: FETCH_COLOR_COMBINATION_SUCCESS,
                payload: colorCombination
            });
        } catch (error) {
            dispatch({
                type: FETCH_COLOR_COMBINATION_FAILURE,
                payload: error.message,
            });
            toast.error(error.response.data)
        }
    };

  
  export const fetchColorCombinationSuccess = (colorCombination) => ({
      type: FETCH_COLOR_COMBINATION_SUCCESS,
      payload: colorCombination,
  });
  
  export const fetchColorCombinationFailure = (error) => ({
      type: FETCH_COLOR_COMBINATION_FAILURE,
    payload: error,
  });
  
  export const updateColorCombinationsRequest = (updatedColorCombination) => async (dispatch) => {
      dispatch({ type: UPDATE_COLOR_COMBINATION_REQUEST });
    const token = localStorage.getItem('token');
      try {
          const response = await axios.post(UPDATECOLORCOMBINATION_URL, updatedColorCombination, {
        headers: {
          // Add your headers here
          Authorization: `Bearer ${token}`,
          // Other headers...
        },
      });
          dispatch({ type: UPDATE_COLOR_COMBINATION_SUCCESS, payload: response.data });
        toast.success("Color Combination has been Updated.")
    } catch (error) {
      dispatch({
          type: UPDATE_COLOR_COMBINATION_FAILURE,
        payload: error.message,
      });
      toast.error("Something went Wrong!, While Updating Color Combination.")
    }
  };
  
  export const updateColorCombinationsSuccess = () => ({
      type: UPDATE_COLOR_COMBINATION_SUCCESS,
  });
  
  export const updateColorCombinationsFailure = (error) => ({
      type: UPDATE_COLOR_COMBINATION_FAILURE,
    payload: error,
  });


  
