import {
  FETCH_ABOUTUS_FAILURE,
  FETCH_ABOUTUS_SUCCESS,
  FETCH_ABOUTUS_REQUEST,
  ADD_ABOUTUS_REQUEST,
  ADD_ABOUTUS_SUCCESS,
  ADD_ABOUTUS_FAILURE,
  DELETE_ABOUTUS_REQUEST,
  DELETE_ABOUTUS_SUCCESS,
  DELETE_ABOUTUS_FAILURE,
  MULTIPLE_DELETE_ABOUTUS_SUCCESS,
  UPDATE_ABOUTUS_REQUEST,
  UPDATE_ABOUTUS_SUCCESS,
  UPDATE_ABOUTUS_FAILURE
} from "../actions/aboutActions";

// Initial state
const initialState = {
  aboutUs: [],
  loading: false,
  error: null,
};

// Reducer
const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ABOUTUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_ABOUTUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_ABOUTUS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_ABOUTUS_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_ABOUTUS_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: action.payload,
      };
    case ADD_ABOUTUS_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: action.payload,
      };
      
      case DELETE_ABOUTUS_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: state.aboutUs.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_ABOUTUS_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedAboutUs = state.aboutUs.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          aboutUs: updatedAboutUs,
        };

        case UPDATE_ABOUTUS_SUCCESS:
          return {
            ...state,
            loading: false,
            aboutUs: action.payload,
          };

      
    case FETCH_ABOUTUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_ABOUTUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_ABOUTUS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_ABOUTUS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default aboutReducer;
