import { React, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DataTable from 'react-data-table-component';
import { ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../apis/url_constants';
import { deleteProductRequest, fetchProductRequest, multipleDeleteProductRequest } from '../../redux_store/actions/productActions';


const Product = () => {
    const [showAlert, setShowAlert] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { product, loading, error } = useSelector(
        (state) => state.product
      );

    useEffect(() => {
        dispatch(fetchProductRequest());
      }, [dispatch]);



      const handleMulitpleDelete = () => {

        const confirmAlert = (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => { 
                const selectedIds = selectedRows.map(row => row.id);
                dispatch(multipleDeleteProductRequest(selectedIds));
                setSelectedRows([]);
                hideAlert();
            }}
              onCancel={hideAlert}
            >
              This action cannot be undone.
            </SweetAlert>
          );
        
          setShowAlert(confirmAlert);
      };

      const handleDelete = (row) => {
        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteProductRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };
      
     
    
      const handleEdit = (row) => {
        // Implement your edit logic here
        console.log('Edit row:', row);
      };
    
      const handleView = (row) => {
        // Implement your view logic here
        console.log('View row:', row);
      };

      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };
      
      const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

      const formatDate = (datetimeString) => {
        const date = new Date(datetimeString);
        const day = date.getDate(); // Get the day (1-31)
        const month = date.getMonth() + 1; // Get the month (0-11) and add 1 to match the desired range (1-12)
        const year = date.getFullYear(); // Get the full year
    
        // Format the components as needed (e.g., zero padding)
        const formattedDate = `${day}-${month}-${year}`;
    
        return formattedDate;
      };

      
      const columns = product && product.length > 0 ? Object.keys(product[0]).map((key) => {
        if (key === 'image') {
          return {
            name: 'Image',
            cell: (row) => (
              row[key] ? (
                <img src={`${BASE_URL}${row[key]}`} alt="Image" width="100" height="100" />
              ) : (
                <div>No Image Available</div>
              )
            ),
            sortable: false,
          };
        }

        if (key === 'createdAt') {
          return {
            name: 'Created At',
            selector: (row) => formatDate(row[key]), // Format the date here
            sortable: true,
          };
        }

        if (key === 'status') {
          return {
            name: key.charAt(0).toUpperCase() + key.slice(1),
            cell: (row) => (
              <input type='checkbox'
                checked={row[key]}
                className='form-check-input'
                disabled
              />
            ),
            sortable: true,
          };
        }
      
        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnsToRemove = ['Id' , 'Description', 'ShortDescription', 'BannerImages', 'HasOptions', 'ProductCategories', 'ProductSubCategories' , 'Variants' ,'Options' , 'RelatedProducts' , 'BasePrice' , 'ProductTags' , 'Discount' , 'FreeShipping' , 'DiscountAmount' , 'ProductTaxes' ,'OutOfStock' , 'InStock' , 'SizeChart' , 'Reviews', 'Deals' , 'Title', 'FinalPrice' ,'DiscountPercentage' ,'RoundedBasePrice' , 'GuaranteeWarranty' ,'EnableTaxes' , 'Slug', 'MetaDescription', 'MetaKeywords', 'Images'  , 'BuyNow' ,'AddToCart' ,'Wishlist', 'ProductFaqs' ,'Enquiry' ,'Whatsapp' ,'HasFAQs' ,'LastModified'];

        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-1'></i>
                <Link to={`/addProduct/${row.id}`}><i className='ti ti-pencil text-info fs-6 px-1'></i></Link>
                <Link to={`/addProduct/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-1'></i></Link>
                <Link to={`/variant/${row.id}`}><i className='ti ti-apps text-secondary fs-6 px-1'></i></Link>
              </>
            ),
            sortable: false,
          };          

          const modifiedColumns = [actionsColumn , ...filteredColumns];

          console.log(product)

  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>Products</h3>
                    <div className='d-flex align-items-center'>
                    {selectedRows.length > 0 && (
                        <button onClick={handleMulitpleDelete} className='btn btn-danger'>Delete Selected</button>
                    )}
                    <a href={`${BASE_URL}/Products`} target='_blank' className='btn btn-dark ms-2'>Preview</a>
                    <Link to="/addProduct" className='btn btn-primary ms-2'> Add Content</Link>
                    </div>
                </div>
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={product}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default Product