import { act } from "react-dom/test-utils";
import {
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  MULTIPLE_DELETE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE
} from "../actions/categoryActions";

// Initial state
const initialState = {
  category: [],
  loading: false,
  error: null,
};

// Reducer
const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_CATEGORY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_CATEGORY_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
      
      case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: state.category.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_CATEGORY_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedCategory = state.category.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          category: updatedCategory,
        };

        case UPDATE_CATEGORY_SUCCESS:
          return {
            ...state,
            loading: false,
            category: action.payload,
          };

      
    case FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_CATEGORY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case UPDATE_CATEGORY_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default categoryReducer;
